
import TableTrips from '@/components/trip/TableTrips.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'TripsView',
    components: {
        TableTrips
    }
})
export default class TripsView extends Vue {
}
