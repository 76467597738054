import { AxiosInstance } from "axios";
import API from "@/utils/request";
import { ROUTES_GLOBAL } from "@/api/paths";
import { USER_ENDPOINTS } from "./endpoints";
import {
  AddPassengerFavoriteRequest,
  CreateDriverRequest,
  EditDriverRequest,
  IRequestCreateGuest,
  IRequestGetGuest,
  UpdateCustomerRequest,
} from "@/types/request/guest";
import {
  GetUsersAutocomplete,
  GetUsersRut,
  IAddCardCustomerResponse,
  IResponsePaginationGetGuest,
  SignDocumentUserResponse,
} from "../../types/response/guest";
import { ResponseCommonUsers } from "@/types/common/api";

export class UserApi {
  private request = new API(ROUTES_GLOBAL.USER);
  public api: AxiosInstance = this.request.getInstance();

  async createCustomer(dataCreated: IRequestCreateGuest) {
    const { data } = await this.api.post<string>(
      USER_ENDPOINTS.CREATE_CUSTOMER,
      dataCreated
    );
    return data;
  }

  async createGuest(dataCreated: IRequestCreateGuest) {
    const { data } = await this.api.post<string>(
      USER_ENDPOINTS.CREATE_GUEST,
      dataCreated
    );
    return data;
  }

  async getUsers({ limit, page, fullName, rut }: IRequestGetGuest) {
    let URL = `${USER_ENDPOINTS.GET_GUEST}?page=${page}&limit=${limit}`;
    if (fullName) {
      URL += `&fullName=${fullName}`;
    }
    if (rut) {
      URL += `&rut=${rut}`;
    }
    const { data } = await this.api.get<IResponsePaginationGetGuest>(URL);
    return data;
  }

  async getDrivers({ limit, page, fullName, rut }: IRequestGetGuest) {
    let URL = `${USER_ENDPOINTS.GET_DRIVERS}?page=${page}&limit=${limit}`;
    if (fullName) {
      URL += `&fullName=${fullName}`;
    }
    if (rut) {
      URL += `&rut=${rut}`;
    }
    const { data } = await this.api.get<IResponsePaginationGetGuest>(URL);
    return data;
  }
  async createDriver(data: CreateDriverRequest) {
    await this.api.post(USER_ENDPOINTS.CREATE_DRIVER, data);
  }
  async updateDriver(id: string, data : EditDriverRequest){
    await this.api.put(USER_ENDPOINTS.EDIT_DRIVER + id, data);
  }
  async getDriversByName(fullName: string) {
    const { data } = await this.api.get<GetUsersAutocomplete[]>(
      USER_ENDPOINTS.GET_DRIVER_BYNAME + fullName
    );
    return data;
  }
  async getCustomerByName(fullName: string) {
    const { data } = await this.api.get<GetUsersAutocomplete[]>(
      USER_ENDPOINTS.GET_CUSTOMERS_BYNAME + fullName
    );
    return data;
  }
  async getAllDrivers() {
    const { data } = await this.api.get<ResponseCommonUsers[]>(
      USER_ENDPOINTS.GET_ALL_DRIVERS
    );
    return data;
  }

  async getCustomerByRUT(rut: string) {
    const { data } = await this.api.get<GetUsersRut>(
      USER_ENDPOINTS.GET_CUSTOMER_RUT + rut
    );
    return data;
  }

  async deleteCard(){
    await this.api.delete(USER_ENDPOINTS.DELETE_CARD_FLOW);
  }

  async addCard(pathReturn: string) {
    const { data } = await this.api.post<IAddCardCustomerResponse>(
      USER_ENDPOINTS.ADD_CARD_FLOW,
      { pathReturn }
    );
    return data;
  }

  async updateCustomer(data: UpdateCustomerRequest, id: string) {
    await this.api.patch(USER_ENDPOINTS.UPDATE_CUSTOMER + `/${id}`, data);
  }

  async refreshSign(user: string) {
    await this.api.post(USER_ENDPOINTS.REFRESH_SIGN + user);
  }

  async resendSign(user: string) {
    await this.api.post(USER_ENDPOINTS.RESEND_SIGN + user);
  }

  async resendPassword(user: string) {
    await this.api.post(USER_ENDPOINTS.CREATE_PASSWORD + user);
  }

  async signUser(user: string, isSign: boolean) {
    await this.api.patch(USER_ENDPOINTS.SIGN_USER + user, { isSign });
  }

  async getContract(): Promise<string> {
    return await this.api.get(USER_ENDPOINTS.GET_CONTRACT);
  }

  async sendSign(data: string) {
    return await this.api.post(USER_ENDPOINTS.SEND_SIGN, { sign: data });
  }
  async getMyContract(): Promise<SignDocumentUserResponse> {
    const { data } = await this.api.get(USER_ENDPOINTS.GET_MY_CONTRACT);
    return data;
  }

  async getContractUser(id: string): Promise<string> {
    return await this.api.get(USER_ENDPOINTS.GET_USER_SIGN + id);
  }

  async uploadContract(id: string, data: FormData) {
    await this.api.post(USER_ENDPOINTS.SET_CONTRACT_UPLOAD + id, data);
  }

  async getContractUserRut(rut: string): Promise<string> {
    return await this.api.get(USER_ENDPOINTS.GET_CONTRACT_BYRUT + rut);
  }

  async getContractUserTrip(trip: string): Promise<string> {
    return await this.api.get(USER_ENDPOINTS.GET_CONTRACT_BYTRIP + trip);
  }

  async blockedUser(id: string) {
    await this.api.patch(USER_ENDPOINTS.BLOCK_USER + id);
  }
  async desBlockedUser(id: string){
    await this.api.patch(USER_ENDPOINTS.DESBLOCK_USER + id );
  }

  async addPassengerFavorite(data: AddPassengerFavoriteRequest){
    await this.api.post(USER_ENDPOINTS.ADD_FAVORITE_PASSENGER,data)
  }

  async deletePassenger(passengers: string[]){
    await this.api.post(USER_ENDPOINTS.REMOVE_FAVORITE_PASSENGER,{passengers})
  }
}

