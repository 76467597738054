
import { Options, Vue } from 'vue-class-component';
import { GetStatusTripResponsePassenger } from '@/types/response/trip';
import ModalCommon from '../common/ModalCommon.vue';
import { Header } from 'vue3-easy-data-table';

@Options({
    name: 'Passengers',
    components: { ModalCommon },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        passengers: {
            type: Array,
            default: () => ([]),
            required: true
        }
    }
})
export default class Passengers extends Vue {
    public isVisible!: boolean;
    public passengers !: GetStatusTripResponsePassenger[];
    public headers: Header[] = [
        {
            text: 'Nombres',
            value: 'firstName'
        },
        {
            text: 'RUT',
            value: 'rut'
        },
        {
            text: 'Celular',
            value: 'phone'
        },
        {
            text: 'Asiento',
            value: 'seating'
        }
    ];
}
