import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import {pinia} from "./store";
import { vuetify } from "./plugins/vuetify";
import './scss/main.scss'
import './permissions'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

/* import VCalendar from 'v-calendar';
import 'v-calendar/style.css'; */

const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(vuetify);
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('VueDatePicker', VueDatePicker);
/* app.use(VCalendar) */
app.mount("#app");
