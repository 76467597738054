import { AxiosInstance } from "axios";
import API from "@/utils/request";
import { ROUTES_GLOBAL } from "@/api/paths";
import { IRequestCreateContact } from "./entity/create-contact";


export class MainApi {
  private request = new API(ROUTES_GLOBAL.MAIN);
  private api: AxiosInstance = this.request.getInstance();

  async createContact(data: IRequestCreateContact) {
    const URL = `create-contact`;
    await this.api.post(URL, data);
  }
}
