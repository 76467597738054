import { IResponsePagination } from "../common/api";

export enum StatusUser {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  REGISTERED = 'REGISTERED',
  BLOCKED = 'BLOCKED'
}

export interface IResponseGetGuest {
  _id: string;
  address: string;
  condominium?: string;
  email: string;
  phone: string;
  rut: string;
  firstName: string;
  lastName: string;
  status: StatusUser;
  statusMap ?: {
    name : string;
    color : string
  }
}

export interface IResponsePaginationGetGuest
  extends IResponsePagination<IResponseGetGuest[]> {}

export interface GetUsersAutocomplete {
  _id:string;
  fullName: string;
  email: string;
  rut : string;
  phone?:string
}

export interface GetUsersRut {
  email?: string,
  firstName: string,
  lastName: string,
  phone?: string,
  isSign:boolean,
  urlSign?:string
}

export interface IAddCardCustomerResponse {
  url: string;
  token: string;
}

export interface SignDocumentUserResponse{
  pdf : string;
  dateCreated: string;
  ipSign: string,
  isAdmin?:boolean,
  dateUpdated?:string
}