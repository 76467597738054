
import { Options, Vue } from 'vue-class-component';
import IconFast from '@/assets/images/icons/Fast.svg'
import IconSecure from '@/assets/images/icons/CarSecure.svg'
import IconMoney from '@/assets/images/icons/Money.svg'

@Options({
  name: 'FeaturesHome'
})
export default class FeaturesHome extends Vue {
  public IconFast = IconFast;
  public IconSecure = IconSecure;
  public IconMoney = IconMoney;
}
