
import { Vue, Options } from 'vue-class-component';
import TextLoaderButton from '@/components/common/LoaderButtons.vue';
import HeaderCommonBooking from '@/components/Booking/HeaderCommonBooking.vue';
import { MainApi } from '@/api/main';
import { IRequestCreateContact } from '@/api/entity/create-contact';
import { requiredField, validateEmail } from '@/utils';
import { FormVuetify } from '@/types/common/vuetify';

@Options({
    name: 'LoginPage',
    components: {
        HeaderCommonBooking,
        TextLoaderButton
    }
})
export default class LoginPage extends Vue {
    private mainApi = new MainApi();
    public requiredField = requiredField;
    public validateEmail = validateEmail;
    public errorForm = false;
    public successForm = false;
    public loading = false;
    public dataContact: IRequestCreateContact = {
        fullName: '',
        phone: '',
        email: '',
        message: ''
    }
    back() {
        this.$router.push('/booking')
    }

    async onSubmit() {
        const form = this.$refs['formContact'] as FormVuetify;
        this.errorForm = false
        this.successForm = false;
        if (form) {
            //eslint-disable-next-line
            const validate = await form.validate();
            if (validate.valid) {
                this.loading = true;
                try {
                    await this.mainApi.createContact(this.dataContact);
                    this.successForm = true;
                    form.reset();
                    form.resetValidation();
                } catch (error) {
                    this.errorForm = true
                }
                this.loading = false;
            }
        }
    }
}

