
import { Vue, Options } from 'vue-class-component';
import avatar from '@/assets/images/avatar.svg'
import { useAuthStore } from '../store/modules/auth';
import {getRole} from '@/utils'

@Options({
  name: 'ProfileMenu'
})
export default class ProfileMenu extends Vue {
  public profilePhoto: string = avatar
  private authStore = useAuthStore()
  public getRole= getRole;

  get userDetails() {
    return this.authStore.user
  }

  logout() {

    this.authStore.logout();
    location.reload();
  }
}
