import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  style: {"color":"#4C4C4B"},
  class: "d-none d-sm-block text-helvetica h-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pt-sm-10 pt-0", [_ctx.line ? 'pb-sm-6 ' : 'mb-0 pb-0', _ctx.background]])
  }, [
    _createVNode(_component_v_container, { class: "position-relative d-sm-block d-flex align-center justify-center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back'))),
            variant: "text",
            class: "icon-mobile"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "small" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-arrow-left")
                ]),
                _: 1
              }),
              _createTextVNode(),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.nameButton), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer)
        ]),
        _createElementVNode("h1", {
          class: _normalizeClass(["text-center mx-auto text-sm-h4 text-h5 text-helvetica h-bold title-header-booking", _ctx.line ? 'line' : 'mb-0' + ` ${_ctx.classMore}`])
        }, _toDisplayString(_ctx.title), 3)
      ]),
      _: 1
    })
  ], 2))
}