export class UrlConstants {
  public static BASE_URL = process.env.VUE_APP_URL
    ? process.env.VUE_APP_URL
    : process.env.NODE_ENV === "production"
    ? "https://ptq-dev.inparts.cl/api/"
    : "https://ptq-dev.inparts.cl/api/";
  public static URL_FLOW_WEB = process.env.VUE_APP_FLOWURL;
  public static URL_SOCKET = process.env.VUE_APP_SOCKET || 'backend.tacerca.cl'
  public static URL_PUBLIC_WEB = process.env.VUE_APP_PUBLIC || 'https://tacerca.cl'
}
