import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_3 = {
  class: "text-helvetica h-regular",
  style: {"color":"#4C4C4B"}
}
const _hoisted_4 = { class: "text-primary text-decoration-underline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_EasyDataTable = _resolveComponent("EasyDataTable")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mt-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_EasyDataTable, {
            loading: _ctx.loadingTable,
            headers: _ctx.headers,
            items: _ctx.items,
            "server-items-length": _ctx.total,
            "server-options": _ctx.serverOptions,
            "onUpdate:serverOptions": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.serverOptions) = $event)),
            "buttons-pagination": ""
          }, {
            "item-nameRoute": _withCtx(({ nameRoute, crudRoute, _id }) => [
              (!crudRoute.editable)
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(nameRoute), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_text_field, {
                      density: "compact",
                      variant: "outlined",
                      modelValue: crudRoute.newValueEditable,
                      "onUpdate:modelValue": ($event: any) => ((crudRoute.newValueEditable) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                    _createVNode(_component_v_btn, {
                      icon: "mdi-content-save",
                      color: "blue",
                      size: "small",
                      variant: "text",
                      class: "mr-1",
                      onClick: ($event: any) => (_ctx.saveEditable(_id, crudRoute.newValueEditable))
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      icon: "mdi-close",
                      color: "red",
                      size: "small",
                      variant: "text",
                      class: "mr-1",
                      onClick: ($event: any) => (_ctx.resetEditable(_id))
                    }, null, 8, ["onClick"])
                  ]))
            ]),
            "item-actions": _withCtx(({ _id, nameRoute }) => [
              _createVNode(_component_v_btn, {
                icon: "mdi-pencil",
                color: "blue",
                onClick: ($event: any) => (_ctx.setEditable(_id)),
                size: "x-small",
                variant: "text"
              }, null, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                icon: "mdi-eye",
                color: "secondary",
                onClick: ($event: any) => (_ctx.setRoute(_id)),
                size: "x-small",
                variant: "text"
              }, null, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                icon: "mdi-delete",
                color: "red",
                size: "x-small",
                variant: "text",
                class: "ml-1",
                onClick: ($event: any) => (_ctx.removeRoute(_id, nameRoute))
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["loading", "headers", "items", "server-items-length", "server-options"])
        ]),
        _: 1
      }),
      _createVNode(_component_ModalCommon, {
        "max-width": "600",
        textButton: "Eliminar",
        classButton: "bg-red",
        onEventSuccess: _ctx.deleteRoute,
        isVisible: _ctx.isVisibleModalDelete,
        title: "Eliminar ruta",
        loading: _ctx.loadingDelete,
        onCloseModal: _ctx.closeModalDelete
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, [
            _createTextVNode("¿Estas seguro de eliminar la ruta "),
            _createElementVNode("b", _hoisted_4, _toDisplayString(_ctx.routeDelete), 1),
            _createTextVNode("?")
          ])
        ]),
        _: 1
      }, 8, ["onEventSuccess", "isVisible", "loading", "onCloseModal"])
    ]),
    _: 1
  }))
}