

import { Vue, Options } from 'vue-class-component';

@Options({
    name: 'TextLoaderButton',
    props: {
        title: {
            type: String,
            default: 'Cargando',
            required: false
        },
        colorLoader: {
            type: String,
            default: 'secondary',
            required: false
        },
        sizeLoader: {
            type: String,
            default: 'x-small',
            required: false
        },
        colorTextLoader: {
            type: String,
            default: 'text-white',
            required: false
        }
    }
})
export default class TextLoaderButton extends Vue {
    public title!: string;
    public colorLoader!: string;
    public sizeLoader!: string;
    public colorTextLoader!: string;
}
