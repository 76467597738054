
import { IStepsDataConditions } from '@/assets/data/policies';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'Privacy',
    props: {
        title: String,
        dataSteps: []
    }
})
export default class Privacy extends Vue {
    public idActive = 0;
    public dataSteps!: IStepsDataConditions[];
    public dataHTMLSelected: IStepsDataConditions | null = null;
    public title!: string;

    changeConditions(id: number) {
        this.idActive = id;
    }

    mounted(): void {
        this.updateData(this.idActive)
        this.$watch('idActive', (value: number) => {
            this.updateData(value);
        })
    }

    updateData(value: number) {
        const dataStep = this.dataSteps.find(({ id }) => id === value)
        if (dataStep) {
            this.dataHTMLSelected = dataStep;
        }
    }
}
