import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!

  return (_openBlock(), _createBlock(_component_ModalCommon, {
    textButton: "Crear",
    maxWidth: "750",
    onEventSuccess: _ctx.saveDriver,
    isVisible: _ctx.isVisible,
    title: "Crear conductor",
    loading: _ctx.loadingCreateRoute,
    onCloseModal: _ctx.closeModalCreateRoute
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "formCreateDriver" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { noGutters: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataDriver.firstName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataDriver.firstName) = $event)),
                    label: "Nombres",
                    variant: "outlined",
                    rules: [_ctx.requiredField],
                    density: "comfortable"
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataDriver.lastName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataDriver.lastName) = $event)),
                    label: "Apellidos",
                    variant: "outlined",
                    rules: [_ctx.requiredField],
                    density: "comfortable"
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataDriver.phone,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataDriver.phone) = $event)),
                    type: "tel",
                    label: "Celular",
                    variant: "outlined",
                    rules: [_ctx.requiredField],
                    density: "comfortable"
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataDriver.rut,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataDriver.rut) = $event)),
                    onKeypress: _ctx.validateNumber,
                    onBlur: _ctx.format,
                    onFocus: _ctx.formatReverse,
                    label: "RUT",
                    variant: "outlined",
                    rules: [_ctx.requiredField, _ctx.ruleValidateRut],
                    density: "comfortable"
                  }, null, 8, ["modelValue", "onKeypress", "onBlur", "onFocus", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataDriver.email,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataDriver.email) = $event)),
                    type: "email",
                    label: "Correo",
                    rules: [_ctx.requiredField, _ctx.validateEmail],
                    variant: "outlined",
                    density: "comfortable"
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataDriver.password,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataDriver.password) = $event)),
                    autocomplete: "off",
                    "onClick:appendInner": _cache[6] || (_cache[6] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
                    label: "Contraseña",
                    variant: "outlined",
                    rules: [_ctx.requiredField],
                    density: "comfortable",
                    "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                    type: _ctx.showPassword ? 'text' : 'password'
                  }, null, 8, ["modelValue", "rules", "append-inner-icon", "type"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["onEventSuccess", "isVisible", "loading", "onCloseModal"]))
}