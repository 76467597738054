
import { VehicleAPI } from "@/api/vehicles";
import { CreateVehicleRequest } from "@/types/request/vehicle";
import {
  GetBrandsResponse,
  GetModelByBrandResponse,
  GetTypesVehicleResponse,
  VehicleResponse,
} from "@/types/response/vehicle";
import { getErrorCatch, requiredField, min } from "@/utils";
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../common/ModalCommon.vue";
import { useVehicleStore } from "@/store/modules/vehicle";
import { FormVuetify } from "@/types/common/vuetify";
import { ResponseCommonName } from "@/types/common/api";
@Options({
  name: "UpdateVehicle",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    vehicle: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    ModalCommon,
  },
})
export default class UpdateVehicle extends Vue {
  public loadingCreateVehicle = false;
  private vehicleApi = new VehicleAPI();
  private vehicleStore = useVehicleStore();
  public isVisible!: boolean;
  public vehicle!: VehicleResponse;
  public dataCreate: CreateVehicleRequest = {
    typeVehicle: "",
    patent: "",
    model: "",
    year: "",
    seatings: "",
    gpsID: "",
    typeDistribution: "",
  };
  public typeDistribution: ResponseCommonName[] = [
    {
      _id: "DEFAULT",
      name: "Por defecto",
    },
    {
      _id: "DEFAULT_V2",
      name: "Por defecto v2",
    },
    {
      _id: "SEATING_16",
      name: "Bus 16 asientos",
    },
  ];
  public brandSelected = "";
  public loadingModels = false;

  public brands: GetBrandsResponse[] = [];
  public models: GetModelByBrandResponse[] = [];
  public typesVehicle: GetTypesVehicleResponse[] = [];
  closeModalCreateVehicle() {
    this.dataCreate = {
      typeVehicle: "",
      typeDistribution: "",
      gpsID: "",
      patent: "",
      model: "",
      year: "",
      seatings: "",
    };
    this.brandSelected = "";
    const form = this.$refs["formCreateVehicle"] as FormVuetify;
    if (!form) return;
    form.reset();
    this.$emit("closeModal");
  }
  async saveVehicle() {
    const form = this.$refs["formCreateVehicle"] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {
      this.loadingCreateVehicle = true;
      try {
        const data: CreateVehicleRequest = {
          ...this.dataCreate,
          year: parseInt(this.dataCreate.year as string),
          seatings: parseInt(this.dataCreate.seatings as string),
        };
        await this.vehicleApi.updateVehicle(data, this.vehicle._id);
        await this.vehicleStore.getVehicles();
        this.closeModalCreateVehicle();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingCreateVehicle = false;
    }
  }

  created() {
    this.$watch("isVisible", (value: boolean) => {
      if (value) {
        this.getBrands();
        this.getTypesVehicle();
      }
    });
  }

  mounted() {
    this.getBrands();
    this.getTypesVehicle();
    this.$watch("vehicle", async (vehicle: VehicleResponse) => {
      if (vehicle) {
        const {
          patent,
          year,
          typeVehicleID,
          vehicle: { brandID, modelID },
          seatings,
          gpsID,
          seatingsDistribution,
        } = vehicle;
        this.dataCreate.patent = patent;
        this.dataCreate.year = year;
        this.dataCreate.typeVehicle = typeVehicleID;
        this.dataCreate.seatings = seatings;
        this.dataCreate.model = modelID;
        this.dataCreate.gpsID = gpsID || "";
        this.brandSelected = brandID;
        this.dataCreate.typeDistribution = seatingsDistribution;
        try {
          await this.getModels(brandID);
        } catch (error) {
          getErrorCatch(error);
        }
      }
    });
  }

  async getBrands() {
    try {
      this.brands = await this.vehicleApi.getBrands();
    } catch (error) {
      getErrorCatch(error);
    }
  }

  async getTypesVehicle() {
    try {
      this.typesVehicle = await this.vehicleApi.getTypesVehicle();
    } catch (error) {
      getErrorCatch(error);
    }
  }

  async getModels(brand: string) {
    if (!brand) return;
    this.loadingModels = true;
    try {
      const models = await this.vehicleApi.getModelsByBrand(brand);
      this.models = models;
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingModels = false;
  }
  public requiredField = requiredField;
  public min = min;
}
