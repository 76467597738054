import { defineStore } from "pinia";
import { IResponseGetGuest } from "@/types/response/guest";
import { IPagination } from "@/types/common/api";
import { UserApi } from "../../api/users";

interface DriverStateStore {
  drivers: IResponseGetGuest[];
  totalDrivers: number;
  paginationDrivers: IPagination;
}

const userApi = new UserApi();

export const useDriverStore = defineStore("drivers", {
  // other options...
  state: (): DriverStateStore => ({
    drivers: [],
    totalDrivers: 0,
    paginationDrivers: {
      limit: 5,
      page: 1,
    },
  }),
  actions: {
    async getDrivers() {
      const { items, total } = await userApi.getDrivers({
        limit: this.paginationDrivers.limit,
        page: this.paginationDrivers.page,
      });
      this.drivers = items;
      this.totalDrivers = total;
    },
    setPage(page: number) {
      this.paginationDrivers.page = page;
    },
    setLimit(limit: number) {
      this.paginationDrivers.limit = limit;
    },
  },
});
