

import { formatRutChile, getErrorCatch, requiredField, ruleValidateRut, validateEmail, validateNumber } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { CreateDriverRequest } from '@/types/request/guest'
import ModalCommon from '../common/ModalCommon.vue';
import { UserApi } from '@/api/users';
import { useDriverStore } from '@/store/modules/drivers';
import { FormVuetify } from '@/types/common/vuetify';
@Options({
    name: 'CreateDriver',
    components: { ModalCommon },
    props: {
        isVisible: {
            type: Boolean,
            required: true,
            default: false
        }
    }
})
export default class CreateDriver extends Vue {
    public loadingCreateRoute = false;
    public isVisible!: boolean;
    public showPassword = false;
    public requiredField = requiredField;
    public validateEmail = validateEmail;
    public validateNumber = validateNumber;
    public ruleValidateRut = ruleValidateRut;
    private userApi = new UserApi()
    private driverStore = useDriverStore();
    public dataDriver: CreateDriverRequest = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phone: '',
        rut: ''
    }
    async saveDriver() {
        const form = this.$refs['formCreateDriver'] as FormVuetify;
        if (!form) return;
        const validate = await form.validate();
        if (validate.valid) {
            this.loadingCreateRoute = true;
            try {
                await this.userApi.createDriver(this.dataDriver);
                await this.driverStore.getDrivers();
                this.closeModalCreateRoute();
            } catch (error) {
                getErrorCatch(error)
            }
            this.loadingCreateRoute = false;
        }
    }
    closeModalCreateRoute() {
        const form = this.$refs['formCreateDriver'] as FormVuetify;
        if (!form) return;
        this.dataDriver = {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '',
            rut: ''
        };
        form.reset();
        this.$emit('closeModal')
    }
    formatReverse() {
        this.dataDriver.rut = this.dataDriver.rut.replace(/[^\dKk]/g, "");
    }
    format() {
        this.dataDriver.rut = formatRutChile(this.dataDriver.rut)
    }
}
