import axios, { AxiosInstance } from "axios";
import { UrlConstants } from "../constants/url.constants";
import { useAuthStore } from "../store/modules/auth";

export class ErrorResponse {
  constructor(public message: string, public statusCode: number) {}
}

export default class API {
  private axios!: AxiosInstance;
  constructor(public path: string) {
    this.axios = axios.create({
      baseURL: UrlConstants.BASE_URL + path,
      timeout: 200000,
    });
    this.setInterceptorRequest();
    this.setInterceptorResponse();
  }

  private setInterceptorRequest() {
    this.axios.interceptors.request.use(
      (config) => {
        const authStore = useAuthStore();
        if (authStore.token) {
          if (config.headers) {
            config.headers["Authorization"] = authStore.token;
          }
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  private setInterceptorResponse() {
    this.axios.interceptors.response.use(
      function (response) {
        return response.data;
      },
      function (error) {
        const errorDescription = {
          message:
            error.response?.data?.message ||
            "Ocurrio un error, vuelva a intentarlo",
          statusCode: error.response?.data?.code || 401,
        };

        if (
          errorDescription.message.length > 0 &&
          typeof errorDescription.message === "object"
        ) {
          errorDescription.message = `Tiene los siguientes errores: ${errorDescription.message.join(
            ","
          )}`;
        }
        const errorRequest = new ErrorResponse(
          errorDescription.message,
          errorDescription.statusCode
        );
        return Promise.reject(errorRequest);
      }
    );
  }

  public getInstance(): AxiosInstance {
    return this.axios;
  }
}
