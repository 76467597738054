import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9401bad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-center align-center position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(["position-absolute float-value text-helvetica h-medium", _ctx.isInactive ? 'color-inactive' : _ctx.isBlocked ? 'color-blocked' : 'color-active'])
    }, _toDisplayString(_ctx.value), 3),
    (_ctx.isInactive)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_v_img, {
            style: {"cursor":"pointer"},
            class: "d-none d-sm-block",
            src: _ctx.inactiveSeating,
            width: "50"
          }, null, 8, ["src"]),
          _createVNode(_component_v_img, {
            style: {"cursor":"pointer"},
            class: "d-block d-sm-none",
            src: _ctx.inactiveMobileSeating,
            width: "50"
          }, null, 8, ["src"])
        ], 64))
      : (_ctx.isBlocked)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_v_img, {
              style: {"cursor":"pointer"},
              class: "d-none d-sm-block",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('event', _ctx.value))),
              src: _ctx.blockedSeating,
              width: "50"
            }, null, 8, ["src"]),
            _createVNode(_component_v_img, {
              style: {"cursor":"pointer"},
              class: "d-block d-sm-none",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('event', _ctx.value))),
              src: _ctx.blockedMobileSeating,
              width: "50"
            }, null, 8, ["src"])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_v_img, {
              style: {"cursor":"pointer"},
              width: "50",
              class: "d-none d-sm-block",
              src: _ctx.activeSeating,
              alt: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('event', _ctx.value)))
            }, null, 8, ["src"]),
            _createVNode(_component_v_img, {
              style: {"cursor":"pointer"},
              width: "50",
              class: "d-block d-sm-none",
              alt: "",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('event', _ctx.value))),
              src: _ctx.activeMobileSeating
            }, null, 8, ["src"])
          ], 64))
  ]))
}