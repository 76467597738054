import { defineStore } from "pinia";
import { IRequestLogin } from "@/types/request/login";
import { AuthApi } from "@/api/auth";
import { getKeys, removeToken, setToken } from "@/utils/cookies";
import { resetRouter } from "@/router";
import { IResponseGetProfile } from "@/types/response/login";
import { UpdateCustomerRequest } from '@/types/request/guest';

interface IAuthStore {
  token: string | null;
  isLogged: boolean;
  user: IResponseGetProfile | null;
}


export const useAuthStore = defineStore("auth", {
  // other options...
  state: (): IAuthStore => ({
    token: getKeys('vue_app_token') || "",
    isLogged: false,
    user: null,
  }),
  actions: {
    resetToken() {
      removeToken();
      this.token = null;
      localStorage.removeItem("token");
    },
    async login(data: IRequestLogin) {
      const authApi = new AuthApi();
      const token = await authApi.login(data);
      const tokenBearer = `Bearer ${token}`;
      setToken(tokenBearer);
      this.token = tokenBearer;
    },
    async getUser() {
      if (!this.token) {
        throw Error("token is undefined!");
      }
      const authApi = new AuthApi();
      const dataProfile = await authApi.getProfile();
      if (!dataProfile) {
        throw Error("User not found");
      }
      this.user = dataProfile;
      this.isLogged = true;
    },
    logout() {
      removeToken();
      resetRouter();
      this.token = null;
      this.isLogged = false;
      this.user = null;
    },
    async updateProfile(data : UpdateCustomerRequest){
      const authApi = new AuthApi();
      await authApi.updateProfile(data);
      await this.getUser();
    }
  },
});
