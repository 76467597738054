

import { UpdateCustomerRequest } from '@/types/request/guest';
import { IResponseGetProfile } from '@/types/response/login';
import { formatRutChile, getErrorCatch, requiredField, ruleValidateRut, validateEmail, validateNumber } from '@/utils';
import { Vue, Options } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { FormVuetify } from '@/types/common/vuetify';
import { useAuthStore } from '@/store/modules/auth';
@Options({
    name: 'UpdateDataClient',
    emits: ['closeModal'],
    props: {
        user: {
            type: Object,
            default: () => ([]),
            required: true
        },
        active: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    components: {
        ModalCommon
    }
})
export default class UpdateDataClient extends Vue {
    public user !: IResponseGetProfile;
    public active!: boolean;
    public loadingUpdate = false;
    public requiredField = requiredField;
    public validateNumber = validateNumber;
    public ruleValidateRut = ruleValidateRut;
    public validateEmail = validateEmail;
    private authStore = useAuthStore()

    public dataUpdate: UpdateCustomerRequest = {
        firstName: '',
        lastName: '',
        rut: '',
        email: '',
        phone: '',
        address: '',
        commune: ''
    }

    mounted(): void {
        this.$watch('active', (value: boolean) => {
            if (value) {
                this.loadCustomer()
            }
        })
    }


    loadCustomer() {
        if (this.user) {
            const { firstName, lastName, email, rut, phone, address } = this.user;
            this.dataUpdate = {
                firstName,
                lastName,
                email,
                rut,
                phone,
                address: address?.address || '',
                commune: address?.commune || ''
            }
        }
    }
    async save() {
        this.loadingUpdate = true;
        try {
            const form = this.$refs['formUpdateCustomer'] as FormVuetify;
            if(!form) throw new Error('Ocurrio un error, contacta al administrador');
            const {valid} = await form.validate();
            if(valid){
                await this.authStore.updateProfile(this.dataUpdate);
                this.$emit('closeModal')
            }
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingUpdate = false;
    }
    formatReverse() {
        this.dataUpdate.rut = this.dataUpdate.rut.replace(/[^\dKk]/g, "");
    }
    format() {
        this.dataUpdate.rut = formatRutChile(this.dataUpdate.rut)
    }
}
