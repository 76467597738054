import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-secondary text-helvetica h-regular"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, { class: "titles-list-planning py-0 py-md-1" }, {
    prepend: _withCtx(() => [
      _createVNode(_component_v_avatar, {
        size: "small",
        color: "secondary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            size: "small",
            color: "white"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.icon), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list_item_title, { class: "h-bold text-helvetica text-primary" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }),
      (_ctx.alt)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.alt), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_v_list_item_subtitle, { class: "text-secondary text-helvetica h-regular" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}