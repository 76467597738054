

import { Options, Vue } from 'vue-class-component';
import IconLarge from '@/assets/images/logo.svg';
import { useMainStore } from '../store/modules/main';
import { customScrollTo } from '@/utils'

@Options({
  name: 'AppBar',
  props: {
    isLogged: {
      type: Boolean,
      default: false,
      required: false
    }
  }
})
export default class AppBar extends Vue {
  icon = IconLarge;
  public store = useMainStore();
  public activeDrawer = false;
  public isLogged !: boolean;

  getHref(href: string) {
    const id = href.split('/')[1]
    return id || '#home'
  }

  toScroll(e: Event, data: string, isDrawer = false) {
    e.preventDefault()
    const path = this.$route.path;
    if (isDrawer) {
      this.activeDrawer = false;
    }
    if (!data.includes("#")) {
      this.$router.push(data);
      return;
    }
    if (path === '/') {
      const topOffset = 80
      const sectionId = data.split("#")[1]
      customScrollTo(sectionId, topOffset)
    } else {
      this.$router.push(data)
    }
  }
  isActive(value: string) {
    return value === this.$route.path
  }
  signOut() {
    this.$emit('signOut')
  }
}
