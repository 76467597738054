

import FormSearchBooking from '@/components/Booking/FormSearch.vue';
import { useRoutesStore } from '@/store/modules/route';
import { getErrorCatch, formatCurrency } from '@/utils';
import { Vue, Options } from 'vue-class-component';
import BannerInfoSearch from '@/components/Booking/BannerInfoSearch.vue';
import { usePlanningStore, STEP } from '@/store/modules/planning';
import ListTripResult from '@/components/Booking/ListTripResult.vue';
import Bus from '@/components/Booking/Bus.vue';
import Passengers from '@/components/Booking/Passengers.vue';
import Payment from '@/components/Booking/Payment.vue';
import { TripApi } from '@/api/trip';
import { GetStatusTripResponse } from '@/types/response/trip';
import HeaderCommonBooking from '@/components/Booking/HeaderCommonBooking.vue';
import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import Line from '@/assets/images/icons/linea.svg'
import LocationPayment from '@/assets/images/icons/location-payment.svg'
import '@/scss/booking.scss'
import { useMainStore } from '@/store/modules/main';

@Options({
    name: 'BookingPage',
    components: {
        FormSearchBooking,
        BannerInfoSearch,
        ListTripResult,
        Bus,
        Passengers,
        Payment,
        HeaderCommonBooking
    }
})
export default class BookingPage extends Vue {
    public routeStore = useRoutesStore();
    public planningStore = usePlanningStore();
    public mainStore = useMainStore();
    private tripApi = new TripApi();
    public loading = false;
    public convertTo12HourFormat = convertTo12HourFormat;
    public formatCurrency = formatCurrency;
    public getDate = getDate;
    public LocationPayment = LocationPayment;
    public Line = Line;
    public errorTrip = false;
    public messageError = '';
    public activeTrip: 'outbound' | 'return' = 'outbound'

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    public tripResponse: GetStatusTripResponse | null = null

    get confirmQuery() {
        return this.$route.query['confirm'];
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected
    }

    get step() {
        return this.planningStore.step;
    }

    get stations() {
        return this.routeStore.stationsList
    }

    get roundType() {
        return this.planningStore.searchSelected?.typeTrip || 'round'
    }

    get seatings() {
        if (!this.tripResponse) return ''
        const seatingOwner = this.tripResponse.seating;
        const seatingPassengers = this.tripResponse.passengers.map(({ seating }) => seating)
        seatingPassengers.push(seatingOwner)
        seatingPassengers.sort(this.sortSeatings)
        return seatingPassengers.join(",")
    }

    get seatingsReturn() {
        if (!this.tripResponse) return ''
        const seatingOwner = this.tripResponse.returnData?.seatingOwner || '';
        const seatingPassengers: string[] = this.tripResponse.returnData ? this.tripResponse.returnData.passengers : []
        seatingPassengers.push(seatingOwner);
        seatingPassengers.sort(this.sortSeatings);
        return seatingPassengers.join(",");
    }

    created(): void {
        if (this.confirmQuery) {
            this.getTrip();
        }
    }
    async mounted() {
        try {
            await this.routeStore.getStations();
            const successCard = this.$route.query['successCard'];
            if (successCard) {
                this.validateLocalData()
            }
        } catch (error) {
            getErrorCatch(error)
        }
    }

    removeAllItems() {
        const keys = ['step', 'searchSelected', 'passengers', 'tripSelected', 'owner', 'tripFinalSelected', 'ownerFinal', 'passengersFinal']
        keys.forEach(key => {
            localStorage.removeItem(key);
        })
    }

    async validateLocalData() {
        const step = localStorage.getItem('step') as STEP;
        const searchSelected = localStorage.getItem('searchSelected');
        const tripSelected = localStorage.getItem('tripSelected');
        const tripFinalSelected = localStorage.getItem('tripFinalSelected');
        const passengers = localStorage.getItem('passengers');
        const passengersFinal = localStorage.getItem('passengersFinal');
        const owner = localStorage.getItem('owner');
        const ownerFinal = localStorage.getItem('ownerFinal');
        if (step && searchSelected && passengers && tripSelected && owner) {
            try {
                this.planningStore.setStep(step);
                this.planningStore.setTrip(JSON.parse(tripSelected));
                await this.planningStore.getTripsBooking(JSON.parse(searchSelected));
                this.planningStore.setOwner(JSON.parse(owner));
                this.planningStore.setPassengers(JSON.parse(passengers));
                if (tripFinalSelected) {
                    this.planningStore.updateStageTrip('return')
                    this.planningStore.setTrip(JSON.parse(tripFinalSelected))
                }
                if (passengersFinal) {
                    this.planningStore.updateStageSeating('return');
                    this.planningStore.setPassengers(JSON.parse(passengersFinal))
                }
                if (ownerFinal) {
                    this.planningStore.updateStageSeating('return');
                    this.planningStore.setOwner(JSON.parse(ownerFinal))
                }
                this.mainStore.setNotification({
                    message: 'Viaje cargado correctamente',
                    isOpen: true,
                    color: 'green darken-3'
                })
                this.removeAllItems()
            } catch (error) {
                this.mainStore.setNotification({
                    message: 'Ocurrio un error al cargar tu viaje',
                    isOpen: true,
                    color: 'red darken-3'
                })
                this.removeAllItems()
            }
        } else {
            console.log("no cumple con validacion de sesion")
            this.removeAllItems()
        }
    }

    async getTrip() {
        if (!this.confirmQuery) return;
        this.loading = true;
        try {
            const code = this.confirmQuery as string;
            this.tripResponse = await this.tripApi.getTrip(code);
        } catch (error) {
            this.errorTrip = true;
            this.tripResponse = null
            this.messageError = (error as Error).message as string;
        }
        this.loading = false;
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || ''
    }

    sortSeatings(a: string, b: string) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }

    back() {
        this.$router.push('/booking')
    }

    beforeUnmount(): void {
        this.planningStore.clearBooking()
    }
    setActiveTrip(active: 'outbound' | 'return' = 'outbound') {
        this.activeTrip = active;
    }
}
