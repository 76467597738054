import { defineStore } from "pinia";
import { IResponseGetGuest, StatusUser } from "@/types/response/guest";
import { UserApi } from "../../api/users";
import { IRequestGetGuest } from "@/types/request/guest";

interface IAuthStore {
  guest: IResponseGetGuest[];
  totalGuest: number;
  paginationGuest: IRequestGetGuest;
}

const userApi = new UserApi();

export const useUserStore = defineStore("user", {
  // other options...
  state: (): IAuthStore => ({
    guest: [],
    totalGuest: 0,
    paginationGuest: {
      limit: 5,
      page: 1,
    },
  }),
  actions: {
    translateStatus(status: StatusUser){
      switch (status) {
        case StatusUser.ACTIVE:
        case StatusUser.REGISTERED:
          return 'Activo'
        case StatusUser.BLOCKED:
          return 'Bloqueado';
        default:
          return ''
      }
    },
    colorStatus(status: StatusUser){
      switch (status) {
        case StatusUser.ACTIVE:
        case StatusUser.REGISTERED:
          return 'green-darken-2'
        case StatusUser.BLOCKED:
          return 'red-darken-2';
        default:
          return ''
      }
    },
    async getUsers() {
      const { items, total } = await userApi.getUsers({
        limit: this.paginationGuest.limit,
        page: this.paginationGuest.page,
        fullName : this.paginationGuest.fullName,
        rut : this.paginationGuest.rut
      });
      this.guest = items.map((item)=>{
        return {
          ...item,
          statusMap : {
            name : this.translateStatus(item.status),
            color : this.colorStatus(item.status)
          }
        }
      });
      this.totalGuest = total;
    },
    setPage(page: number) {
      this.paginationGuest.page = page;
    },
    setLimit(limit: number) {
      this.paginationGuest.limit = limit;
    },
    setFullname(name:string){
      this.paginationGuest.fullName = name;
    },
    setRUT(rut:string){
      this.paginationGuest.rut = rut;
    }
  },
});
