
import { TripApi } from '@/api/trip';
import { GetTripResponse, GetStatusTripResponsePassenger } from '@/types/response/trip';
import { getErrorCatch, translate } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import {useTripStore} from '@/store/modules/trips'
@Options({
    name: 'MenuSeatings',
    props: {
        trip: {
            type: Object,
            default: () => ({}),
            required: false
        },
        mobile: {
            type: Boolean,
            default: false,
            required: true
        }
    }
})
export default class MenuSeatings extends Vue {
    public trip !: GetTripResponse;
    public mobile!: boolean;
    public translate = translate;
    public seatingsFinal = []
    public tripApi = new TripApi();
    public tripStore = useTripStore();

    get isCancelOneWay() {
        return this.trip.typeCancelTrip?.includes('ONE_WAY')
    }

    get isCancelReturn() {
        return this.trip.typeCancelTrip?.includes('ONLY_RETURN')
    }

    get seatings(): GetStatusTripResponsePassenger[] {
        if (this.trip.typeTrip == 'round') {
            if (this.trip.status == 'CANCEL') {
                const typeCancel = this.trip.typeCancelTrip;
                if (!typeCancel || !this.trip.returnData) {
                    return []
                }
                if (typeCancel.includes('BOTH') || (typeCancel.includes('ONE_WAY') && typeCancel.includes('ONLY_RETURN'))) { return [] }
                if (typeCancel.includes('ONE_WAY')) {
                    return this.trip.returnData.seatings as GetStatusTripResponsePassenger[]
                }
            }
        }
        return this.trip.seatings
    }

    async getPassenger(){
        try {
            await this.tripStore.getPassengers(this.trip._id)
        } catch (error) {
            getErrorCatch(error)
        }
    }
}
