import { defineStore } from "pinia";
import { IPagination } from "@/types/common/api";

import { CouponsResponse } from "@/types/response/coupon";
import { CouponApi } from "@/api/coupons";

interface CouponStateStore {
  coupons: CouponsResponse[];
  totalCoupons: number;
  paginationCoupons: IPagination;
}

const couponApi = new CouponApi();

export const useCouponStore = defineStore("coupons", {
  // other options...
  state: (): CouponStateStore => ({
    coupons: [],
    totalCoupons: 0,
    paginationCoupons: {
      limit: 5,
      page: 1,
      name : undefined
    },
  }),
  actions: {
    async getCoupons() {
      const { limit, page,name,customer } = this.paginationCoupons;
      const { items, total } = await couponApi.getCoupons({
        limit,
        page,
        name,
        customer
      });
      this.coupons = items.map((item) => {
        return {
          ...item,
          customer: item.customer
            ? {
                ...item.customer,
                fullName: `${item.customer.firstName} ${item.customer.lastName}`,
              }
            : undefined,
        };
      });
      this.totalCoupons = total;
    },
    setPage(page: number) {
      this.paginationCoupons.page = page;
    },
    setLimit(limit: number) {
      this.paginationCoupons.limit = limit;
    },
    setName(name:string){
      this.paginationCoupons.name = name
    },
    setUserName(name:string){
      this.paginationCoupons.customer = name
    }
  },
});
