import { defineStore } from "pinia";
import { MainMenu, MainMenuMobile } from "@/assets/data/menu";
import { IMenu } from "../../types/main";
import { TIMEZONE } from "@/types/common";

export enum DeviceType {
  Mobile,
  Desktop,
}

interface INotificationMain {
  color?: string;
  isOpen: boolean;
  message: string;
  timeout?: number;
  isLink?: boolean;
  nameLink?: string;
  link?: string;
  buttonClose?: boolean;
}

interface iMainStore {
  menu: IMenu[];
  notification: INotificationMain;
  drawerOpen: boolean;
  device: DeviceType;
  timeZone: TIMEZONE;
  menuMobile: IMenu[];
  statusServer: boolean;
}

export const useMainStore = defineStore("main", {
  // other options...
  state: (): iMainStore => ({
    menu: [],
    notification: {
      isOpen: false,
      message: "",
      color: "green",
      timeout: 2000,
      buttonClose: true,
    },
    drawerOpen: true,
    device: DeviceType.Desktop,
    timeZone: "America/Santiago",
    menuMobile: [],
    statusServer: true,
  }),
  actions: {
    async setStatus(value : boolean) {
      this.statusServer = value;
    },
    getMenu() {
      this.menu = MainMenu;
      this.menuMobile = MainMenuMobile;
    },
    setNotification({
      isOpen,
      message,
      color,
      timeout,
      isLink,
      link,
      nameLink,
      buttonClose,
    }: INotificationMain) {
      this.notification = {
        isOpen,
        message,
        color,
        timeout: timeout ? timeout : 1500,
        isLink,
        link,
        nameLink,
        buttonClose,
      };
    },
    setDrawer(value: boolean) {
      this.drawerOpen = value;
    },
    setDevice(value: DeviceType) {
      this.device = value;
    },
    toggleDrawer() {
      this.setDrawer(!this.drawerOpen);
    },
  },
});
