import { IResponsePagination } from "@/types/common/api";
import API from "@/utils/request";
import { AxiosInstance } from "axios";

export class LoggerApi {
  private request = new API("logger");
  private api: AxiosInstance = this.request.getInstance();

  async getLoggers(page : number, limit : number) {
    const URL = `/?page=${page}&limit=${limit}`;
    const { data } = await this.api.get<IResponsePagination<[]>>(URL);
    return data;
  }
}
