
import { Options, Vue } from "vue-class-component";

@Options({
  name: "ModalCommon",
  props: {
    classMain: {
      type: String,
      default: "",
      required: false,
    },
    classHeader: {
      type: String,
      default: "",
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    isPersistent: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    withoutButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    textButtonClose: {
      type: String,
      required: false,
      default: "Cerrar",
    },
    textButton: {
      type: String,
      required: false,
    },
    classButton: {
      type: String,
      required: false,
      default: "",
    },
    maxWidth: {
      type: String,
      required: false,
      default: "400",
    },
    maxHeight: {
      type: String,
      required: false,
      default: "auto",
    },
    fullScreen: {
      type: Boolean,
      default: false,
      required: false,
    },
    colorButtonClose: {
      type: String,
      default: "medium-emphasis",
      required: false,
    },
    typeButtonClose : {
      type: String,
      default : 'outlined',
      required: false
    }
  },
})
export default class ModalCommon extends Vue {
  public isVisible!: boolean;
  public isPersistent!: boolean;
  public title!: string;
  public loading!: boolean;
  public withoutButtons!: boolean;
  public textButtonClose!: string;
  public textButton!: string;
  public classButton!: string;
  public maxWidth!: string;
  public fullScreen!: boolean;
  public visibleFullScreen = false;
  public classHeader!: string;
  public maxHeight!: string;
  public classMain!: string;
  public colorButtonClose!: string;
  public typeButtonClose!:"flat" | "text" | "elevated" | "tonal" | "outlined" | "plain"

  public WIDTH = 572;

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < this.WIDTH;
  }

  created(): void {
    this.$watch("isVisible", (data: boolean) => {
      if (data) {
        this.load();
      }
    });
  }

  load() {
    if (this.fullScreen && this.isMobile) {
      this.visibleFullScreen = true;
    }
  }
}
