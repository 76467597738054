

import { RouteStationAPI } from '@/api/route-station';
import { useMainStore } from '@/store/modules/main';
import { useRoutesStore } from '@/store/modules/route';
import { getErrorCatch, requiredField, min } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import type { Header } from "vue3-easy-data-table";
import { ResponseCommonName } from '@/types/common/api';
import { CreateRouteStationsRequest } from '@/types/request/route';
import { FormVuetify } from '@/types/common/vuetify';

interface ItemsStations {
  origin: ResponseCommonName,
  destination: ResponseCommonName,
  keyOrder: number,
  duration: number
}

@Options({
  name: 'CreateStation',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ModalCommon
  }
})
export default class CreateStation extends Vue {
  public nameRoute = ''
  private routeAPI = new RouteStationAPI()
  public mainStore = useMainStore()
  public routeStore = useRoutesStore();
  public loadingCreateRoute = false;
  public isVisible!: boolean;
  public stationsSelecteds: ItemsStations[] = []
  public emptyStations = false;
  public dataStation = {
    origin: '',
    destination: '',
    duration: '',
    order: ''
  }
  public errorStation = false;
  public messageErrorStation = ''

  public headers: Header[] = [
    { text: '#', value: 'keyOrder' },
    { text: "Origen", value: "origin.name" },
    { text: 'Destino', value: 'destination.name' },
    { text: 'Duración(min)', value: 'duration' },
    { text: '', value: 'actions' }
  ];

  get stations() {
    return this.routeStore.stationsList
  }

  closeModalCreateRoute() {
    this.nameRoute = '';
    const form = this.$refs['formNameRoute'] as FormVuetify;
    if (!form) return;
    form.reset();
    this.stationsSelecteds = []
    this.$emit('closeModal');
  }

  async saveRoute() {
    const form = this.$refs['formNameRoute'] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (this.stationsSelecteds.length === 0) {
      this.emptyStations = true
      return;
    }
    this.emptyStations = false
    if (validate.valid) {
      this.loadingCreateRoute = true;
      try {
        const routeID = await this.routeAPI.createRoute({ nameRoute: this.nameRoute })
        const data: CreateRouteStationsRequest = {
          route: routeID,
          stations: this.stationsSelecteds.map(({ destination, duration, keyOrder, origin }) => {
            return {
              duration: duration,
              keyOrder: keyOrder,
              finalStation: destination._id,
              originStation: origin._id
            }
          })
        }
        await this.routeAPI.createRouteStation(data);
        await this.routeStore.getRoutes();
        this.closeModalCreateRoute()
      } catch (error) {
        getErrorCatch(error)
      }
      this.loadingCreateRoute = false;
    }
  }
  public requiredField = requiredField;
  public min = min

  private resetErrorStation() {
    this.errorStation = false;
    this.messageErrorStation = ''
  }
  private resetDataStation() {
    this.dataStation = {
      origin: '',
      destination: '',
      duration: '',
      order: ''
    }
    const form = this.$refs['formStation'] as FormVuetify;
    if (!form) return;
    form.reset();
  }

  async addStation() {
    this.resetErrorStation();
    const form = this.$refs['formStation'] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {
      const { origin, destination, duration, order } = this.dataStation
      const existOrder = this.stationsSelecteds.find(({ keyOrder }) => keyOrder == parseInt(order))
      if (existOrder) {
        this.errorStation = true;
        this.messageErrorStation = 'Orden ya existe'
        return;
      }
      if (origin === destination) {
        this.errorStation = true;
        this.messageErrorStation = 'Origen no puede ser igual al destino'
        return;
      }
      const originData = this.stations.find((station) => station._id == origin);
      const destinationData = this.stations.find((station) => station._id == destination);
      if (!originData || !destinationData) {
        this.errorStation = true;
        this.messageErrorStation = 'Origen o destino no existe'
        return;
      }
      this.stationsSelecteds.push({
        destination: destinationData,
        origin: originData,
        keyOrder: parseInt(order),
        duration: parseInt(duration)
      })
      this.stationsSelecteds = this.stationsSelecteds.sort((a, b) => a.keyOrder - b.keyOrder)
      this.resetDataStation();
      this.emptyStations = false;
    }
  }
  removeStation(entryKeyOrder: number) {
    this.stationsSelecteds = this.stationsSelecteds.filter(({ keyOrder }) => keyOrder !== entryKeyOrder)
  }
}
