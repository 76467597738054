
import { Options, Vue } from "vue-class-component";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { getErrorCatch } from "../../utils";
import { formatDateCompleted } from "@/filters/formatDate";
import { useDriverStore } from "@/store/modules/drivers";
import EditDriver from "./EditDriver.vue";
import { IResponseGetGuest } from "@/types/response/guest";

@Options({
  name: "TableDrivers",
  components: {
    EditDriver,
  },
})
export default class TableDrivers extends Vue {
  private driverStore = useDriverStore();
  public formatDateCompleted = formatDateCompleted;
  public loadingTable = false;
  public driverSelected: IResponseGetGuest | null = null;
  public serverOptions: ServerOptions = {
    page: 1,
    rowsPerPage: 5,
  };
  public visibleEditDriver = false;

  public headers: Header[] = [
    { text: "Fec. de registro", value: "dateCreated" },
    { text: "Nombre completo", value: "firstName" },
    { text: "Correo", value: "email" },
    { text: "Celular", value: "phone" },
    { text: "RUT", value: "rut" },
    { text: "", value: "actions" },
  ];

  get total() {
    return this.driverStore.totalDrivers;
  }

  get items() {
    return this.driverStore.drivers;
  }

  created() {
    this.$watch("serverOptions", ({ page, rowsPerPage }: ServerOptions) => {
      this.driverStore.setPage(page);
      this.driverStore.setLimit(rowsPerPage);
      this.getUsers();
    });
  }

  mounted(): void {
    this.getUsers();
  }

  async getUsers() {
    this.loadingTable = true;
    try {
      await this.driverStore.getDrivers();
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingTable = false;
  }

  openEdit(_id: string) {
    const driver = this.items.find((item) => item._id == _id);
    if (driver) {
      this.driverSelected = driver;
      this.visibleEditDriver = true;
    }
  }
  closeModalEdit(){
    this.driverSelected = null;
    this.visibleEditDriver = false;
  }
}
