

import { useMainStore } from '@/store/modules/main';
import { CouponsProfile } from '@/types/response/login';
import { formatCurrency } from '@/utils';
import { Vue, Options } from 'vue-class-component';

@Options({
    name: 'ListCoupons',
    props: {
        coupons: {
            type: Array,
            default: () => ([]),
            required: true
        }
    }
})
export default class ListCoupons extends Vue {
    public coupons !: CouponsProfile[];
    private mainStore = useMainStore();
    public formatCurrency = formatCurrency;
    public allCoupons = false;

    get finalCoupons(){
        if(this.allCoupons){
            return this.coupons;
        }
        return this.coupons.filter((coupon) => coupon.status == 'ACTIVE')
    }

    copy(name: string) {
        navigator.clipboard.writeText(name);
        this.mainStore.setNotification({
            isOpen: true,
            message: 'Cupón copiado correctamente',
            color: 'blue darken-2'
        })
    }
}
