

import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ItemListDriver',
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        subtitle: {
            type: String,
            required: true,
            default: ''
        },
        alt: {
            type: String,
            required: false,
            default: null
        },
        icon: {
            type: String,
            required: true,
            default: 'mdi-account'
        }
    }
})
export default class ItemListDriver extends Vue {
    public title !: string;
    public subtitle!: string
    public icon!: string;
    public alt!: string
}
