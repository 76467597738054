
import { Vue, Options } from 'vue-class-component';
import Privacy from '@/components/Policies/Privacy.vue';
import { dataConditionsGeneral,dataConditionsShop,mediosPago,derechosRetrator,transportePerros,pagosApp,condicionesEquipaje} from '@/assets/data/policies';

@Options({
    name: 'TermsConditions',
    components: {
        Privacy
    }
})
export default class TermsConditions extends Vue {
    public dataConditionsGeneral = dataConditionsGeneral || []
    public dataConditionsShop = dataConditionsShop || []
    public mediosPago = mediosPago || []
    public derechosRetrator = derechosRetrator || []
    public pagosApp = pagosApp || []
    public transportePerros = transportePerros || [];

    public condicionesEquipaje = condicionesEquipaje || []
}

