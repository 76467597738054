
import { GetTripResponse } from "@/types/response/trip";
import { Options, Vue } from "vue-class-component";
import LocationPayment from "@/assets/images/icons/location-payment.svg";
import Line from "@/assets/images/icons/linea.svg";
import { convertTo12HourFormat, getDate } from "@/filters/formatDate";
import { STATUS_ACTIVE_TRIP } from "@/types/response/planning";
import { formatCurrency } from "@/utils";
import InfoRoute from "./customer/InfoRoute.vue";
import InfoHourRoute from "./customer/InfoHourRoute.vue";

@Options({
  name: "ModalTripDetail",
  components: { InfoRoute, InfoHourRoute },
  props: {
    tripSelected: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
})
export default class ModalTripDetail extends Vue {
  public tripSelected!: GetTripResponse;
  public isVisible!: boolean;
  public LocationPayment = LocationPayment;
  public Line = Line;
  public convertTo12HourFormat = convertTo12HourFormat;
  public getDate = getDate;
  public formatCurrency = formatCurrency;
  public activeTrip: "outbound" | "return" = "outbound";

  get seatings() {
    if (!this.tripSelected) return "";
    const seatingOwner = this.tripSelected.seating;
    const seatingPassengers = this.tripSelected.passengers.map(
      ({ seating }) => seating
    );
    seatingPassengers.push(seatingOwner);
    seatingPassengers.sort(this.sortSeatings);
    return seatingPassengers.join(",");
  }

  get seatingsReturn() {
    if (!this.tripSelected) return "";
    const seatingOwner = this.tripSelected.returnData?.seatingOwner || "";
    const seatingPassengers: string[] = this.tripSelected.returnData
      ? this.tripSelected.returnData.passengers
      : [];
    seatingPassengers.push(seatingOwner);
    seatingPassengers.sort(this.sortSeatings);
    return seatingPassengers.join(",");
  }

  sortSeatings(a: string, b: string) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  closeDialog() {
    this.$emit("closeModal");
  }
  getStatus(entryStatus: string) {
    console.log(entryStatus);
    const status: Record<string, string> = {
      ON_HOLD: "En espera",
      IN_PROGRESS: "En progreso",
      FINALIZED: "Finalizado",
      REMOVED: "Viaje eliminado por admin.",
    };
    return status[entryStatus] || "";
  }
  getColorIcon(status: STATUS_ACTIVE_TRIP) {
    let color = "black";
    switch (status) {
      case STATUS_ACTIVE_TRIP.ON_HOLD:
        color = "grey";
        break;
      case STATUS_ACTIVE_TRIP.IN_PROGRESS:
        color = "green";
        break;
      case STATUS_ACTIVE_TRIP.FINALIZED:
        color = "primary";
        break;
      case STATUS_ACTIVE_TRIP.REMOVED:
        color = "red";
        break;
    }
    return `${color} darken-2`;
  }
  setActiveTrip(active: "outbound" | "return" = "outbound") {
    this.activeTrip = active;
  }
}
