
import { Vue, Options } from 'vue-class-component';
import AppBar from '../components/AppBar.vue';
import Notification from '../components/common/Notification.vue';
import { useAuthStore } from '@/store/modules/auth';

@Options({
  name: "LayoutFront",
  components: {
    AppBar,
    Notification
  }
})
export default class LayoutFront extends Vue {
  private authStore = useAuthStore()

  get isLogged(){
    return this.authStore.isLogged
  }
  signOut(){
    this.authStore.logout()
  }
}
