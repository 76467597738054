import { defineStore } from "pinia";
import { GetTripResponse } from "@/types/response/trip";
import { TripApi } from "@/api/trip";
import { GetTripsPagination, GpsTripResponse } from "@/types/request/trip";

interface TripsStateStore {
  trips: GetTripResponse[];
  paginationTrips: GetTripsPagination;
  totalTrips: number;
  tripSelectedGPS: GpsTripResponse | null
}

const tripApi = new TripApi();

export const useTripStore = defineStore("trips", {
  // other options...
  state: (): TripsStateStore => ({
    trips: [],
    paginationTrips: {
      limit: 5,
      page: 1,
      code: undefined,
      customer: undefined,
      warningTrip: undefined,
    },
    totalTrips: 1,
    tripSelectedGPS: null
  }),
  actions: {
    async getTripSelectedGPS(id: string,tipo?:string){
      const trip = await tripApi.validateVehicle(id,tipo);
      this.tripSelectedGPS = trip;
    },
    async getTripSelectedGPSTrip(id: string,tripID: string,tipo?:string){
      const trip = await tripApi.validateVehicleTrip(id,tripID,tipo);
      this.tripSelectedGPS = trip;
    },
    setPage(page: number) {
      this.paginationTrips.page = page;
    },
    setLimit(limit: number) {
      this.paginationTrips.limit = limit;
    },
    nextPage(){
      this.paginationTrips.limit = (this.paginationTrips.limit as number)+5;
    },
    setPlanningID(planning?: string) {
      this.paginationTrips.planning = planning;
    },
    async getTrips() {
      const { items, total } = await tripApi.getTrips(this.paginationTrips);
      this.trips = items.map((item) => {
        if(!item.seatings) return item
        const seatings = item.seatings.sort((a, b) => {
          const seatingA = parseInt(a.seating);
          const seatingB = parseInt(b.seating);
          return seatingA - seatingB;
        }).map((seating,index)=>{
          if(item.typeTrip == 'round' && item.returnData){
            const seatingReturnFinal = [item.returnData.seatingOwner, ...item.returnData.passengers]
            return {
              ...seating,
              returnSeating: seatingReturnFinal[index]
            }
          }
          return seating
        })
        return {
          ...item,
          seatings,
        };
      });
      this.totalTrips = total;
    },
    setLoadingTrip(id: string, value: boolean){
      this.trips = this.trips.map((trip) => {
        if(trip._id == id){
          trip.loading = value
        }
        return trip;
      })
    },
    async getPassengers(id : string){
      const trips : GetTripResponse[] = [];
      for (let index = 0; index < this.trips.length; index++) {
        const element = this.trips[index];
        if(element._id == id){
          this.setLoadingTrip(element._id,true)
          try {
            const passengers = await tripApi.getPassengers(element.code);
            element.seatings = passengers.seatings;
            if(element.typeTrip == 'round'){
              if(element.returnData !== undefined){
                element.returnData.seatings = passengers.returnData.seatings  
              }
              if(element.returnData!== undefined && element.returnDataActiveTrip){
                element.returnData.activeTrip = element.returnDataActiveTrip
              }
            }
            const seatings = element.seatings.sort((a, b) => {
              const seatingA = parseInt(a.seating);
              const seatingB = parseInt(b.seating);
              return seatingA - seatingB;
            }).map((seating,index)=>{
              if(element.typeTrip == 'round' && element.returnData){
                const seatingReturnFinal = [element.returnData.seatingOwner, ...element.returnData.passengers]
                return {
                  ...seating,
                  returnSeating: seatingReturnFinal[index]
                }
              }
              return seating
            })
            element.seatings = seatings;
          } catch (error) {
            element.errorPassenger = true;
            console.error("Error obteniendo pasajeros")
          }
          this.setLoadingTrip(element._id,false)
        }
        trips.push(element)
      }
      this.trips = trips;
    },
    async getTripsCustomer() {
      const { items, total } = await tripApi.getTrips(this.paginationTrips);
      const data : GetTripResponse[] = []
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if(!element.seatings){
          continue;
        }
        /*const passengers = await tripApi.getPassengers(element.code);
        element.seatings = passengers.seatings;
        if(element.typeTrip == 'round'){
          if(element.returnData !== undefined){
            element.returnData.seatings = passengers.returnData.seatings  
          }
          if(element.returnData!== undefined && element.returnDataActiveTrip){
            element.returnData.activeTrip = element.returnDataActiveTrip
          }
        }
        const seatings = element.seatings.sort((a, b) => {
          const seatingA = parseInt(a.seating);
          const seatingB = parseInt(b.seating);
          return seatingA - seatingB;
        }).map((seating,index)=>{
          if(element.typeTrip == 'round' && element.returnData){
            const seatingReturnFinal = [element.returnData.seatingOwner, ...element.returnData.passengers]
            return {
              ...seating,
              returnSeating: seatingReturnFinal[index]
            }
          }
          return seating
        })*/
        data.push(element)
      }
      this.trips = data;
      this.totalTrips = total;
    },
    setCustomer(customer?: string) {
      this.paginationTrips.customer = customer;
    },
    setCode(code?: string) {
      this.paginationTrips.code = code;
    },
    sortCode() {
      this.trips.sort(compareCodes);
    },
    sortDate() {
      this.trips.sort(compareDates);
    },
    setDates(dateInit?: string, dateFinish?: string) {
      this.paginationTrips.dateFinish = dateFinish;
      this.paginationTrips.dateInit = dateInit;
    },
    setWarningTrip(value: boolean) {
      this.paginationTrips.warningTrip = value;
    },
  },
});
function compareDates(a: GetTripResponse, b: GetTripResponse) {
  if (!a.dateExecution || !b.dateExecution) return 0;
  const dateA = new Date(a.dateExecution);
  const dateB = new Date(b.dateExecution);
  return dateB.getTime() - dateA.getTime();
}

function extractYearAndCount(code: string) {
  const match = code.match(/(\d{4})-(\d+)$/);
  if (match) {
    return {
      year: parseInt(match[1], 10),
      count: parseInt(match[2], 10),
    };
  }
  return null;
}
function compareCodes(a: GetTripResponse, b: GetTripResponse) {
  const aParts = extractYearAndCount(a.code);
  const bParts = extractYearAndCount(b.code);

  if (!aParts || !bParts) return 0;
  if (aParts.year !== bParts.year) {
    return aParts.year - bParts.year;
  }
  return bParts.count - aParts.count;
}
