
import HeaderCommonBooking from "@/components/Booking/HeaderCommonBooking.vue";
import { Options, Vue } from "vue-class-component";
import Passenger from "@/assets/images/passenger.png";
import { useAuthStore } from "@/store/modules/auth";
import {
  customScrollTo,
  formatCurrency,
  formatRutChile,
  getErrorCatch,
  requiredField,
  ruleValidateRut,
  validateEmail,
  validateNumber,
} from "@/utils";
import { UserApi } from "@/api/users";
import ModalCommon from "@/components/common/ModalCommon.vue";
import SignaturePad from "signature_pad";
import ListCoupons from "@/components/profile/ListCoupons.vue";
import UpdateDataClient from "@/components/profile/UpdateDataClient.vue";
import { formatDate } from "@/filters/formatDate";
import { FormVuetify } from "@/types/common/vuetify";
import TextLoaderButton from "@/components/common/LoaderButtons.vue";
import { AuthApi } from "@/api/auth";
import { useMainStore } from "@/store/modules/main";
import { Header } from "vue3-easy-data-table";
import { AddPassengerFavoriteRequest } from "@/types/request/guest";

@Options({
  components: {
    HeaderCommonBooking,
    ModalCommon,
    ListCoupons,
    UpdateDataClient,
    TextLoaderButton,
  },
})
export default class Profile extends Vue {
  public formatDateSimple = formatDate;
  public Passenger = Passenger;
  private userApi = new UserApi();
  private authApi = new AuthApi();
  private authStore = useAuthStore();
  private mainStore = useMainStore();
  public formatCurrency = formatCurrency;
  public validateEmail = validateEmail;
  public validateNumber = validateNumber;
  public ruleValidateRut = ruleValidateRut;
  public isActiveSign = false;
  public dataContract = "";
  public tab = 1;
  public signaturePad: SignaturePad | null = null;
  public isVisibleEditUser = false;
  public dataContractBlob = "";
  public dateCreatedSign = "";
  public ipSign = "";
  public loadingSendSign = false;
  public isAdminSign = false;
  public dateUpdatedSign = "";
  public visibleModalSign = false;
  public requiredField = requiredField;
  public loadingResetPassword = false;

  public passwordOld = "";
  public password = "";
  public confirmPassword = "";
  public showPassword = false;
  public showPassword2 = false;
  public showPassword3 = false;
  public loadingCard = false;
  public listSelected : string[] = []
  public allSelected = false;

  // passengers favorite
  public loadingTable = false;
  public isVisiblePassengerFavorite = false;
  public loadingCreatedPassengerFavorite = false;
  public openDeletePassenger= false;

  public createFavoritePassenger: AddPassengerFavoriteRequest = {
    firstName: "",
    lastName: "",
    rut: "",
    phone: "",
    email: "",
  };

  public headers: Header[] = [
    { text: "Nombres", value: "firstName" },
    { text: "Apellidos", value: "lastName" },
    { text: "Correo", value: "email" },
    { text: "RUT", value: "rut" },
    { text: "Celular", value: "phone" },
    { text: "", value: "actions" },
  ];

  mounted(): void {
    if (!this.isEmptyData) {
      this.signUser();
    } else {
      if (this.user) {
        if (this.user.isSign) {
          this.signUser();
        }
      }
    }
    const query = this.$route.query;
    if (query.addCard) {
      const existCard = Boolean(query.addCard);
      if (existCard) {
        this.mainStore.setNotification({
          isOpen: true,
          message: "Tarjeta agregada.",
          color: "green-darken-2",
        });
      }
    }
    if (query.section) {
      const section = query.section;
      if (section === "tosign") {
        this.tab = 2;
        customScrollTo("emptyData", 0);
        if (!this.user?.isSign) {
          this.visibleModalSign = true;
        }
      }
      if (section == "reset-password") {
        this.tab = 3;
      }
    }
  }

  addAllPassengers(value : boolean){
    if(this.allSelected){
      this.listSelected = [];
      this.allSelected = false;
      return;
    }
    this.listSelected = this.favoritePassengers.map(({_id}) => _id);
    this.allSelected = value;
  }

  signOut() {
    this.authStore.logout();
    this.$nextTick(() => {
      this.$router.push("/login");
    });
  }

  get user() {
    return this.authStore.user;
  }

  get isEmptyData() {
    if (!this.user) return true;
    if (!this.user.address) return true;
    if (!this.user.address.address || !this.user.address.commune) return true;
    return false;
  }

  get fullName() {
    if (!this.user) return "";
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get coupons() {
    if (!this.user) return [];
    return this.user.coupons || [];
  }

  get favoritePassengers() {
    if (this.authStore.user) {
      const user = this.authStore.user;
      return user.passengersFavorite;
    }
    return [];
  }

  back() {
    this.$router.back();
  }

  format() {
    this.createFavoritePassenger.rut = formatRutChile(
      this.createFavoritePassenger.rut
    );
  }

  formatReverse() {
    this.createFavoritePassenger.rut = this.createFavoritePassenger.rut.replace(
      /[^\dKk]/g,
      ""
    );
  }

  closeAddFavoritePassenger() {
    const form = this.$refs["formAddPassenger"] as FormVuetify;
    this.createFavoritePassenger = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      rut: "",
    };
    form.reset();
    this.isVisiblePassengerFavorite = false;
  }

  async deleteFavoritePassenger() {
    this.loadingTable = true;
    try {
      await this.userApi.deletePassenger(this.listSelected);
      this.listSelected = []
      await this.authStore.getUser()
      this.mainStore.setNotification({
        isOpen: true,
        message: "Pasajero eliminado.",
        color: "red-darken-2",
      });
      this.openDeletePassenger = false
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;
  }

  async addPassengerFavorite() {
    const form = this.$refs["formAddPassenger"] as FormVuetify;
    const validate = await form.validate();
    if (!validate.valid) {
      return;
    }
    this.loadingCreatedPassengerFavorite = true;
    try {
      await this.userApi.addPassengerFavorite(this.createFavoritePassenger);
      await this.authStore.getUser()
      this.mainStore.setNotification({
        isOpen: true,
        message: "Pasajero agregado.",
        color: "green-darken-2",
      });
      this.closeAddFavoritePassenger();
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingCreatedPassengerFavorite = false;
  }

  async signUser() {
    if (this.user && this.user.isSign) {
      try {
        const { dateCreated, ipSign, pdf, dateUpdated, isAdmin } =
          await this.userApi.getMyContract();
        this.dataContract = "data:application/pdf;base64," + pdf;
        this.dataContractBlob = pdf;
        this.dateCreatedSign = dateCreated;
        this.ipSign = ipSign;
        this.isAdminSign = isAdmin == undefined ? false : isAdmin;
        this.dateUpdatedSign = dateUpdated == undefined ? "" : dateUpdated;
      } catch (error) {
        getErrorCatch(error);
      }
      return;
    }
    try {
      const sign = await this.userApi.getContract();
      this.dataContract = "data:application/pdf;base64," + sign;
      this.dataContractBlob = sign;
    } catch (error) {
      getErrorCatch(error);
    }
  }
  closeModalActiveSign() {
    this.isActiveSign = false;
  }
  visibleSign() {
    this.isActiveSign = true;
    this.$nextTick(() => {
      try {
        const canvas = this.$refs["canvasSign"] as HTMLCanvasElement;
        if (!canvas) {
          throw new Error("Ocurrio un error al cargar la firma");
        }
        this.signaturePad = new SignaturePad(canvas);
      } catch (error) {
        getErrorCatch(error);
      }
    });
  }

  openEditUser() {
    this.isVisibleEditUser = true;
  }

  async sendSign() {
    if (this.signaturePad == null) return;
    this.loadingSendSign = true;
    try {
      if (this.signaturePad.isEmpty()) {
        throw new Error("Firma vacía");
      }
      const data = (this.signaturePad as any).toDataURL();
      await this.userApi.sendSign(data.split("base64,")[1]);
      await this.authStore.getUser();
      await this.signUser();
      this.isActiveSign = false;
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingSendSign = false;
  }

  closeEditUser() {
    this.isVisibleEditUser = false;
    this.signUser();
  }

  reloadSign() {
    (this.signaturePad as any).clear();
  }

  viewContract() {
    const byteCharacters = atob(this.dataContractBlob);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "application/pdf" });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, "_blank");
  }

  repeatPassword(value: string) {
    if (value == this.password) return true;
    return "Contraseña es diferente";
  }

  async resetPassword() {
    const form = this.$refs["formReset"] as FormVuetify;
    const validate = await form.validate();
    if (validate.valid) {
      this.loadingResetPassword = true;
      try {
        await this.authApi.updatePasswordAUTH(this.passwordOld, this.password);
        this.mainStore.setNotification({
          isOpen: true,
          message: "Contraseña actualizada.",
          color: "green-darken-2",
        });
        this.password = "";
        this.passwordOld = "";
        this.confirmPassword = "";
        form.reset();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingResetPassword = false;
    }
  }
  async deleteCard() {
    this.loadingCard = true;
    try {
      await this.userApi.deleteCard();
      await this.authStore.getUser();
      this.mainStore.setNotification({
        isOpen: true,
        message: "Tarjeta eliminada correctamente.",
        color: "green-darken-2",
      });
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingCard = false;
  }
  async addCard() {
    this.loadingCard = true;
    try {
      const { token, url } = await this.userApi.addCard(
        encodeURI("perfil?addCard=")
      );
      window.location.href = `${url}?token=${token}`;
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingCard = false;
  }
}
