export interface IStepsDataConditions {
  title: string;
  html: string;
  id: number;
}
export const dataStepsChangePolicy: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1">Es necesario que la persona usuaria haya leído y esté conforme con las condiciones sobre la protección de datos de carácter personal contenidas en esta política de privacidad y de cookies y que acepte el tratamiento de sus datos personales para que la persona responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El uso del sitio web implica la aceptación de la política de privacidad y de cookies del mismo.</p>
        <p class="text-body-1 mt-2">TACERCA se reserva el derecho a modificar su política de privacidad y de cookies de acuerdo a su propio criterio, o motivado por un cambio legislativo o jurisprudencial. Los cambios o actualizaciones de esta política de privacidad y de cookies serán puestos en conocimiento de la persona usuaria.</p>
        <p class="text-body-1 mt-2">Se recomienda a la persona usuaria consultar esta página de forma periódica para estar al tanto de los últimos cambios o actualizaciones.</p>
        <p class="text-body-1 mt-2">Esta política de privacidad y de cookies fue elaborada el día 21 de marzo de 2023 y se encuentra actualizada para adaptarse a la legislación vigente.</p>
    `,
    id: 0,
  },
];

export const dataConditionsGeneral: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1"><b class='text-primary'>1.1</b> TACERCA transportará al pasajero dentro de los horarios previstos y acordados con anterioridad, velando por la seguridad y el respeto de las normativas legales vigentes. Sin perjuicio de lo anterior, la empresa no podrá garantizar el cumplimiento de estos horarios en caso de eventos fortuitos o de fuerza mayor; por ejemplo: accidentes, cortes de carreteras, entre otros. En caso de necesidad, TACERCA, sin previo aviso, puede hacerse sustituir por otros transportadores si fuera necesario.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.2</b> Todo pasajero que no se presente en el punto acordado a la hora pactada, perderá su reserva sin que exista derecho a compensación o devolución alguna ni a reprogramación del servicio.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.3</b> El tiempo de espera del minibús en el punto de subida del pasajero no excederá los cuatro (4) minutos desde la hora programada. </p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.4</b> Los pasajeros serán llevados a su punto de bajada siguiendo una ruta lógica, y no por orden de ingreso al minibús.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.5</b> La hora de subida en el punto seleccionado puede variar en un rango de diez (10) minutos después de la hora programada en la reserva.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.6</b> TACERCA no permite fumar ni consumir bebidas alcohólicas dentro de sus vehículos; asimismo, se reserva el derecho de transportar pasajeros que se encuentren en evidente estado de ebriedad, sin que corresponda devolución ni reprogramación del servicio.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.7</b> Al momento de reservar se enviará un correo de confirmación del servicio que se envía al momento de comprar el ticket se incluye el detalle de la reserva para su verificación.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.8</b> Al efectuarse la compra los servicios no podrán ser modificados en cuanto a la fecha, horario y punto de subida o bajada, de querer cambiarlos es necesario cancelar el viaje y comprar uno nuevamente.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>1.9</b> El pasajero al proporcionar sus datos personales, conoce, acepta y autoriza a TACERCA a utilizar y tratar de forma automatizada sus datos personales e información suministrada, los cuales formarán parte de la base de datos de TACERCA y podrán ser utilizados, en forma enunciativa, más no limitativa, para: identificar, ubicar, comunicar, contactar, enviar información y/o bienes al pasajero. Del mismo modo, acepta y autoriza el envío y/o transferencia de estos datos a terceros, así como cualquier otra utilización que TACERCA estime conveniente de acuerdo a la Ley N° 19.628 sobre Protección de Datos de carácter personal.</p>
    `,
    id: 0,
  },
];

export const dataConditionsShop: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1"><b class='text-primary'>2.1</b> La reserva se realizará vía app o contact center y esta deberá estar pagada al momento de iniciar el servicio por el monto fijado por TACERCA.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>2.2</b> La compra de tickets con tarjeta de crédito está sujeta a aprobación del ente emisor de su tarjeta.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>2.3</b> Se pueden comprar tickets hasta para 14 pasajeros.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>2.4</b> La información de los pasajeros podrá ser guardada en nuestras bases de datos y manejada en forma confidencial para usos exclusivos de la empresa.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>2.5</b> En dicha reserva podrán hacerse efectivos cupones de descuento los cuales deberán ser entregados como parte de la información al momento de hacer la reserva y así incluir este como descuento en la tarifa estimada de cobro.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>2.6</b> Los tickets serán enviados al email que cada pasajero registre al momento de hacer su reserva y confirmar su compra.</p>
    `,
    id: 0,
  },
];

export const mediosPago: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1"><b class='text-primary'>3.1</b> TACERCA acepta como medios de pago: tarjetas de crédito Visa, MasterCard, Magna, Diners Club y American Express; tarjetas de debito y cupones (códigos) de descuento.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>3.2</b> Las tarjetas de debito y crédito Visa, Mastercard, Magna, Diners Club y American Express son autorizadas por Flow S.A., quien emite un comprobante de pago una vez este haya sido procesado exitosamente, mediante Webpay de Transbank.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>3.3</b> Las Tarifas de TACERCA son exentas de IVA.</p>
    `,
    id: 0,
  },
];
export const derechosRetrator: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1"><b class='text-primary'>4.1</b> El proceso de devolución debe ser iniciado por el pasajero en la opción cancelar del ticket comprado que encontrará en la sección mis viajes en nuestra aplicación; comprometiéndose TACERCA a efectuar la devolución en un plazo no mayor a 10 días hábiles, en caso que corresponda.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>4.2</b> En caso que el servicio no sea ejecutado por causas no imputables a TACERCA, este no será reprogramado y tampoco se realizará una devolución de dinero.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>4.3</b> Para cancelar el viaje y obtener la devolución del dinero, el cliente debe cancelar el viaje a través a la aplicación con un mínimo de 3 horas de anticipación al horario de inicio del viaje.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>4.4</b> La responsabilidad de TACERCA se limita únicamente al valor del ticket pagado.</p>
    `,
    id: 0,
  },
];

export const pagosApp: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1 "><b class='text-primary'>5.1 Registro de método de pago </b> </p>
        <p class="text-body-1 pl-7"><b class='text-primary'>5.1.1</b> Cuando introduces una nueva tarjeta de crédito como método de pago, realizamos un pequeño cargo en tu tarjeta para confirmar la validez de ésta. Una vez hecha esta verificación, te devolveremos el importe al instante. Para ciertos bancos esta devolución puede demorar unos días en verse reflejada en tu estado bancario.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>5.2 Pago de un viaje </b> </p>
        <p class="text-body-1  pl-7 mt-2"><b class='text-primary'>5.2.1</b> Cuando solicitas un viaje, ya sea, inmediato o programado, se realizará un cargo en tu tarjeta por el precio fijado de tu viaje al momento de la solicitud. Si al realizar el pago el banco emisor no acepta el cargo, tendrás que elegir otro método de pago. Es posible que el cargo hecho por TACERCA aparezca bajo el concepto de “Flow S.A” en tu estado bancario.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>5.3 Cancelación de un viaje </b></p>
        <p class="text-body-1  pl-7 mt-2"><b class='text-primary'>5.3.1</b> Al cancelar un viaje previamente pagado con tarjeta de crédito a través de nuestra aplicación, el cargo previamente se devolverá a través de transferencia bancaria en un máximo de 10 días hábiles. Recuerda que la devolución del dinero sólo se hará en cancelaciones con hasta 3 horas de anticipación a iniciar el viaje. En caso que la cancelación sea hecha por el conductor deberás realizar la solicitud de devolución directamente a nuestro contact center o a través del formulario de contacto en nuestro sitio web www.tacerca.cl</p>
    `,
    id: 0,
  },
];

export const condicionesEquipaje: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1"><b class='text-primary'>7.1</b> Los artículos personales y/o equipaje de mano es de exclusiva responsabilidad del pasajero; en ningún caso TACERCA será responsable de su extravío o pérdida. *Se define como artículo personal y/o equipaje de mano: Aquel artículo de tamaño menor (cartera, mochila pequeña, que el pasajero lleve consigo) y maleta pequeña (cabina).</p>
    <p class="text-body-1 font-weight-bold text-primary">Exclusiones de Servicio por Equipaje</p>
    <p class="text-body-1">Para transportar este tipo de equipajes es necesario consultar previamente en nuestros canales de comunicación si el minibús cuenta con el espacio suficiente en el maletero.</p>
    <ul class="mt-3 cookies-rrss">
    <li>Instrumentos musicales (Aquellos que los pasajeros no puedan llevar en las piernas como equipaje de mano)</li>
    <li>Equipaje de cabina.</li>
    <li>Tablas de surf, windsurf, snowboard, Ski, etc.</li>
    <li>Palos de golf.</li>
    <li>Scooters.</li>
    </ul>
    <p class="text-body-1 mt-2"><b class='text-BLACK'>7.2 Límite de Responsabilidad <br/> TACERCA no se responsabiliza legalmente en caso de pérdida o daño de lo transportado.</b></p>
    `,
    id: 0,
  },
];

export const transportePerros: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1"><b class='text-primary'>6.1</b> <b>Perros guías o lazarillos o Perros de Asistencia: </b><br/> Perros guías o lazarillos o Perros de Asistencia, serán admitidos sin costo adicional en el servicio que el cliente solicite. La forma de transportarlo lo determinará el pasajero según su preferencia. La instrucción se la dará el pasajero al perro guía y se respetará aquello. Se consultará al pasajero la mejor forma de anclarlo a una parte fija del vehículo para su transporte si así se pudiera.</p>
        <p class="text-body-1 mt-2"><b class='text-primary'>6.2</b> <b>Mascotas de acompañamiento :</b><br/> Estas mascotas si es un animal pequeño, en jaula, la que debe estar anclada a algún elemento fijo del vehículo o bien, en bolso de transporte que contenga un sistema de contención o anclaje. Estos sistemas el pasajero debe proveerlo y es responsabilidad de este contar con ello al momento de ingresar al vehículo. Si es una mascota más grande deberá tener jaula o arnés y anclarlo a una parte fija del vehículo y utilizar otro asiento con cubre asiento desechable para que pueda acompañar al pasajero. Este cubreasiento debe ser provisto por el pasajero, o de lo contrario deberá transportarse en su jaula o bolso de transporte. ¿Dónde debe ir la mascota?. Se transportarán desde la segunda fila, o bien en la zona destinada a equipaje en el minibús donde el ambiente es el mismo que el de los pasajeros.</p>
    `,
    id: 0,
  },
];

export const dataStepsCookies: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1">El acceso a este sitio web/aplicación puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada persona usuaria -en los distintos dispositivos que pueda utilizar para navegar- para que el servidor recuerde cierta información que posteriormente, y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la hacen más amigable y no dañan el dispositivo de navegación.</p>
        <p class="text-body-1 mt-2">La información recolectada a través de las cookies puede incluir la fecha y hora de visitas del sitio web, las páginas visionadas, el tiempo que ha estado en el sitio web y los sitios visitados justo antes y después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de teléfono de la persona usuaria o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer información del disco duro de la persona usuaria o robar información personal. La única manera de que la información privada de la persona usuaria forme parte del archivo cookie es que la persona usuaria dé personalmente esa información al servidor.</p>
        <p class="text-body-1 mt-2">Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido, para la utilización de las mismas será necesario el consentimiento de la persona usuaria. Este consentimiento será comunicado, en base a una elección auténtica, ofrecido mediante una afirmación afirmativa y positiva, antes del tratamiento inicial, removible y documentado.</p>
    `,
    id: 0,
  },
  {
    title: "1. Cookies propias",
    html: `<p class="text-body-1 mt-2">Son aquellas cookies que son enviadas al ordenador o dispositivo de la persona usuaria y gestionadas exclusivamente por TACERCA para el mejor funcionamiento del sitio web. La información que se recaba se emplea para mejorar la calidad del sitio web, su contenido y la experiencia de la persona usuaria. Estas cookies permiten reconocer a la persona usuaria como visitante recurrente del sitio web y adaptar el contenido para ofrecerle contenidos que se ajusten a sus preferencias.</p>`,
    id: 1,
  },
  {
    title: "2. Cookies de redes sociales",
    html: `
        <p class="text-body-1">TACERCA incorpora plugins de redes sociales que permiten acceder a las mismas a partir del sitio web. Por esta razón, las cookies de redes sociales pueden almacenarse en el navegador de la persona usuaria. Las personas titulares de dichas redes sociales disponen de sus propias políticas de protección de datos y de cookies, siendo ellos mismos, en cada caso, responsables de sus propios ficheros o bases de datos y de sus propias prácticas de privacidad. La persona usuaria debe referirse a las mismas para informarse acerca de dichas cookies y, en su caso, del tratamiento de sus datos personales. Únicamente a título informativo se indica a continuación los enlaces de algunas de las redes sociales en los que se pueden consultar dichas políticas de privacidad y/o de cookies:</p>
        <ul class="mt-3 cookies-rrss">
          <li><a href="https://www.facebook.com/policies/cookies" target="_blank">https://www.facebook.com/policies/cookies</a></li>
          <li><a href="https://twitter.com/es/privacy" target="_blank">https://twitter.com/es/privacy</a></li>
          <li><a href="https://www.facebook.com/help/instagram/155833707900388" target="_blank">https://www.facebook.com/help/instagram/155833707900388</a></li>
          <li><a href="https://www.youtube.com/intl/es/about/policies/#community-guidelines" target="_blank">https://www.youtube.com/intl/es/about/policies/#community-guidelines</a></li>
          <li><a href="https://policies.google.com/privacy?hl=es" target="_blank">https://policies.google.com/privacy?hl=es</a></li>
          <li><a href="https://es.linkedin.com/legal/privacy-policy" target="_blank">https://es.linkedin.com/legal/privacy-policy</a></li>
          <li><a href="https://policy.pinterest.com/es/privacy-policy" target="_blank">https://policy.pinterest.com/es/privacy-policy</a></li>
          <li><a href="https://www.tiktok.com/legal/privacy-policy?lang=es" target="_blank">https://www.tiktok.com/legal/privacy-policy?lang=es</a></li>
        </ul>`,
    id: 2,
  },
  {
    title: "3. Deshabilitar, rechazar y eliminar cookies",
    html: `<p class="text-body-1">La persona usuaria puede deshabilitar, rechazar y eliminar las cookies -total o parcialmente- instaladas en su dispositivo mediante la configuración de su navegador (entre los que se encuentran, por ejemplo, Chrome, Firefox, Safari). En este sentido, los procedimientos para rechazar y eliminar cookies pueden diferir de un navegador de internet a otro. En consecuencia, la persona usuaria debe acudir a las instrucciones facilitadas por el propio navegador de internet que esté utilizando. En el supuesto de que rechace el uso de cookies -total o parcialmente- podrá seguir usando el sitio web, aunque podrá tener limitada la utilización de algunas prestaciones del mismo.</p>`,
    id: 3,
  },
];

export const dataStepsPrivacy: IStepsDataConditions[] = [
  {
    title: "",
    html: `<p class="text-body-1">El sitio web TACERCA, en adelante "TACERCA", "TACERCA" o "la aplicación" indistintamente, pone en conocimiento de las personas que hagan uso del mismo, en adelante "personas usuarias", la presente política de privacidad y protección de los datos personales.</p>
        <p class="text-body-1 mt-2">Esta política de privacidad y protección de los datos personales forma parte de los Términos y Condiciones Generales de Uso del sitio web TACERCA. Para mayor información, revise los Términos y Condiciones Generales de Uso contenidos en el sitio web, los cuales se encuentran disponibles en el siguiente enlace: (completar por el encargado).</p>
        <p class="text-body-1 mt-2">La lectura de la misma le permitirá a las personas usuarias conocer el modo en que TACERCA recolecta, trata y protege de sus datos personales.</p>
        <p class="text-body-1 mt-2">El acceso, uso y permanencia en el sitio web TACERCA implica la aceptación de la presente política de privacidad.</p>
        <p class="text-body-1 mt-2">De particular importancia resultan la aplicación de la Ley N° 19.628 de Protección de Datos Personales y la Ley N° 19.496 sobre Derechos del Consumidor.</p>
        <p class="text-body-1 mt-2">Esta política, en lo que no contraviene la legislación chilena, está adaptada al Reglamento Europeo de Protección de Datos (RGPD).</p>
    `,
    id: 0,
  },
  {
    title: "1. Definiciones",
    html: `<ol type="a">
            <li><p class="text-body-1 mt-2">Almacenamiento de datos: conservación o custodia de datos en un registro, banco o base de datos</p></li>
            <li><p class="text-body-1 mt-2">Dato estadístico: aquel que en su origen, o como consecuencia de su tratamiento, no puede ser asociado a un titular identificado o identificable.</p></li>
            <li><p class="text-body-1 mt-2">Datos de carácter personal o datos personales: aquellos relativos a cualquier información concerniente a personas naturales, identificadas o identificables.</p></li>
            <li><p class="text-body-1 mt-2">Almacenamiento de datos: conservación o custodia de datos en un registro, banco o base de datos</p></li>
            <li><p class="text-body-1 mt-2">Datos sensibles: aquellos datos personales que se refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como los hábitos personales, el origen racial, las ideologías y opiniones políticas, las creencias o convicciones religiosas, los estados de salud físicos o psíquicos y la vida sexual.</p></li>
            <li><p class="text-body-1 mt-2">Registro, banco o base de datos: conjunto organizado de datos de carácter personal, sea automatizado o no y cualquiera sea la forma o modalidad de su creación u organización, que permita relacionar los datos entre sí, así como realizar todo tipo de tratamiento de datos.</p></li>
            <li><p class="text-body-1 mt-2">Responsable del registro, banco o base de datos: la persona natural o jurídica a quien compete las decisiones relacionadas con el tratamiento de los datos de carácter personal, también conocido como responsable del tratamiento de datos personales.</p></li>
            <li><p class="text-body-1 mt-2">Titular de los datos: persona natural a la que se refieren los datos de carácter personal.</p></li>
            <li><p class="text-body-1 mt-2">Tratamiento de datos: cualquier operación o complejo de operaciones o procedimientos técnicos, de carácter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, transmitir o cancelar datos de carácter personal, o utilizarlos en cualquier otra forma.</p></li>
            <li><p class="text-body-1 mt-2">Usuarios: personas que solicitan o reciben el transporte solicitado por a traves de su cuenta TACERCA</p></li>
        </ol>`,
    id: 1,
  },
  {
    title: "2. Principios aplicables al tratamiento de los datos personales",
    html: `<p class="text-body-1 mt-1 mb-4">El tratamiento de los datos personales de las personas usuarias se someterá a los siguiente principios:</p>
        <ol type="a">
            <li> <p class="text-body-1 mt-2">Principio de licitud, lealtad y transparencia: se requerirá en todo momento el consentimiento de la persona usuaria previa información completamente transparente de los fines para los cuales se recogen los datos personales.</p></li>
            <li> <p class="text-body-1 mt-2">Principio de limitación de la finalidad: los datos personales serán recogidos con fines determinados, explícitos y legítimos.</p></li>
            <li> <p class="text-body-1 mt-2">Principio de minimización de datos: los datos personales recogidos serán únicamente los estrictamente necesarios en relación con los fines para los que son tratados.</p></li>
            <li> <p class="text-body-1 mt-2">Principio de exactitud: los datos personales deben ser exactos y serán siempre actualizados.</p></li>
            <li> <p class="text-body-1 mt-2">Principio de limitación del plazo de conservación: los datos personales solo serán mantenidos de forma que se permita la identificación de la persona usuaria durante el tiempo necesario para los fines de su tratamiento.</p></li>
            <li> <p class="text-body-1 mt-2">Principio de integridad y confidencialidad: los datos personales serán tratados de manera que se garantice su seguridad y confidencialidad.</p></li>
            <li> <p class="text-body-1 mt-2">Principio de responsabilidad proactiva: la persona responsable del tratamiento será responsable de asegurar que los principios anteriores se cumplan.</p></li>
        </ol>`,
    id: 2,
  },
  /* {
    title: "3. Responsables del registro, banco o base de datos",
    html: `<p class="text-body-1 mt-1">Las personas responsables del tratamiento de los datos personales recogidos a través del sitio web TACERCA son:</p>
        <ul>
          <li><p class="text-body-1 mt-1">T ACERCA SPA</p></li>
          <li><p class="text-body-1 mt-1">Camila Alcayaga, 17.313.347-2</p></li>
        </ul>
        <p class="text-body-1 mt-2">Las personas individualizadas, en adelante, las responsables del tratamiento, son corresponsables del tratamiento de datos personales.</p>
        <p class="text-body-1 mb-3">Los datos para tomar contacto con las personas responsables son:</p>
        <ul>
          <li><p class="text-body-1 mt-1">hola@tacerca.cl</p></li>
          <li><p class="text-body-1 mt-1">Vista Hermosa 9790 oficina 3, Cerrillos</p></li>
          <li><p class="text-body-1 mt-1">+569 2724 4690</p></li>
        </ul>
        `,
    id: 3,
  },
  {
    title:
      "4. Recolección y registro de datos de carácter personal y finalidad de su tratamiento",
    id: 4,
    html: `<p class="text-body-1 ">Los datos personales obtenidos por TACERCA mediante los formularios extendidos en sus páginas quedarán incorporados y serán tratados en nuestras bases de datos con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre TACERCA y las personas usuarias, o para el mantenimiento de la relación que se establezca en los formularios que éstas rellenen, o para atender una solicitud o consulta de las mismas</p>
        <p class="text-body-1 mb-5">En concreto, los datos de las personas usuarias serán obtenidos por TACERCA a través de la o las siguientes acciones:</p>
        <ul><li><p class="text-body-1">Al momento de ingresar sus datos a la aplicación, cuando los usuarios se les de acceso a la plataforma TACERCA y ellos actualicen sus datos lo que puede incluir su nombre, correo electrónico, número de telefono, nombre de usuario y contraseña, domicilio, foto de perfil, detalles bancarios de pago; Al momento de hacer compras con tarjetas de crédito, debido, prepago o similares a través de la aplicación; Al momento de realizar consultas, dejar recomendaciones o hacer reclamos, TACERCA se recopilan los datos que los usuarios envián cuando se contactan con el soporte de cliente de TACERCA; se recopila los datos de ubicación de los usuarios cuando utilizan la Aplicación desde su dispositivo móvil, desde que se inicia el trayecto, hasta que se termina; sobre los datos de uso se recopila la información de como los usuarios interactuan con los servicios que se prestan tales como fecha y hora de acceso, funciones de la aplicación, tipo de navegador, fallos de la aplicación y otras actividades del sistema, sobre la comunicación se recopilara los datos de las comunicaciones por telefono, mensajes de texto en la plataforma de comunicación de la aplicación. </p></li></ul>`,
  },
  {
    title: "5. Categoría de datos personales",
    id: 5,
    html: `
        <p class="text-body-1">Las categorías de datos que se tratan en TACERCA son únicamente datos identificativos. En ningún caso se tratan categorías de datos personales de carácter sensibles, como el estado de salud de las personas o sus opiniones políticas o creencias religiosas.</p>
        <p class="text-body-1 mt-2">No pueden ser objeto de tratamiento los datos sensibles, salvo cuando la ley lo autorice, exista consentimiento de la persona titular de dichos datos o éstos sean datos necesarios para la determinación u otorgamiento de beneficios de salud que correspondan a sus titulares.</p>`,
  },
  {
    title: "6. Base legal para el tratamiento de los datos personales",
    id: 6,
    html: `
        <p class="text-body-1">El tratamiento de los datos personales solamente puede efectuarse cuando la ley lo autorice o el titular consienta expresamente en ello.</p>
        <p class="text-body-1 mt-2">TACERCA se compromete a recabar el consentimiento expreso, escrito y verificable de la persona usuaria respecto de los datos personales de los que es titular, para el tratamiento de dichos datos para uno o varios fines específicos, debidamente informados.</p>
        <p class="text-body-1 mt-2">También se informará de la posible comunicación al público de los datos almacenados y tratados.</p>
        <p class="text-body-1 mt-2">No requiere autorización el tratamiento de datos personales que provengan o que se recolecten de fuentes accesibles al público, cuando sean de carácter económico, financiero, bancario o comercial, se contengan en listados relativos a una categoría de personas que se limiten a indicar antecedentes tales como la pertenencia del individuo a ese grupo, su profesión o actividad, sus títulos educativos, dirección o fecha de nacimiento, o sean necesarios para comunicaciones comerciales de respuesta directa o comercialización o venta directa de bienes o servicios.</p>
        <p class="text-body-1 mt-2">Tampoco requerirá de esta autorización el tratamiento de datos personales que realicen personas jurídicas privadas para el uso exclusivo suyo, de sus asociados y de las entidades a que están afiliadas, con fines estadísticos, de tarificación u otros de beneficio general de aquéllos.</p>
        <p class="text-body-1 mt-2">Los datos personales deben utilizarse solamente para los fines para los cuales hubieren sido recolectados, salvo que provengan o se hayan recolectado de fuentes accesibles al público.</p>
        <p class="text-body-1 mt-2">No pueden ser objeto de tratamiento los datos sensibles, salvo cuando la ley lo autorice, exista consentimiento del titular o sean datos necesarios para la determinación u otorgamiento de beneficios de salud que correspondan a sus titulares.</p>
        <p class="text-body-1 mt-2">La persona usuaria tendrá derecho a retirar su consentimiento en cualquier momento. Será tan fácil retirar el consentimiento como darlo. Como regla general, el retiro del consentimiento no condicionará el uso del sitio web.</p>
        <p class="text-body-1 mt-2">En las ocasiones en las que la persona usuaria deba o pueda facilitar sus datos a través de formularios para realizar consultas, solicitar información o por motivos relacionados con el contenido del sitio web, se le informará en caso de que la cumplimentación de alguno de ellos sea obligatorio debido a que los mismos sean imprescindibles para el correcto desarrollo de la operación realizada.</p>`,
  },
  {
    title: "7. Período de retención de los datos personales",
    id: 7,
    html: `
        <p class="text-body-1">Los datos personales solamente serán retenidos durante el tiempo mínimo necesario para los fines de su tratamiento y, en todo caso, únicamente hasta que la persona usuaria solicite su supresión.</p>
        <p class="text-body-1 mt-2">En el momento en que se obtengan los datos personales se informará a la persona usuaria sobre el plazo durante el cual se conservarán los datos personales o, cuando eso no sea posible, los criterios utilizados para determinar este plazo.</p>`,
  },
  {
    title: "8. Destinatarios de los datos personales",
    id: 8,
    html: `
        <p class="text-body-1">Los datos personales de las personas usuarias podrán ser compartidos con terceras personas, para lo cual se deberá dejar constancia en esta política de privacidad, efectuando su actualización, si correspondiere, de la individualización de la persona destinataria, el motivo y el propósito de la transmisión de datos hacia ella, y el tipo de datos que se transmiten.</p>
        <p class="text-body-1 mt-2">La persona destinataria solamente podrá utilizar los datos personales que se le transmitan para los fines que motivaron la transmisión.</p>
        <p class="text-body-1 mt-2">En caso de que la persona responsable del tratamiento tenga la intención de transferir datos personales a un tercer país u organización internacional, en el momento en que se obtengan los datos personales se informará a las personas usuarias acerca del tercer país u organización internacional al cual se tiene la intención de transferir los datos, salvo que dicha transmisión sea en cumplimiento de lo dispuesto en los tratados y convenios vigentes.</p>`,
  },
  {
    title: "9. Datos personales de menores de edad",
    id: 9,
    html: `
        <p class="text-body-1">Solamente las personas mayores de 14 años podrán otorgar su consentimiento para el tratamiento de sus datos personales de forma lícita por TACERCA.</p>
        <p class="text-body-1 mt-2">Si se trata de una persona menor de 14 años será necesario el consentimiento de los padres o representantes legales o por quien tiene a su cargo el cuidado personal del niño o niña, salvo que expresamente lo autorice o mandate la ley.</p>
        <p class="text-body-1 mt-2">Los datos sensibles de las personas adolescentes menores de 16 años, solamente se podrán tratar con el consentimiento otorgado por sus padres o representantes legales o quien tiene a su cargo el cuidado personal del menor, salvo que expresamente lo autorice o mandate la ley.</p>`,
  },
  {
    title: "10. Secreto y seguridad de los datos personales",
    id: 10,
    html: `
        <p class="text-body-1 mt-2">TACERCA se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los datos de carácter personal y se evite la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizado a dichos datos.</p>
        <p class="text-body-1 mt-2">El sitio web/aplicación TACERCA cuenta con un certificado SSL (Secure Socket Layer), que asegura que los datos personales se transmiten de forma segura y confidencial, al ser esta transmisión entre el servidor y la persona usuaria, y en retroalimentación, totalmente cifrada o encriptada.</p>
        <p class="text-body-1 mt-2">Sin embargo, debido a que TACERCA no puede garantizar la inexpugnabilidad de internet ni la ausencia total de hackers u otros que accedan de modo fraudulento a los datos personales, la persona responsable del tratamiento se compromete a comunicar a las personas usuarias, sin dilación indebida, la ocurrencia de cualquier violación de la seguridad de los datos personas que sea probable que entrañe un alto riesgo para los derechos y libertades de las personas físicas. Se entiende por violación de la seguridad de los datos personales toda violación de la seguridad que ocasione la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizado a dichos datos.</p>
        <p class="text-body-1 mt-2">Los datos personales serán tratados como confidenciales por la persona responsable del tratamiento, quien se compromete a informar de y a garantizar por medio de una obligación legal o contractual que dicha confidencialidad sea respetada por sus empleados, asociados y toda persona a la cual le haga accesible la información.</p>`,
  },
  {
    title: "11. Derechos derivados del tratamiento de los datos personales",
    id: 11,
    html: `
        <p class="text-body-1">La persona usuaria tiene sobre TACERCA y podrá, por tanto, ejercer frente a la persona responsable del tratamiento los siguientes derechos:</p>
        <ol type="a">
            <li><p class="text-body-1 mt-2">Derecho de acceso: es el derecho que tienen las personas usuarias a obtener confirmación de si TACERCA está tratando o no sus datos personales y, en caso afirmativo, obtener información sobre sus datos concretos de carácter personal y del tratamiento que TACERCA haya realizado o realice, así como, entre otras, de la información disponible sobre el origen de dichos datos y las personas destinatarias de las comunicaciones realizadas o previstas de los mismos.</p></li>
            <li><p class="text-body-1 mt-2">Derecho de rectificación: es el derecho de las personas usuarias a que se modifiquen sus datos personales que resulten ser inexactos o, teniendo en cuenta los fines del tratamiento, incompletos.</p></li>
            <li><p class="text-body-1 mt-2">Derecho de supresión ("el derecho al olvido"): Es el derecho de las personas usuarias, siempre que la legislación vigente no establezca lo contrario, a obtener la supresión de sus datos personales cuando estos ya no sean necesarios para los fines para los cuales fueron recogidos o tratados; cuando la persona usuaria haya retirado su consentimiento al tratamiento y no exista otro motivo legítimo para continuar con el mismo; cuando los datos personales hayan sido tratados ilícitamente; cuando los datos personales deban suprimirse en cumplimiento de una obligación legal.<br/>Si los datos personales suprimidos o rectificados hubieren sido comunicados previamente a tercera personas determinadas o determinables, la persona responsable del tratamiento deberá avisarles a la brevedad posible la operación efectuada. Si no fuese posible determinar las personas a quienes se les haya comunicado, pondrá un aviso que pueda ser de general conocimiento para quienes usen la información de la base datos.<br/>No podrá pedirse la rectificación, supresión o bloqueo de datos personales almacenados por mandato legal, fuera de los casos contemplados en la ley respectiva.</p></li>
            <li><p class="text-body-1 mt-2">Derecho a la limitación del tratamiento: es el derecho de las personas usuarias a limitar el tratamiento de sus datos personales. La persona usuaria tiene derecho a obtener la limitación del tratamiento cuando impugne la exactitud de sus datos personales, el tratamiento sea ilícito, el responsable del tratamiento ya no necesite los datos personales, pero la persona usuaria los necesite para hacer reclamaciones, y cuando la persona usuaria se haya opuesto al tratamiento.</p></li>
            <li><p class="text-body-1 mt-2">Derecho a la portabilidad de los datos: en caso que el tratamiento se efectúe por medios automatizados, la persona usuaria tendrá derecho a recibir de la persona responsable del tratamiento sus datos personales en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento. Siempre que sea técnicamente posible, la persona responsable del tratamiento transmitirá directamente los datos a esa otra responsable.</p></li>
            <li><p class="text-body-1 mt-2">Derecho de oposición: es el derecho de las persona usuarias a que no se lleve a cabo el tratamiento de sus datos de carácter personal o se cese el tratamiento de los mismos por parte de TACERCA.</p></li>
            <li><p class="text-body-1 mt-2">Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles: es el derecho de las personas usuarias a no ser objeto de una decisión individualizada basada únicamente en el tratamiento automatizado de sus datos personales, incluida la elaboración de perfiles, existente salvo que la legislación vigente establezca lo contrario.</p></li>
        </ol>
        <p class="text-body-1 mt-2">La persona usuaria podrá ejercitar sus derechos mediante comunicación escrita dirigida a la persona responsable del tratamiento, conforme lo establece el artículo 16 de la Ley N° 19.628.</p>`,
  },
  {
    title: "12. Reclamaciones ante la autoridad de control",
    id: 12,
    html: `<p class="text-body-1">En caso de que la persona usuaria considere que existe un problema o infracción de la normativa vigente en la forma en la que se están tratando sus datos personales, tendrá derecho a ejercer las acciones que estime pertinentes ante los Tribunales de Justicia.</p>`,
  }, */
];
