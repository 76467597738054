import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box-data" }
const _hoisted_2 = { class: "text-helvetica h-medium color-normal" }
const _hoisted_3 = { class: "d-flex align-center" }
const _hoisted_4 = { class: "location-icon mr-md-2 mr-1" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "text-subtitle color-sec text-helvetica h-regular ml-md-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_img, {
          class: "bg-white",
          src: _ctx.LocationPayment,
          height: "30",
          width: "20",
          contain: ""
        }, null, 8, ["src"])
      ]),
      _createElementVNode("h4", {
        class: "text-h6 text-lg-h5 text-sm-h7 text-height-h6 my-sm-2 my-sm-0 d-flex d-sm-block color-normal text-helvetica h-bold position-relative",
        title: _ctx.getStationSelected(_ctx.valueTitle)
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.getStationSelected(_ctx.valueTitle)), 1)
      ], 8, _hoisted_5)
    ]),
    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.subTitle) + " " + _toDisplayString(_ctx.convertTo12HourFormat(_ctx.valueSubTitle)), 1)
  ]))
}