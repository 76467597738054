
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../common/ModalCommon.vue";
import {
  PlanningTripsResponse,
  TripFailDeleted,
} from "@/types/response/planning";
import { getErrorCatch } from "@/utils";
import { PlanningTripAPI } from "@/api/planning";
import { useMainStore } from "@/store/modules/main";
import { Header } from "vue3-easy-data-table";
import { getDate } from "@/filters/formatDate";

@Options({
  name: "CalendarPlanning",
  emits: ["closeModal",'loadPlanning'],
  components: {
    ModalCommon,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    planning: {
      type: Object,
      required: false,
      default: null,
    },
  },
})
export default class ModalDeletePlanning extends Vue {
  public visible!: boolean;
  public planning!: PlanningTripsResponse;
  private planningApi = new PlanningTripAPI();
  private mainStore = useMainStore();
  public getDate = getDate;
  public loading = false;
  public existTrips = false;
  public tripsDeleted: TripFailDeleted[] = [];
  public titleLoading= "Eliminar todos";

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < 572;
  }

  public headers: Header[] = [
    { text: "Día de viaje", value: "dateExecuting" },
    { text: "Asientos", value: "seatings" },
  ];

  get title() {
    let titleFinal = "Eliminar planificación";
    if (this.planning) {
      titleFinal += ` ${this.planning.name}`;
    }
    return titleFinal;
  }

  closeModal() {
    this.existTrips = false;
    this.tripsDeleted = [];
    this.$emit("closeModal");
  }

  async deletePlanning() {
    if (!this.planning) return;
    this.loading = true;
    try {
      const resultDeleted = await this.planningApi.deletePlanning(
        this.planning._id
      );
      if (resultDeleted?.data.length > 0) {
        const { data, message } = resultDeleted;
        this.tripsDeleted = data;
        this.existTrips = true;
        this.mainStore.setNotification({
          isOpen: true,
          message,
          color: "orange-darken-2",
        });
      } else {
        this.mainStore.setNotification({
          isOpen: true,
          message: 'Planificación eliminada',
          color : 'red-darken-2'
        })
        this.closeModal();
        this.$emit('loadPlanning')
      }
    } catch (error) {
      getErrorCatch(error);
    }
    this.loading = false;
  }
  async deleteAllActive() {
    this.loading = true;
    try {
      this.titleLoading = "Eliminando...";
      let failActiveTrip = false;
      for (let index = 0; index < this.tripsDeleted.length; index++) {
        const element = this.tripsDeleted[index];
        this.titleLoading = `Eliminando el dia ${element.dateExecuting.split('T')[0]}`
        try {
          await this.planningApi.removeActiveTrip(element._id);
        } catch (error) {
          failActiveTrip = true
          this.mainStore.setNotification({
            isOpen: true,
            message: `Error eliminando el dia ${element.dateExecuting.split('T')[0]}`
          })
        }
      }
      this.titleLoading = 'Eliminar todos';
      if(!failActiveTrip){
        this.tripsDeleted = [];
        this.existTrips = false;
        this.mainStore.setNotification({
          isOpen: true,
          message: 'Días eliminados, puede continuar.',
          color: 'green-darken-2'
        })
      }
    } catch (error) {
      getErrorCatch(error);
    }
    this.loading = false;
  }
}
