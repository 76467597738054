
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'Help'
})
export default class Help extends Vue {

  public questions = [{
    title: '¿Cómo se realizan los viajes?',
    description: '<p class="px-2 py-2">Descarga la app, elije tu ruta, horario y reserva tu asiento.</p>',
    id: 1,
  }, {
    id: 2,
    title: '¿Se puede cancelar los viajes?',
    description: '<p class="px-2 py-2">Si la cancelación se realiza 3 hrs antes de iniciar el viaje te reembolsamos el dinero.</p>'
  }, {
    id: 3,
    title: '¿Cómo se realiza el pago del servicio?',
    description: '<p class="px-2 py-2">A través de la app cuentas con medios de pago electrónico para poder realizarlos de  forma segura.</p>'
  }, {
    id: 4,
    title: '¿Dónde veo mis reservas?',
    description: '<p class="px-2 py-2">En la sección <b>Mis viajes</b> puedes visualizar todas tus reservas.</p>'
  }]
}
