
import { Vue, Options } from 'vue-class-component';
import Check from '@/assets/images/home/check_list.png'

@Options({
  name: 'Partnerts'
})
export default class  Partnerts extends Vue{
  public itemsC = ['Viaje exclusivo desde tu condominio ida y vuelta.', 'Rápido sin detenciones en la ruta.', 'Tarifas preferenciales.'];
  public itemSuscriptions = ['Capacidad para 14 pasajeros.', 'Matrimonios, eventos, empresas, aeropuertos, etc.'];
  public checkIcon = Check
}
