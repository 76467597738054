
import { Vue, Options } from 'vue-class-component';
import { useMainStore } from '@/store/modules/main'

@Options({
  name: 'App',
})
export default class App extends Vue {
  store = useMainStore();
  mounted() {
    this.store.getMenu()
  }
}
