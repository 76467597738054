
import { Options, Vue } from 'vue-class-component';
import '@/scss/coupons.scss'
import ModalCreated from '@/components/Coupons/ModalCreated.vue';
import TableCoupons from '@/components/Coupons/TableCoupons.vue';

@Options({
  name: 'CouponsView',
  components: {
    ModalCreated,
    TableCoupons
  }
})
export default class CouponsView extends Vue {
  public isCreatedModalVisible = false;
}
