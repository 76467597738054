

interface DaysSelected {
    date: Date
}

import { PlanningTripsResponse } from '@/types/response/planning';
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { generateListDates } from '@/utils';
import { formatDateNoonJSDate, formatDateStartJSDate, getDate } from '@/filters/formatDate';
import { Header } from 'vue3-easy-data-table';
import { getErrorCatch, determinarPatronFechas } from '@/utils'
import { PlanningTripAPI } from '@/api/planning';
import { useMainStore } from '@/store/modules/main';

@Options({
    name: 'AddDaysTrip',
    props: {
        planning: {
            type: Object,
            default: () => ({}),
            required: false
        },
        visible: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: ['closeModal'],
    components: {
        ModalCommon
    }
})
export default class AddDaysTrip extends Vue {
    public planning !: PlanningTripsResponse;
    private mainStore = useMainStore();
    private apiPlaning = new PlanningTripAPI()
    public visible !: boolean;
    public loading = false;
    public days: Date[] = []
    public isRange = true;
    public daysMapped: DaysSelected[] = [];
    public getDate = getDate;
    public determinarPatronFechas = determinarPatronFechas;
    public disabledDays: Date[] = []

    mounted(): void {
        this.$watch('days', (value: Date[]) => {
            if (value && value.length > 0) {
                this.daysMapped = value.map((date) => ({ date }));
            }
        })
        this.$watch('planning', (value: PlanningTripsResponse) => {
            if (value) {
                this.disabledDays = value.days.map((day) => new Date(day));
            }
        })
    }

    public headers: Header[] = [
        {
            text: 'Fecha',
            value: 'date'
        }
    ]

    get namePlanning() {
        if (!this.planning) return ''
        return this.planning.name;
    }

    formatCL(date: Date) {
        const stringDate = date.toISOString().split("T")[0];
        return new Date(stringDate + "T12:00:00-03:00")
    }

    async addDays() {
        this.loading = true;
        try {
            const days = this.days.map((day) => formatDateNoonJSDate(this.formatCL(day)).toJSDate())
            await this.apiPlaning.addDays(this.planning._id, days, this.isRange)
            this.mainStore.setNotification({
                isOpen: true,
                color: 'green-darken-3',
                message: 'Días agregados'
            })
            this.$emit('reloadPlanning');
            this.closeModal()
        } catch (error) {
            getErrorCatch(error)
        }
        this.loading = false;
    }
    closeModal() {
        this.isRange = true;
        this.days = [];
        this.daysMapped = []
        this.$emit('closeModal')
    }

    changeRange() {
        if (!this.isRange) {
            if (this.days.length == 2) {
                const dateInit = this.days[0];
                const dateFinish = this.days[1];
                const dates = generateListDates(formatDateStartJSDate(dateInit).toJSDate(), formatDateStartJSDate(dateFinish).toJSDate())
                this.days = dates.filter((date) => !this.disabledDays.map((d) => d.getTime()).includes(date.getTime()));
            }
        } else {
            this.days = []
        }
    }
    sorted(dateA: Date, dateB: Date) {
        return dateA.valueOf() - dateB.valueOf();
    }
}
