
import { Options, Vue } from 'vue-class-component';
import RouteTable from '../components/route-station/RouteTable.vue';
import { useRoutesStore } from '@/store/modules/route';
import CreateStation from '@/components/route-station/CreateStation.vue';
import CreateRoute from '@/components/route-station/CreateRoute.vue';
import { getErrorCatch } from '@/utils';
import type { Header } from 'vue3-easy-data-table';

@Options({
  name: 'RutasView',
  components: {
    RouteTable,
    CreateStation,
    CreateRoute
  }
})
export default class RutasView extends Vue {
  public routeStore = useRoutesStore();
  public visibleCreateStation = false;
  public visibleCreateRoute = false;

  get loading() {
    return this.routeStore.loadingRoutes;
  }

  public headers: Header[] = [
    { text: '#', value: 'keyOrder' },
    { text: "Origen", value: "originStation.name" },
    { text: 'Destino', value: 'finalStation.name' },
    { text: 'Duración(min)', value: 'duration' }
  ];
  async mounted() {
    try {
      await this.routeStore.getStations();
    } catch (error) {
      getErrorCatch(error)
    }
  }
}
