
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useRoutesStore } from '@/store/modules/route'
import { formatDateCompleted } from '@/filters/formatDate';
import { getErrorCatch } from '@/utils';
import ModalCommon from '../common/ModalCommon.vue';
import { RouteStationAPI } from '@/api/route-station'

@Options({
    name: 'RouteTable',
    components: {
        ModalCommon
    }
})
export default class RouteTable extends Vue {
    private routesStore = useRoutesStore();
    private routeAPI = new RouteStationAPI();
    public formatDateCompleted = formatDateCompleted;
    public isVisibleModalDelete = false;
    public loadingDelete = false;
    public loadingTable = false;
    public routeDelete = '';
    public routeIDDelete = ''
    public serverOptions: ServerOptions = {
        page: 1,
        rowsPerPage: 5
    }

    public headers: Header[] = [
        { text: "Ruta", value: "nameRoute" },
        { text: 'Acciones', value: 'actions' }
    ];


    get total() {
        return this.routesStore.totalRoutes
    }

    get items() {
        return this.routesStore.routes
    }

    created() {
        this.$watch('serverOptions', ({ page, rowsPerPage }: ServerOptions) => {
            this.routesStore.setPage(page);
            this.routesStore.setLimit(rowsPerPage);
            this.getRoutes();
        })
    }

    mounted(): void {
        this.getRoutes()
    }

    async getRoutes() {
        this.loadingTable = true
        try {
            await this.routesStore.getRoutes();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }
    async setRoute(_id: string) {
        this.routesStore.setLoadingRoutes(true)
        try {
            await this.routesStore.getRoute(_id);
        } catch (error) {
            getErrorCatch(error)
        }
        this.routesStore.setLoadingRoutes(false)
    }
    removeRoute(_id: string, nameRoute: string) {
        this.routeDelete = nameRoute;
        this.routeIDDelete = _id;
        this.isVisibleModalDelete = true;
    }

    closeModalDelete() {
        this.isVisibleModalDelete = false;
        this.routeDelete = '';
        this.routeIDDelete = ''
    }
    async deleteRoute() {
        this.loadingDelete = true;
        try {
            await this.routeAPI.deleteRoute(this.routeIDDelete);
            await this.routesStore.getRoutes();
            this.closeModalDelete();
            this.routesStore.deleteRouteSelected();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingDelete = false;
    }
    setEditable(id: string) {
        this.routesStore.setEditableRoute(id);
    }
    async saveEditable(id: string, value: string) {
        this.routesStore.setLoadingRoute(id, true)
        try {
            await this.routeAPI.editNameRoute(id, value)
            await this.routesStore.getRoutes();
        } catch (error) {
            getErrorCatch(error)
        }
        this.routesStore.setLoadingRoute(id, false)
    }
    resetEditable(id: string) {
        this.routesStore.resetEditableRoute(id)
    }
}
