

import { RouteStationAPI } from '@/api/route-station';
import { useMainStore } from '@/store/modules/main';
import { StationCrud, useRoutesStore } from '@/store/modules/route';
import { getErrorCatch, requiredField } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { FormVuetify } from '@/types/common/vuetify';


@Options({
  name: 'CreateStation',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ModalCommon
  }
})
export default class CreateStation extends Vue {
  public nameStation = ''
  private routeAPI = new RouteStationAPI()
  public mainStore = useMainStore()
  public routeStore = useRoutesStore();
  public loadingCreateStation = false;
  public isVisible!: boolean;

  get stations() {
    return this.routeStore.stationsList
  }

  closeModalCreateStation() {
    this.nameStation = '';
    const form = this.$refs['formCreateStation'] as FormVuetify;
    if (!form) return;
    form.reset();
    this.$emit('closeModal');
  }
  async saveStation() {
    const form = this.$refs['formCreateStation'] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {
      this.loadingCreateStation = true;
      try {
        await this.routeAPI.createStation({ name: this.nameStation });
        await this.routeStore.getStations();
        this.mainStore.setNotification({ isOpen: true, message: 'Creado correctamente!' })
        this.closeModalCreateStation()
      } catch (error) {
        getErrorCatch(error)
      }
      this.loadingCreateStation = false;
    }
  }
  public requiredField = requiredField;
  toggleEditable(routeSelected: StationCrud) {
    this.routeStore.setEditableStation(routeSelected._id)
  }
  async saveEditable(routeSelected: StationCrud) {
    try {
      await this.routeAPI.updateRouteName(routeSelected.newValueEditable, routeSelected._id)
      await this.routeStore.getStations();
    } catch (error) {
      getErrorCatch(error)
    }
  }
  resetEditable(routeSelected: StationCrud) {
    this.routeStore.resetEditable(routeSelected._id)
  }
  async removeStation(routeSelected: StationCrud) {
    try {
      await this.routeAPI.removeStation(routeSelected._id)
      await this.routeStore.getStations();
    } catch (error) {
      getErrorCatch(error)
    }
  }
}
