import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!

  return (_openBlock(), _createBlock(_component_ModalCommon, {
    "max-width": "600",
    textButton: "Actualizar",
    onEventSuccess: _ctx.saveDriver,
    isVisible: _ctx.isVisible,
    title: "Editar cliente",
    loading: _ctx.loadingUpdate,
    onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    default: _withCtx(() => [
      (_ctx.customer)
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            ref: "formUpdateCustomer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { noGutters: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    class: "px-2 py-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.dataUpdate.firstName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataUpdate.firstName) = $event)),
                        label: "Nombres",
                        variant: "outlined",
                        rules: [_ctx.requiredField],
                        density: "comfortable"
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    class: "px-2 py-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.dataUpdate.lastName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataUpdate.lastName) = $event)),
                        label: "Apellidos",
                        variant: "outlined",
                        rules: [_ctx.requiredField],
                        density: "comfortable"
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    class: "px-2 py-1 mt-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.dataUpdate.rut,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataUpdate.rut) = $event)),
                        onKeypress: _ctx.validateNumber,
                        onBlur: _ctx.format,
                        onFocus: _ctx.formatReverse,
                        label: "RUT",
                        variant: "outlined",
                        rules: [_ctx.requiredField, _ctx.ruleValidateRut],
                        density: "comfortable"
                      }, null, 8, ["modelValue", "onKeypress", "onBlur", "onFocus", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    class: "px-2 py-1 mt-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.dataUpdate.phone,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataUpdate.phone) = $event)),
                        label: "Celular",
                        variant: "outlined",
                        density: "comfortable"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    class: "px-2 py-1 mt-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.dataUpdate.email,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataUpdate.email) = $event)),
                        label: "Nombres",
                        variant: "outlined",
                        rules: [_ctx.requiredField, _ctx.validateEmail],
                        density: "comfortable"
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onEventSuccess", "isVisible", "loading"]))
}