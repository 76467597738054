import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "coupon-list" }
const _hoisted_2 = { class: "middle" }
const _hoisted_3 = { class: "text-helvetica h-bold text-transform-uppercase" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "mt-4 bg-white",
    variant: "outlined"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_checkbox, {
            color: "primary",
            modelValue: _ctx.allCoupons,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allCoupons) = $event)),
            density: "compact",
            label: "Ver todos (Gastados)"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.finalCoupons, (coupon, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["item-coupon", coupon.status === 'SPENT' && ' spent']),
                key: `coupon-${key}`
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("h2", _hoisted_3, [
                    _createTextVNode(_toDisplayString(coupon.name) + " ", 1),
                    (coupon.status === 'ACTIVE')
                      ? (_openBlock(), _createBlock(_component_v_icon, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.copy(coupon.name)),
                          size: "x-small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-content-copy")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  (coupon.status === 'ACTIVE')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                        (coupon.amount)
                          ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_ctx.formatCurrency(coupon.amount)), 1))
                          : (_openBlock(), _createElementBlock("b", _hoisted_6, _toDisplayString(coupon.percentajeAmount) + "%", 1)),
                        _createTextVNode(" de descuento en tu próxima compra")
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_7, "Cupón gastado"))
                ])
              ], 2))
            }), 128))
          ]),
          (_ctx.coupons.length == 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, " Sin cupones "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}