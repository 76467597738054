
import { convertTo12HourFormat } from '@/filters/formatDate';
import { GetTripResponse } from '@/types/response/trip';
import { Options, Vue } from 'vue-class-component';

type TypeCancel = 'ALL' | 'FIRST' | 'SECOND' | 'EMPTY'

@Options({
    name: 'TripHourRound',
    props: {
        trip: {
            type: Object,
            default: () => ({}),
            required: false
        },
        typeSelected: {
            type: String,
            default: 'OUTBOUND',
            require: true
        }
    }
})
export default class TripHourRound extends Vue {
    public convertTo12HourFormat = convertTo12HourFormat
    public trip !: GetTripResponse;
    public typeSelected!: string;

    get getCancelRoute(): TypeCancel {
        if (this.trip.status === 'CANCEL') {
            if (this.trip.typeTrip == 'round') {
                if (!this.trip.typeCancelTrip) return 'EMPTY'
                if (this.trip.typeCancelTrip.includes('BOTH') || (this.trip.typeCancelTrip.includes('ONE_WAY') && this.trip.typeCancelTrip.includes('ONLY_RETURN'))) {
                    return 'ALL'
                }
                if (this.trip.typeCancelTrip.includes('ONE_WAY')) {
                    return 'FIRST'
                }
                if (this.trip.typeCancelTrip.includes('ONLY_RETURN')) {
                    return 'SECOND'
                }
                return 'EMPTY'
            } else {
                return 'ALL'
            }
        } else {
            return 'EMPTY'
        }
    }
}
