
import { Vue, Options } from 'vue-class-component';
import activeSeating from '@/assets/images/seatings/active-web.svg'
import blockedSeating from '@/assets/images/seatings/blocked-web.svg'
import inactiveSeating from '@/assets/images/seatings/inactive-web.svg'
import activeMobileSeating from '@/assets/images/seatings/active.svg'
import blockedMobileSeating from '@/assets/images/seatings/blocked.svg'
import inactiveMobileSeating from '@/assets/images/seatings/inactive.svg'
@Options({
    name: 'Seating',
    props: {
        isInactive: {
            type: Boolean,
            default: false,
            required: true
        },
        isBlocked: {
            type: Boolean,
            default: false,
            required: true
        },
        value: {
            type: String,
            default: 1,
            required: false
        }
    }
})
export default class Seating extends Vue {
    public isInactive !: boolean;
    public isBlocked !: boolean;
    public value!: string;
    public activeSeating = activeSeating;
    public blockedSeating = blockedSeating;
    public inactiveSeating = inactiveSeating;
    public activeMobileSeating = activeMobileSeating;
    public blockedMobileSeating = blockedMobileSeating;
    public inactiveMobileSeating = inactiveMobileSeating;
}
