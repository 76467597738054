import { defineStore } from "pinia";
import { IPagination } from "@/types/common/api";
import { VehicleAPI } from "@/api/vehicles";
import { VehicleResponse } from "@/types/response/vehicle";

interface VehicleStateStore {
  vehicles: VehicleResponse[];
  paginationVehicle: IPagination;
  totalVehicle: number;
}

const vehicleAPI = new VehicleAPI();

export const useVehicleStore = defineStore("vehicle", {
  // other options...
  state: (): VehicleStateStore => ({
    vehicles: [],
    paginationVehicle: {
      limit: 5,
      page: 1,
    },
    totalVehicle: 1
  }),
  actions: {
    async getVehicles() {
      const { items, total } = await vehicleAPI.getVehicles({
        limit: this.paginationVehicle.limit,
        page: this.paginationVehicle.page,
      });
      this.vehicles = items;
      this.totalVehicle = total;
    },
    setPage(page: number) {
      this.paginationVehicle.page = page;
    },
    setLimit(limit: number) {
      this.paginationVehicle.limit = limit;
    }
  },
});
