export enum PLANNING_TRIP_ENDPOINTS {
  CREATE_PLANNING_TRIP = 'create-planning-trip',
  ADD_DAYS = 'add-days/',
  GET_ALL_PLANNING_TRIPS = 'get-plannings-trip',
  CREATE_PLANNING_TRIP_STATION = 'create-planning-trip-station',
  GET_TRIP_STATIONS_PLANNING = 'get-planning-trip-stations/',
  CREATE_ACTIVE_TRIP = 'create-active-trip',
  CREATE_ACTIVE_TRIP_SEATINGS = 'create-active-trip-seating',
  GET_BOOKING = 'get-booking' ,
  GET_CALENDAR_TRIP = 'get-calendar/',
  UPDATE_STATUS_SEATING = 'update-status-seating/',
  GET_TRIPS_TODAY_DRIVER = 'get-trips-today',
  SUSPEND_SEATING = 'suspend-seating/',
  UPDATE_DRIVER_PLANNING = 'update-driver-planning/',
  UPDATE_VEHICLE_PLANNING = 'update-vehicle-planning/',
  UPDATE_STATUS_TRIP = 'update-status-trip/',
  UPDATE_DURATION_STATION_PLANNING = 'update-duration-station-planning/',
  REMOVE_ACTIVE_TRIP = 'remove-active-trip/',
  ADD_ACTIVE_TRIP = 'add-active-trip/',
  GET_PDF = 'get-pdf/',
  GET_ALL_PLANNING_TRIPS_LIST = 'get-select',
  UPDATE_NAME_PLANNING = 'update-name/',
  UPDATE_HOUR_STATION_PLANNING = 'update-hour-station-planning/',
  GET_STATIONS_PLANNING = 'get-stations-planning/',
  UPDATE_PRICE_STATION_PLANNING = 'update-price-station-planning/',
  DELETE_PLANNING = 'delete-planning/',
  GENERATED_REPORT = 'generate-report-xlsx/',
  ADD_USER_NOTIFICATION = "/subscribe-activeTrip/",
  REMOVE_USER_NOTIFICATION = "/subscribe-activeTrip/",
}