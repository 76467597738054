
import MainDriver from '@/components/Dashboard/MainDriver.vue';
import MainCustomer from '@/components/Dashboard/MainCustomer.vue';
import { useAuthStore } from '@/store/modules/auth';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'DashboardPage',
  components: {
    MainDriver,
    MainCustomer
  }
})
export default class DashboardPage extends Vue {
  public authStore = useAuthStore();
  get user() {
    return this.authStore.user;
  }


}

