import { AxiosInstance } from "axios";
import API from "@/utils/request";
import { ROUTES_GLOBAL } from "@/api/paths";
import { ROUTES_ENDPOINTS } from "./endpoints";
import {
  CreateRouteRequest,
  CreateRouteStationsRequest,
  CreateStationRequest,
  GetRouteRequest,
} from "@/types/request/route";
import {
  GetRouteByIDResponse,
  GetRoutesResponse,
  GetStationsResponse,
  PaginationGetRoutesResponse,
} from "@/types/response/route";

export class RouteStationAPI {
  private request = new API(ROUTES_GLOBAL.ROUTES_STATION);
  private api: AxiosInstance = this.request.getInstance();

  async getRoutes({ limit, page }: GetRouteRequest) {
    const URL = `${ROUTES_ENDPOINTS.GET_ROUTES}?page=${page}&limit=${limit}`;
    const { data } = await this.api.get<PaginationGetRoutesResponse>(URL);
    return data;
  }

  async createRoute(createRouteRequest: CreateRouteRequest) {
    const URL = `${ROUTES_ENDPOINTS.CREATE_ROUTE}`;
    const { data } = await this.api.post<string>(URL, createRouteRequest);
    return data;
  }

  async editNameRoute(id: string, name : string){
    await this.api.patch(`${ROUTES_ENDPOINTS.EDIT_ROUTE}${id}`,{nameRoute : name});
  }
  
  async getStations() {
    const URL = `${ROUTES_ENDPOINTS.GET_STATIONS}`;
    const { data } = await this.api.get<GetStationsResponse[]>(URL);
    return data;
  }
  async createStation(createStationRequest: CreateStationRequest) {
    const URL = `${ROUTES_ENDPOINTS.CREATE_STATION}`;
    await this.api.post<PaginationGetRoutesResponse>(URL, createStationRequest);
  }
  async createRouteStation(
    createRouteStationsRequest: CreateRouteStationsRequest
  ) {
    await this.api.post(
      ROUTES_ENDPOINTS.CREATE_ROUTE_STATIONS,
      createRouteStationsRequest
    );
  }
  async getRoute(id: string) {
    const { data } = await this.api.get<GetRouteByIDResponse>(
      ROUTES_ENDPOINTS.GET_ROUTE_ID + id
    );
    return data;
  }
  async getRoutesByName(name: string) {
    const { data } = await this.api.get<GetRoutesResponse[]>(
      ROUTES_ENDPOINTS.GET_ROUTE_NAME + name
    );
    return data;
  }
  async updateRouteName(name: string, id: string) {
    await this.api.put(ROUTES_ENDPOINTS.EDIT_STATION + id, { name });
  }
  async removeStation(id: string) {
    await this.api.delete(ROUTES_ENDPOINTS.REMOVE_STATION + id);
  }

  async deleteRoute(id: string) {
    await this.api.delete(ROUTES_ENDPOINTS.DELETE_ROUTE + id);
  }
}
