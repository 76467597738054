import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!

  return (_openBlock(), _createBlock(_component_ModalCommon, {
    "max-width": "700",
    isVisible: _ctx.visible,
    textButton: "Descargar",
    loading: _ctx.loading,
    title: "Reporte de planificación",
    class: "add-days-modal",
    onEventSuccess: _ctx.sendReport,
    onCloseModal: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      (_ctx.planning)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_VueDatePicker, {
              clearable: false,
              range: true,
              monthChangeOnScroll: false,
              modelValue: _ctx.days,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.days) = $event)),
              hideNavigation: ['time'],
              "allowed-dates": _ctx.allowedDates,
              "start-date": _ctx.startDate,
              "focus-start-date": "",
              format: "yyyy-MM-dd",
              locale: "es-CL",
              placeholder: "Seleccionar fechas",
              selectText: "Seleccionar"
            }, null, 8, ["modelValue", "allowed-dates", "start-date"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["isVisible", "loading", "onEventSuccess", "onCloseModal"]))
}