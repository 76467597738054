
import { formatDateNoonTimestamp, getDateStarDay } from "@/filters/formatDate";
import { useRoutesStore } from "@/store/modules/route";
import { usePlanningStore } from "@/store/modules/planning";
import { FormVuetify, SelectData } from "@/types/common/vuetify";
import { GetBookingRequest } from "@/types/request/planning";
import { getErrorCatch, requiredField, sleep } from "@/utils";
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import BannerMobile from "@/assets/images/banners/banner_mov.png";
import { useDisplay } from "vuetify";
import { customScrollTo } from "@/utils";
import { useAuthStore } from "@/store/modules/auth";
import { useMainStore } from "@/store/modules/main";

@Options({
  name: "FormSearchBooking",
  components: {
    HeaderCommonBooking,
  },
  props: {
    home: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class FormSearchBooking extends Vue {
  public dateSelected = new Date();
  public routeStore = useRoutesStore();
  public planningStore = usePlanningStore();
  private mainStore = useMainStore();
  private authStore = useAuthStore();
  public requiredField = requiredField;
  public searchLoading = false;
  public getDateStarDay = getDateStarDay;
  public mobile = false;
  public home!: boolean;
  public listTypeTrip: SelectData[] = [
    {
      title: "Ida y vuelta",
      value: "round",
    },
    {
      title: "Solo ida",
      value: "justReturn",
    },
  ];

  public banner = BannerMobile;
  public timeOut = 0;

  public dateCreateTrip: GetBookingRequest = {
    origin: undefined,
    destination: undefined,
    date: new Date(),
    typeTrip: "round",
    dateFinal: new Date(),
  };

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < 572;
  }

  get isLogged() {
    return this.authStore.isLogged;
  }

  get isSigned() {
    return this.authStore.user?.isSign;
  }

  get stations() {
    return this.routeStore.stationsList;
  }
  get tripsSearch() {
    return this.planningStore.tripsSelected;
  }

  get searchSelected() {
    return this.planningStore.searchSelected;
  }

  get checkMainPage() {
    if (!this.isMobile) {
      return true;
    }
    const router = this.$route.path;
    if (router === "/booking") {
      return this.tripsSearch.length > 0;
    }
    return false;
  }

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
    const route = this.$route.path;
    try {
      if (route === "/booking") {
        const dataLocal = localStorage.getItem("searchSelected");
        if (dataLocal) {
          const searchSelected = JSON.parse(dataLocal) as GetBookingRequest;
          const dateSearch = new Date(searchSelected.date as number);
          this.dateCreateTrip.date = dateSearch;
          this.dateCreateTrip.destination = searchSelected.destination;
          this.dateCreateTrip.origin = searchSelected.origin;
          this.dateCreateTrip.typeTrip = searchSelected.typeTrip;
          this.dateCreateTrip.dateFinal = searchSelected.dateFinal;
        }
      }
    } catch (error) {
      getErrorCatch(error);
    }
  }

  setNoonDate(dateEntry: number | Date) {
    const dateFormat = new Date(dateEntry);
    dateFormat.setHours(12);
    dateFormat.setMinutes(0);
    dateFormat.setSeconds(0);
    return dateFormat;
  }

  async search() {
    const form = this.$refs["formSearch"] as FormVuetify;
    if (!form) return;
    const valid = await form.validate();
    if (valid.valid) {
      if (!this.authStore.isLogged) {
        this.mainStore.setNotification({
          isOpen: true,
          message:
            "Para continuar con la compra, debes iniciar sesión o crear tu cuenta.",
          isLink: true,
          link: "/login",
          nameLink: "Aquí",
          color: "red-darken-2",
          timeout: 3000,
          buttonClose: false,
        });
        return;
      } else {
        if (!this.isSigned) {
          this.mainStore.setNotification({
            isOpen: true,
            message:
              "Para continuar con la compra, debes firmar tu contrato.",
            isLink: true,
            link: "/perfil",
            nameLink: "Aquí",
            color: "red-darken-2",
            timeout: 3000,
            buttonClose: false,
          });
          return;
        }
      }
      const dateInitFormat = this.setNoonDate(this.dateCreateTrip.date);
      const dateFinalFormat = this.dateCreateTrip.dateFinal
        ? this.setNoonDate(this.dateCreateTrip.dateFinal)
        : undefined;
      this.dateCreateTrip.date = formatDateNoonTimestamp(
        dateInitFormat.getTime()
      ).toMillis();
      this.dateCreateTrip.dateFinal = dateFinalFormat
        ? formatDateNoonTimestamp(dateFinalFormat.getTime()).toMillis()
        : undefined;
      this.searchLoading = true;
      this.planningStore.setLoadingBooking(true);
      try {
        await this.planningStore.getTripsBooking(this.dateCreateTrip);
        this.planningStore.resetSearchTrip();
        this.planningStore.updateStageTrip("outbound");
      } catch (error) {
        getErrorCatch(error);
      }
      this.planningStore.setLoadingBooking(false);
      this.searchLoading = false;
      if (this.mobile) {
        await sleep(300, this.timeOut);
        customScrollTo("listTrips", 0);
      }
      if (this.home) {
        this.$router.push("/booking");
      }
    }
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  different(value: string, valueCompare?: string) {
    if (!valueCompare) return true;
    return value !== valueCompare ? true : "Campo igual a origen";
  }
  back() {
    if (this.mobile) {
      this.planningStore.clearBooking();
      this.dateCreateTrip = {
        date: new Date(),
        destination: undefined,
        origin: undefined,
        dateFinal: new Date(),
        typeTrip: "round",
      };
      return;
    }
    this.$router.push("/");
  }
  setTypeTrip(value: string) {
    if (value === "round") {
      this.dateCreateTrip.dateFinal = new Date(this.dateCreateTrip.date);
    } else {
      this.dateCreateTrip.dateFinal = undefined;
    }
  }
  updateDateFinal(value: Date) {
    if (this.dateCreateTrip.typeTrip === "round") {
      this.dateCreateTrip.dateFinal = new Date(value);
    }
  }
}
