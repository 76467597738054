
import { Vue, Options } from "vue-class-component";
import { useMainStore } from "../store/modules/main";
import ProfileMenu from "./ProfileToolBar.vue";
import { useAuthStore } from "@/store/modules/auth";
import IconLarge from "@/assets/images/logo.svg";
import { customScrollTo, getErrorCatch } from "@/utils";
import { ParameterApi } from "@/api/parameter";
import ModalCommon from "./common/ModalCommon.vue";

@Options({
  name: "ToolBar",
  components: {
    ProfileMenu,
    ModalCommon
  },
})
export default class ToolBar extends Vue {
  icon = IconLarge;
  public authStore = useAuthStore();
  private parameterApi = new ParameterApi();
  public activeDrawer = false;
  public loadingStatus = false;
  public valueStatus = false
  public modalVisibleServer = false;
  get user() {
    return this.authStore.user;
  }
  public mainStore = useMainStore();

  get isServerActive() {
    return this.mainStore.statusServer;
  }

  signOut() {
    this.authStore.logout();
    this.$router.push("/booking");
    location.reload();
  }

  toggleDrawer() {
    this.mainStore.toggleDrawer();
  }
  toScroll(e: Event, data: string, isDrawer = false) {
    e.preventDefault();
    const path = this.$route.path;
    if (isDrawer) {
      this.activeDrawer = false;
    }
    if (!data.includes("#")) {
      this.$router.push(data);
      return;
    }
    if (path === "/") {
      const topOffset = 80;
      const sectionId = data.split("#")[1];
      customScrollTo(sectionId, topOffset);
    } else {
      this.$router.push(data);
    }
  }

  isActive(value: string) {
    return value === this.$route.path;
  }

  openModalServerStatus(value: boolean){
    this.valueStatus = value;
    this.modalVisibleServer = true;
  }

  closeModalVisibleServer(){
    this.modalVisibleServer = false;

  }

  async updateStatus() {
    this.loadingStatus = true;
    try {
      await this.parameterApi.updateKey("status", this.valueStatus ? "1" : "0");
      const data = await this.parameterApi.getStatuServer();
      this.mainStore.setStatus(data);
      this.closeModalVisibleServer()
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingStatus = false;
  }
}
