
import { Options, Vue } from 'vue-class-component';
import ModalCommon from './ModalCommon.vue';
import { requiredField, validateEmail, getErrorCatch } from '@/utils'
import TextLoaderButton from './LoaderButtons.vue';
import { FormVuetify } from '@/types/common/vuetify';
import { useAuthStore } from '@/store/modules/auth';

@Options({
    name: 'ModalLogin',
    components: {
        ModalCommon,
        TextLoaderButton
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
            required: true
        }
    }
})
export default class ModalLogin extends Vue {
    public isVisible !: boolean;
    public isLoading = false;
    public requiredField = requiredField;
    public validateEmail = validateEmail;
    public email = '';
    public password = '';
    public showPassword = false;

    public storeAuth = useAuthStore()

    async login() {
        const form = this.$refs["loginForm"] as FormVuetify;
        const isValid = await form.validate();
        if (!isValid.valid) return;
        this.isLoading = true;
        try {
            await this.storeAuth.login({ password: this.password, username: this.email });
            await this.storeAuth.getUser();
            form.reset();
            form.resetValidation()
            this.$emit('closeModal');
        } catch (error) {
            getErrorCatch(error)
        }
        this.isLoading = false;
    }
}
