
import { Options, Vue } from "vue-class-component";
import ModalCommon from "@/components/common/ModalCommon.vue";
import {
  CreateCouponRequest,
  TTypeCoupon,
  TypeCoupon,
} from "@/types/request/coupon";
import { FormVuetify, SelectData } from "@/types/common/vuetify";
import { GetUsersAutocomplete } from "@/types/response/guest";
import { min, max, requiredField, getErrorCatch, getStartNoonTimeDate } from "@/utils";
import { UserApi } from "@/api/users";
import { CouponApi } from "@/api/coupons";
import { useMainStore } from "@/store/modules/main";
import { useCouponStore } from "@/store/modules/coupon";
@Options({
  name: "ModalCreated",
  components: {
    ModalCommon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
})
export default class ModalCreated extends Vue {
  private readonly userApi = new UserApi();
  private readonly couponApi = new CouponApi();
  // rules
  public min = min;
  public max = max;
  public requiredField = requiredField;
  public mainStore = useMainStore();
  public couponStore = useCouponStore();
  public visible!: boolean;
  public loadingCreated = false;
  public subTypeSelected = TypeCoupon.PERCENTAJE;
  public isAmountMin = false;
  public loadingCustomer = false;
  public search = "";
  public dayExpired = "";
  public customersSearch: GetUsersAutocomplete[] = [];

  public dataCreated: CreateCouponRequest = {
    name: "",
    type: TypeCoupon.CUSTOMER,
    isInfinite: false,
    isWallet: false
  };

  public items: SelectData[] = [
    {
      title: "Por cliente",
      value: TypeCoupon.CUSTOMER,
    },
    {
      title: "Porcentaje",
      value: TypeCoupon.PERCENTAJE,
    },
    {
      title: "Monetario",
      value: TypeCoupon.MONEY,
    },
  ];
  public itemsCustomer: SelectData[] = [
    {
      title: "Porcentaje",
      value: TypeCoupon.PERCENTAJE,
    },
    {
      title: "Monetario",
      value: TypeCoupon.MONEY,
    },
  ];

  created(): void {
    this.$watch("search", (val: string) => {
      val && val !== this.dataCreated.customer && this.getCustomers(val);
    });
  }

  async getCustomers(value: string) {
    this.loadingCustomer = true;
    try {
      const data = await this.userApi.getCustomerByName(value);
      this.customersSearch = data;
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingCustomer = false;
  }

  closeModal() {
    this.$emit("closeModal");
    const form = this.$refs["formCreateCoupon"] as FormVuetify;
    form.resetValidation();
    form.reset();
  }
  async saveCoupon() {
    this.loadingCreated = true;
    const form = this.$refs["formCreateCoupon"] as FormVuetify;
    const valid = await form.validate();
    if (valid.valid) {
      try {
        const dataCreated = this.dataCreated;
        if (
          !(
            dataCreated.isInfinite &&
            dataCreated.type === "CUSTOMER" &&
            dataCreated.subType === "MONEY"
          )
        ) {
          dataCreated.isWallet = false;
        }
        if (dataCreated.limit) {
          dataCreated.limit = parseInt(dataCreated.limit as string);
        }
        if (dataCreated.amount) {
          dataCreated.amount = parseInt(dataCreated.amount as string);
        }
        if (dataCreated.minAmount) {
          dataCreated.minAmount = parseInt(dataCreated.minAmount as string);
        }
        if (dataCreated.percentaje) {
          dataCreated.percentaje = parseInt(dataCreated.percentaje as string);
        }
        if (this.dataCreated.type === TypeCoupon.CUSTOMER) {
          dataCreated.subType = this.subTypeSelected;
        }
        if(this.dayExpired !== null || this.dayExpired !== ''){
          dataCreated.dateExpired = getStartNoonTimeDate(new Date(this.dayExpired)).getTime();
        }
        await this.couponApi.createCoupon(this.dataCreated);
        this.mainStore.setNotification({
          isOpen: true,
          message: "Cupón creado",
          color: "green darken-2",
        });
        await this.couponStore.getCoupons();
        this.closeModal();
      } catch (error) {
        getErrorCatch(error);
      }
    }
    this.loadingCreated = false;
  }

  setType(type: TTypeCoupon) {
    this.dataCreated.type = TypeCoupon[type];
    this.dataCreated.amount = undefined;
    this.dataCreated.percentaje = undefined;
  }
  setSubType(type: TTypeCoupon) {
    if (type === this.subTypeSelected) return;
    this.subTypeSelected = TypeCoupon[type];
    this.dataCreated.subType = TypeCoupon[type];
    this.dataCreated.amount = undefined;
    this.dataCreated.percentaje = undefined;
  }
  toggleInfinite() {
    this.dataCreated.isInfinite = !this.dataCreated.isInfinite;
    this.dataCreated.limit = undefined;
    const form = this.$refs["formCreateCoupon"] as FormVuetify;
    form.resetValidation();
  }
  toggleAmountmin() {
    this.isAmountMin = !this.isAmountMin;
    this.dataCreated.amount = undefined;
    const form = this.$refs["formCreateCoupon"] as FormVuetify;
    form.resetValidation();
  }
}
