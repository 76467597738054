
import { Vue, Options } from 'vue-class-component';
import { useAuthStore } from '../store/modules/auth';

@Options({
  name: 'ErrorPage'
})
export default class ErrorPage extends Vue {
  private authStore = useAuthStore();
  public path = '/';
  mounted(): void {
    if (this.authStore.isLogged) {
      this.path = '/dashboard'
    }
  }
}

