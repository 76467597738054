

import { getDate } from '@/filters/formatDate';
import { useCalendarStore } from '@/store/modules/calendar';
import { useMainStore } from '@/store/modules/main';
import { DateTime } from 'luxon';
import { Options, Vue } from 'vue-class-component';
import ItemListDriver from './ItemListDriver.vue';
import { PlanningTripsResponse } from '@/types/response/planning';
import { getErrorCatch } from '@/utils';

interface DataExpirado {
    color: string;
    text: string;
    expirado?: boolean
}

@Options({
    name: 'ListPlannings',
    components: {
        ItemListDriver
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
            required: true
        }
    }
})
export default class ListPlannings extends Vue {
    public calendarStore = useCalendarStore();
    public mainStore = useMainStore();
    public isLoading !: boolean;
    public isCulminados = false;
    public loadingInternal = false;

    get planning() {
        return this.calendarStore.planningSelected;
    }

    get getPlannings() {
        const plannings = this.calendarStore.plannings.map((planing) => {
            return {
                ...planing,
                expirado: this.getExpirado(planing.days as string[])
            }
        }).sort((a, b) => {
            const dateA = new Date(a.days[0]);
            const dateB = new Date(b.days[0]);
            return dateB.getTime() - dateA.getTime()
        }).filter(({ expirado }) => {
            if (!this.isCulminados) {
                return !expirado.expirado;
            }
            return true;
        })
        return plannings;
    }
    getRangeDates(dates: string[]) {
        return `${getDate(dates[0], 'onlyDate')} - ${getDate(dates[1], 'onlyDate')}`
    }
    getExpirado(dates: string[]): DataExpirado {
        if (dates.length != 2) {
            return {
                color: '',
                text: ''
            }
        }
        const dateOne = DateTime.fromJSDate(new Date(dates[0])).setZone(this.mainStore.timeZone).startOf("day").toMillis();
        const dateTwo = DateTime.fromJSDate(new Date(dates[1])).setZone(this.mainStore.timeZone).endOf("day").toMillis();
        const dateNow = new Date().getTime();
        if (dateNow > dateTwo) {
            return {
                color: 'red',
                text: 'Culminado',
                expirado: true
            }
        }
        if (dateOne < dateNow && dateNow < dateTwo) {
            return {
                color: 'green',
                text: 'En progreso'
            }
        }
        if (dateNow < dateOne) {
            return {
                color: 'orange-darken-2',
                text: 'Aún no iniciado'
            }
        }
        return {
            color: '',
            text: ''
        }
    }

    async setPlanning(planning: PlanningTripsResponse) {
        if (this.planning?._id == planning._id) return;
        this.$emit("resetCalendar")
        this.calendarStore.setLoading(true)
        this.loadingInternal = true;
        try {
            await Promise.all([this.calendarStore.getStations(planning._id), this.calendarStore.getCalendar(planning._id)])
            this.calendarStore.setPlanning(planning)
            this.$emit('loadCalendar')
        } catch (error) {
            getErrorCatch(error)
        }
        this.calendarStore.setLoading(false)
        this.loadingInternal = false;

    }
}
