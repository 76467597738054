export enum USER_ENDPOINTS {
  CREATE_GUEST = "create-customer",
  UPDATE_CUSTOMER = "update-customer",
  CREATE_CUSTOMER = "create-customerv2",
  GET_GUEST = "get-customers",
  GET_DRIVERS = "get-drivers",
  CREATE_DRIVER = "create-driver",
  EDIT_DRIVER = "edit-driver/",
  GET_DRIVER_BYNAME = "get-drivers/",
  GET_CUSTOMERS_BYNAME = "get-customers/",
  GET_ALL_DRIVERS = "get-all-driver",
  GET_CUSTOMER_RUT = "get-customer/",
  ADD_CARD_FLOW = "add-card",
  DELETE_CARD_FLOW = "delete-card/",
  REFRESH_SIGN = "refresh-sign/",
  RESEND_SIGN = "resend-sign/",
  SIGN_USER = "sign-user/",
  GET_CONTRACT = "sign",
  SEND_SIGN = "sign",
  GET_MY_CONTRACT = "sign/get-my-contract",
  GET_USER_SIGN = "sign/get-contract-user/",
  SET_CONTRACT_UPLOAD = "sign/add-contract-user/",
  CREATE_PASSWORD = "create-password/",
  GET_CONTRACT_BYRUT = "sign/get-contract-rut/",
  GET_CONTRACT_BYTRIP = "sign/get-contract-trip/",
  BLOCK_USER = "blocked-customer/",
  DESBLOCK_USER = "desblocked-customer/",
  // favorite passenger
  ADD_FAVORITE_PASSENGER = '/add-favorite-passenger',
  REMOVE_FAVORITE_PASSENGER = '/remove-favorite-passenger/'
}
