
import { Options, Vue } from 'vue-class-component';
import { useMainStore, DeviceType } from '../store/modules/main';
import IconLarge from '@/assets/images/logo.svg';
import { useAuthStore } from '../store/modules/auth';
import MainMenu from './Menus/MainMenu.vue';
const WIDTH = 992

@Options({
  name: "NavigationDrawer",
  components: {
    MainMenu
  }
})
export default class NavigationDrawer extends Vue {
  private mainStore = useMainStore()
  private authStore = useAuthStore()
  public logo = IconLarge

  get drawer() {
    return this.mainStore.drawerOpen
  }

  set drawer(value: boolean) {
    this.mainStore.setDrawer(value)
  }

  private isMobile() {
    const rect = document.body.getBoundingClientRect()
    return rect.width - 1 < WIDTH
  }

  mounted(): void {
    const isMobile = this.isMobile()
    if (isMobile) {
      this.mainStore.setDevice(DeviceType.Mobile)
      this.mainStore.setDrawer(false)
    }
  }

  signOut() {
    this.authStore.logout();
    location.reload();
  }
}
