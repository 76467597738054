

import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import { usePlanningStore } from '@/store/modules/planning';
import { useRoutesStore } from '@/store/modules/route';
import { Vue, Options } from 'vue-class-component';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import { CustomerSeating } from '@/types/request/planning';
import activeSeating from '@/assets/images/seatings/active-web.svg'
import blockedSeating from '@/assets/images/seatings/blocked-web.svg'
import inactiveSeating from '@/assets/images/seatings/inactive-web.svg'
import seatingCount from '@/assets/images/seatings/seatingCount.svg'
import timonIcon from '@/assets/images/icons/timon.png'
import Seating from './Seating.vue'
import locationPrimary from '@/assets/images/icons/location-primary.svg'
import locationSecondary from '@/assets/images/icons/location-secondary.svg'
import Line from '@/assets/images/icons/linea.svg'
import { useDisplay } from 'vuetify'
import { customScrollTo, sleep } from '@/utils'
import { useMainStore } from '@/store/modules/main';

@Options({
    name: 'Bus',
    components: {
        HeaderCommonBooking,
        Seating
    }
})
export default class Bus extends Vue {
    public activeSeating = activeSeating;
    public blockedSeating = blockedSeating;
    public inactiveSeating = inactiveSeating;
    public locationPrimary = locationPrimary;
    public locationSecondary = locationSecondary;
    public seatingCount = seatingCount;
    public timonIcon = timonIcon;
    public routeStore = useRoutesStore();
    public planningStore = usePlanningStore();
    private mainStore = useMainStore()
    public convertTo12HourFormat = convertTo12HourFormat;
    public getDate = getDate;
    public Line = Line;
    public mobile = false;
    public timeOut = 0;

    back() {
        this.planningStore.resetSearchTrip()
        this.planningStore.updateStageTrip('outbound');

        this.planningStore.removeTripSelected()
    }

    mounted(): void {
        const { mobile } = useDisplay();
        this.mobile = mobile.value;
    }

    get stations() {
        return this.routeStore.stationsList
    }

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected;
    }

    get seatingsTrip() {
        return this.tripSelected?.seatings || []
    }

    get seatingsTripFinal() {
        return this.tripFinalSelected?.seatings || []
    }

    get typeDistribution(){
        if(!this.planningStore.tripSelected) return 'DEFAULT'
        if(this.stageSeating == 'return' && this.planningStore.tripFinalSelected){
            return this.planningStore.tripFinalSelected.vehicle.seatingsDistribution
        }
        return this.planningStore.tripSelected.vehicle.seatingsDistribution
    }

    get passengers() {
        let pass: string[] = []
        this.planningStore.owner && pass.push(this.planningStore.owner.seating);
        pass = [...pass, ...this.planningStore.passengers.map(({ seating }) => seating)].sort(this.sortSeatings)
        return pass
    }

    public seatingSelected: string[] = []

    get stageSeating() {
        return this.planningStore.stageSeatings
    }

    toggleSeating(value: string) {
        const exist = this.searchSeating(value);
        if (exist) return;
        const existValue = this.seatingSelected.find((key) => key == value);
        if (existValue) {
            this.seatingSelected = this.seatingSelected.filter(key => key !== value);
        } else {
            if (this.stageSeating === 'return') {
                if (this.seatingSelected.length === this.passengers.length) {
                    this.mainStore.setNotification({
                        isOpen: true,
                        message: 'Ya no puedes seleccionar mas asientos.',
                        color: 'red darken-3'
                    })
                    return;
                }
            }
            this.seatingSelected.push(value);
        }
        this.seatingSelected = this.seatingSelected.sort(this.sortSeatings);
    }

    searchSeating(value: string) {
        if (this.stageSeating == 'outbound') {
            return !!this.seatingsTrip.find(({ seating }) => value === seating)
        } else if (this.stageSeating === 'return') {
            return !!this.seatingsTripFinal.find(({ seating }) => value === seating)
        }
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || ''
    }
    async nextStep() {
        if (this.stageSeating == 'return') {
            if (this.seatingSelected.length !== this.passengers.length) {
                this.mainStore.setNotification({
                    isOpen: true,
                    message: 'Falta asientos por seleccionar, deben ser la misma cantidad que la ida.',
                    color: 'red-darken-2',
                    timeout: 3000
                })
                return;
            }
        }
        this.planningStore.setOwner({
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            rut: '',
            seating: this.seatingSelected[0]
        })
        const seatings: CustomerSeating[] = [];
        this.seatingSelected.forEach((value, index) => {
            if (index > 0) {
                seatings.push({
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    rut: '',
                    seating: value
                })
            }
        });
        this.planningStore.setPassengers(seatings);
        if (this.searchTripBooking?.typeTrip === 'round') {
            if (this.stageSeating === 'return') {
                this.planningStore.setStep('PASSENGERS');
                await sleep(300, this.timeOut);
                customScrollTo('null', 0)
            } else {
                this.seatingSelected = [];
                this.mainStore.setNotification({
                    isOpen: true,
                    message: 'Seleccione tus asientos del viaje de vuelta.',
                    color: 'green darken-2'
                })
                await sleep(300, this.timeOut);
                customScrollTo('null', 0)
            }
        } else {
            this.planningStore.setStep('PASSENGERS');
            await sleep(300, this.timeOut);
            customScrollTo('null', 0)

        }
        this.planningStore.updateStageSeating('return');
    }

    beforeUnmount(): void {
        clearInterval(this.timeOut);
    }

    sortSeatings(a: string, b: string) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }
    backStepSeating() {
        this.seatingSelected = [];
        this.planningStore.updateStageSeating('outbound');
        this.planningStore.setPassengers([]);
    }
}
