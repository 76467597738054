
import CreateVehicle from '@/components/vehicles/CreateVehicle.vue';
import TableVehicles from '@/components/vehicles/TableVehicles.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Vehicles',
  components: {
    TableVehicles,
    CreateVehicle
  }
})
export default class Vehicles extends Vue {
  public visibleVehicle = false;
}
