
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../../common/ModalCommon.vue";
import { convertTo12HourFormat } from "@/filters/formatDate";
import {
  SeatingGetTripTodayDriver,
  TripTodayDriverResponse,
  TypeStatusSeating,
} from "@/types/response/planning";
import { getErrorCatch, sleep, translate } from "@/utils";
import { PlanningTripAPI } from "@/api/planning";
import { UserApi } from "@/api/users";
import { ErrorResponse } from "@/utils/request";

@Options({
  components: {
    ModalCommon,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    titleViewSeatings: {
      type: String,
      default: "",
      required: true,
    },
    tripsSelected: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
})
export default class ModalSeatings extends Vue {
  public isVisible!: boolean;
  public titleViewSeatings!: string;
  public tripsSelected!: TripTodayDriverResponse;
  public seatings: SeatingGetTripTodayDriver[] = [];
  private planningApi = new PlanningTripAPI();
  private userApi = new UserApi();
  public translate = translate;
  public convertTo12HourFormat = convertTo12HourFormat;
  public seatingsAvailables: TypeStatusSeating[] = [
    "ABSEND",
    "ATTENDED",
    "ON_HOLD",
    "FINALIZED",
  ];

  mounted(): void {
    this.$watch("isVisible", (value: boolean) => {
      if (value) {
        this.loadPassenger();
      }
    });
  }

  async changeStatus(id: string, status: TypeStatusSeating) {
    if (this.tripsSelected == null) return;
    try {
      this.seatings = this.seatings.map((seating) => {
        if (seating._id == id) {
          seating.loading = true;
        }
        return {
          ...seating,
        };
      });
      await this.planningApi.updateSeatingStatus(id, status);
      this.seatings = this.seatings.map((seating) => {
        if (seating._id == id) {
          seating.status = status;
          seating.loading = false;
          (seating.colorStatus = this.getColorStatus(status)),
            (seating.iconStatus = this.getIconStatus(status)),
            (seating.translateStatus = this.translate(status));
        }
        return {
          ...seating,
        };
      });
      this.$emit("updateSeating", this.seatings);
    } catch (error) {
      getErrorCatch(error);
    }
  }

  loadPassenger() {
    this.seatings = this.tripsSelected.seatings.map((seating) => {
      return {
        ...seating,
        colorStatus: this.getColorStatus(seating.status),
        iconStatus: this.getIconStatus(seating.status),
        translateStatus: this.translate(seating.status),
        loadingContract: false,
      };
    });
    this.loadingRutsContracts();
  }

  setLoadingSeating(id: string, value: boolean) {
    const data = this.seatings.map((seating) => {
      if (seating._id == id) {
        return {
          ...seating,
          loadingContract: value,
        };
      }
      return seating;
    });
    this.seatings = data;
  }

  setBase64(id: string, value: string, isContractOther= false) {
    this.seatings = this.seatings.map((seating) => {
      if (seating._id == id) {
        return {
          ...seating,
          base64: value,
          isContractOther
        };
      }
      return seating;
    });
  }

  setContract(base64: string, id: string,isContractOther = false) {
    this.setLoadingSeating(id, false);
    this.setBase64(id, base64,isContractOther);
  }

  async loadingRutsContracts() {
    for (let index = 0; index < this.seatings.length; index++) {
      const element = this.seatings[index];
      this.setLoadingSeating(element._id, true);
      this.userApi
        .getContractUserRut(element.rut)
        .then((value) => this.setContract(value, element._id))
        .catch((err: ErrorResponse) => {
          if (err.statusCode == 404) {
            if (element.codeTrip) {
              this.setLoadingSeating(element._id, true);
              this.userApi
                .getContractUserTrip(element.codeTrip)
                .then((value) => this.setContract(value, element._id,true))
                .finally(() => {
                  this.setLoadingSeating(element._id, false);
                });
            } else {
              console.log("No existe codeTrip", element);
            }
          }
        })
        .finally(() => {
          this.setLoadingSeating(element._id, false);
        });
    }
  }

  getColorStatus(status: TypeStatusSeating) {
    const colors: Record<TypeStatusSeating, string> = {
      ABSEND: "#f57e00",
      ATTENDED: "#28A745",
      POSTPONED: "red",
      ON_HOLD: "#878787",
      FINALIZED: "blue",
    };
    return colors[status] || "grey";
  }

  getIconStatus(status: TypeStatusSeating) {
    const colors: Record<TypeStatusSeating, string> = {
      ABSEND: "mdi-account-off",
      ATTENDED: "mdi-account-settings",
      POSTPONED: "red",
      ON_HOLD: "mdi-account",
      FINALIZED: "mdi-account-check",
    };
    return colors[status] || "mdi-account";
  }
  viewContract(passenger: SeatingGetTripTodayDriver) {
    if (passenger.base64) {
      try {
        const byteCharacters = atob(passenger.base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, "_blank");
      } catch (error) {
        getErrorCatch(error);
      }
    }
  }
}
