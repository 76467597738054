import { defineStore } from "pinia";
import {
  GetCalendarResponse,
  PlanningTripsResponse,
  PlanningTripsStationsResponse,
} from "@/types/response/planning";
import { PlanningTripAPI } from "@/api/planning";

interface CalendarStateStore {
  plannings: PlanningTripsResponse[];
  planningSelected: PlanningTripsResponse | null;
  stations: PlanningTripsStationsResponse[];
  calendarData: GetCalendarResponse[];
  loadingPlanningSelected : boolean
}

const planningTripAPI = new PlanningTripAPI();

export const useCalendarStore = defineStore("calendar", {
  // other options...
  state: (): CalendarStateStore => ({
    plannings: [],
    planningSelected: null,
    stations: [],
    calendarData: [],
    loadingPlanningSelected : false
  }),
  actions: {
    async getPlanningTrips() {
      const data = await planningTripAPI.getPlanningDriver();
      this.plannings = data;
    },
    setPlanning(planning: PlanningTripsResponse) {
      this.planningSelected = planning;
    },
    removePlaning() {
      this.planningSelected = null;
      this.stations = [];
      this.calendarData = [];
    },
    async getCalendar(id: string) {
      const data = await planningTripAPI.getCalendar(id);
      this.calendarData = data;
    },
    async getStations(id: string) {
      const data = await planningTripAPI.getStationsPlanningTrip(id);
      this.stations = data;
    },
    setLoading(value: boolean){
      this.loadingPlanningSelected = value;
    }
  },
});
