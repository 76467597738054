
import { Vue, Options } from 'vue-class-component';
import { requiredField, validateEmail, getErrorCatch, formatRutChile, validateNumber, ruleValidateRut } from '@/utils'
import { useAuthStore } from '../store/modules/auth';
import { useMainStore } from '../store/modules/main';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import IconEmail from '@/assets/images/icons/email.png'
import IconGmail from '@/assets/images/icons/gmail.png'
import { FormVuetify } from '@/types/common/vuetify';
import { IRequestCreateGuest } from '@/types/request/guest';
import TextLoaderButton from '@/components/common/LoaderButtons.vue';
import { faker } from '@faker-js/faker';
import { generarRUTChileno } from '@/assets/data/ruts'
import { UserApi } from '@/api/users/index'

@Options({
    name: 'RegistroPage',
    components: {
        TextLoaderButton
    }
})
export default class RegistroPage extends Vue {
    public loading = false;
    public email = '';
    public password = '';
    public showPassword = false;
    public showPassword2 = false;
    public redirect = '';
    public isEmail = false;
    public isGmail = false;
    public IconEmail = IconEmail;
    public IconGmail = IconGmail;
    public checkbox = false;
    private userApi = new UserApi();
    public successForm = false;

    validateEmail = validateEmail;
    requiredField = requiredField;

    public storeAuth = useAuthStore()
    public storeMain = useMainStore()

    public dataForm: IRequestCreateGuest = {
        address: '',
        email: '',
        firstName: '',
        phone: '',
        rut: '',
        condominium: '',
        commune: '',
        lastName: '',
        password: ''
    }

    created(): void {
        this.$watch('$route', (route: RouteLocationNormalizedLoaded) => {
            const query = route.query;
            if (query) {
                this.redirect = query.redirect as string
            }
        }, { immediate: true })
        this.loadDataFake();
    }

    loadDataFake() {
        if (process.env.NODE_ENV === 'development') {
            const pass = faker.internet.password()
            this.dataForm.firstName = faker.person.firstName();
            this.dataForm.lastName = faker.person.lastName();
            this.dataForm.email = faker.internet.email();
            this.dataForm.rut = formatRutChile(generarRUTChileno());
            this.dataForm.phone = faker.phone.number();
            this.dataForm.password = pass;
            this.dataForm.address = "av ss"
            this.dataForm.commune = "maipu"
            this.password = pass;
        }
    }

    format() {
        this.dataForm.rut = formatRutChile(this.dataForm.rut)
    }
    validateNumber = validateNumber;
    ruleValidateRut = ruleValidateRut;

    requiredTrue(field: boolean) {
        return field || 'Debe aceptar los terminos y condiciones'
    }
    formatReverse() {
        this.dataForm.rut = this.dataForm.rut.replace(/[^\dKk]/g, "");
    }

    mounted() {
        if (this.storeAuth.isLogged) {
            this.$router.push("/dashboard");
        }
    }

    async register() {
        this.loading = true;
        const form = this.$refs["loginForm"] as FormVuetify;
        const isValid = await form.validate();
        if (isValid.valid) {
            try {
                await this.userApi.createCustomer(this.dataForm);
                this.checkbox = false;
                this.$nextTick(() => {
                    form.reset();
                    form.resetValidation()
                })
                this.successForm = true;
            } catch (error) {
                getErrorCatch(error)
            }
        }
        this.loading = false;
    }
    repeatPassword(value: string) {
        if (value == this.dataForm.password) return true;
        return "Contraseña es diferente";
    }
}

