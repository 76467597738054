

import { Vue, Options } from 'vue-class-component';
@Options({
    name: 'HeaderCommonBooking',
    props: {
        background : {
            type : String,
            default :'bg-white',
            required : false
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        nameButton: {
            type: String,
            required: false,
            default: 'Volver'
        },
        line: {
            type: Boolean,
            default: true,
            required: false
        },
        classMore: {
            type: String,
            default: '',
            require: false
        }
    }
})
export default class HeaderCommonBooking extends Vue {
    public title !: string;
    public nameButton!: string;
    public line!: boolean;
    public classMore!: string;
    public background !:string
}
