


import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'InfoRoute',
    props: {
        typeTrip: {
            type: String,
            default: '',
            required: false
        },
        activeTrip: {
            type: String,
            default: 'outbound',
            required: false
        },
        stationOrigin: {
            type: String,
            default: '',
            required: false
        },
        stationDestination: {
            type: String,
            default: '',
            required: false
        },
    }
})
export default class InfoRoute extends Vue {
    public typeTrip!: string;
    public activeTrip!: string;
    public stationOrigin!: string;
    public stationDestination!: string;
}
