import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerMain = _resolveComponent("BannerMain")!
  const _component_FeaturesHome = _resolveComponent("FeaturesHome")!
  const _component_Partnerts = _resolveComponent("Partnerts")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BannerMain),
    _createVNode(_component_FeaturesHome),
    _createVNode(_component_Partnerts),
    _createVNode(_component_Contact)
  ]))
}