
import { IResponseGetGuest } from '@/types/response/guest';
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { FormVuetify } from '@/types/common/vuetify';
import { formatRutChile, getErrorCatch, requiredField, ruleValidateRut, validateEmail, validateNumber } from '@/utils';
import { UserApi } from '@/api/users';
import { UpdateCustomerRequest } from '@/types/request/guest'
import { useUserStore } from '@/store/modules/user';
import { useMainStore } from '@/store/modules/main';

@Options({
    name: 'UpdateCustomer',
    props: {
        isVisible: {
            type: Boolean,
            default: false,
            required: false
        },
        customer: {
            type: Object,
            default: () => ({}),
            required: false
        }
    },
    components: {
        ModalCommon
    }
})
export default class UpdateCustomer extends Vue {
    public isVisible !: boolean;
    public customer!: IResponseGetGuest;
    private userApi = new UserApi();
    private mainStore = useMainStore()
    private userStore = useUserStore()
    public loadingUpdate = false;
    public requiredField = requiredField;
    public validateEmail = validateEmail;
    public formatRutChile = formatRutChile;
    public validateNumber = validateNumber;
    public ruleValidateRut = ruleValidateRut;

    public dataUpdate: UpdateCustomerRequest = {
        firstName: '',
        lastName: '',
        rut: '',
        email: '',
        phone: ''
    }

    mounted(): void {
        this.$watch('customer', () => {
            this.loadCustomer()
        })
    }

    async saveDriver() {
        const form = this.$refs['formUpdateCustomer'] as FormVuetify
        const valid = await form.validate()
        if (valid.valid) {
            this.loadingUpdate = true;
            try {
                await this.userApi.updateCustomer(this.dataUpdate, this.customer._id)
                await this.userStore.getUsers();
                this.mainStore.setNotification({
                    isOpen: true,
                    message : 'Usuario actualizado',
                    color : 'green darken-3'
                })
                this.$emit('closeModal')
            } catch (error) {
                getErrorCatch(error)
            }
            this.loadingUpdate = false;
        }
    }

    loadCustomer() {
        if (this.customer) {
            const { firstName, lastName, email, rut, phone } = this.customer;
            this.dataUpdate = {
                firstName,
                lastName,
                email,
                rut,
                phone
            }
        }
    }
    formatReverse() {
        this.dataUpdate.rut = this.dataUpdate.rut.replace(/[^\dKk]/g, "");
    }
    format() {
        this.dataUpdate.rut = formatRutChile(this.dataUpdate.rut)
    }
}
