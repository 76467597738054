import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "text-secondary text-helvetica h-bold mt-md-2 mt-1"
}
const _hoisted_3 = {
  key: 1,
  class: "text-secondary text-helvetica h-bold mt-md-2 mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_ctx.trip.returnData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.getCancelRoute == 'ALL' || _ctx.getCancelRoute == 'FIRST' ? ' text-decoration-line-through text-red-darken-1 ' : '')
        }, [
          _createVNode(_component_v_icon, {
            icon: "mdi-arrow-right-bold",
            size: "x-small",
            color: "primary",
            class: "mr-1"
          }),
          _createTextVNode(_toDisplayString(_ctx.convertTo12HourFormat(_ctx.typeSelected == 'OUTBOUND' ? _ctx.trip.hourDeparture : _ctx.trip.hourArrival)) + " ", 1),
          (!(_ctx.getCancelRoute == 'ALL' || _ctx.getCancelRoute == 'FIRST'))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.trip.isLate && _ctx.typeSelected === 'OUTBOUND')
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      icon: "mdi-clock-check",
                      size: "x-small",
                      color: "green-darken-2"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.typeSelected==='OUTBOUND')
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.trip.stationDestination), 1))
          : _createCommentVNode("", true),
        (_ctx.typeSelected==='RETURN')
          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.trip.stationOrigin), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.getCancelRoute == 'ALL' || _ctx.getCancelRoute == 'SECOND' ? ' text-decoration-line-through text-red-darken-1 ' : '')
        }, [
          _createVNode(_component_v_icon, {
            icon: "mdi-arrow-left-bold",
            size: "x-small",
            color: "primary",
            class: "mr-1"
          }),
          _createTextVNode(_toDisplayString(_ctx.convertTo12HourFormat(_ctx.typeSelected == 'OUTBOUND' ? _ctx.trip.returnData.hourDeparture : _ctx.trip.returnData.hourArrival)) + " ", 1),
          (!(_ctx.getCancelRoute == 'ALL' || _ctx.getCancelRoute == 'SECOND'))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.trip.isLateReturn && _ctx.typeSelected === 'RETURN')
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      icon: "mdi-clock-check",
                      size: "x-small",
                      color: "green-darken-2"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}