
import { Options, Vue } from 'vue-class-component';
import TableGuest from '../components/users/TableGuestUser.vue';

@Options({
  name: 'UsuariosView',
  components : {
    TableGuest
  }
})
export default class UsuariosView extends Vue { }
