
import { Vue, Options } from 'vue-class-component';
import FbIcon from '@/assets/images/icons/icon_fb.svg'
import IgIcon from '@/assets/images/icons/icon_ig.svg'
import WspIcon from '@/assets/images/icons/icon_wsp.png'
import { IRequestCreateContact } from '@/api/entity/create-contact'
import { MainApi } from '@/api/main'
import { customScrollTo, requiredField, validateEmail } from '@/utils';
import TextLoaderButton from '../common/LoaderButtons.vue';

@Options({
  name: 'Contact',
  components: {
    TextLoaderButton
  }
})
export default class Contact extends Vue {
  private mainApi = new MainApi();
  public fbIcon = FbIcon;
  public igIcon = IgIcon;
  public wspIcon = WspIcon;
  public loading = false
  public errorForm = false
  public successForm = false;

  public dataContact: IRequestCreateContact = {
    fullName: '',
    phone: '',
    email: '',
    message: ''
  }

  async onSubmit() {
    const form = this.$refs['formContact'];
    this.errorForm = false
    this.successForm = false;
    if (form) {
      //eslint-disable-next-line
      const validate = await (form as any).validate();
      if (validate.valid) {
        this.loading = true;
        try {
          await this.mainApi.createContact(this.dataContact);
          this.successForm = true;
          //eslint-disable-next-line
          (form as any).reset();
        } catch (error) {
          this.errorForm = true
        }
        this.loading = false;
      }
    }
  }
  validateEmail = validateEmail;
  requiredField = requiredField;
  requiredTrue(field: boolean) {
    return field || 'Debe aceptar los terminos y condiciones'
  }
  toScroll(e: Event, data: string) {
    e.preventDefault()
    const path = this.$route.path;
    if (!data.includes("#")) {
      this.$router.push(data);
      return;
    }
    if (path === '/') {
      const topOffset = 80
      const sectionId = data.split("#")[1]
      customScrollTo(sectionId, topOffset)
    } else {
      this.$router.push(data)
    }
  }
}
