
import { Options, Vue } from 'vue-class-component';
import TableDrivers from '../components/users/TableDrivers.vue';
import CreateDriver from '@/components/users/CreateDriver.vue';

@Options({
  name: 'UsuariosView',
  components: {
    TableDrivers,
    CreateDriver
  }
})
export default class UsuariosView extends Vue {
  public visibleCreateDriver = false;
}
