
import { Options, Vue } from 'vue-class-component';
import { useCalendarStore } from '@/store/modules/calendar'
import { customScrollTo, getErrorCatch, sleep, translate } from '@/utils';
import ItemListDriver from '@/components/DriverCalendar/ItemListDriver.vue'
import { useMainStore } from '@/store/modules/main';
import ListPlannings from '@/components/DriverCalendar/ListPlannings.vue';
import { GetCalendarResponse, GetCalendarSeating, TypeStatusSeating } from '@/types/response/planning';
import { formatDateStart, formatDateStartJSDate, getDate } from '@/filters/formatDate';
import { mergeProps } from 'vue'
import { PlanningTripAPI } from '@/api/planning';
import { useDisplay } from 'vuetify'

interface Markers {
    date: Date | string;
    type?: 'dot' | 'line';
    tooltip?: { text: string; color?: string; }[];
    color?: string;
}

@Options({
    name: 'DriverCalendar',
    components: {
        ItemListDriver,
        ListPlannings
    }
})
export default class DriverCalendar extends Vue {
    public getDate = getDate;
    public translate = translate;
    private apiPlanning = new PlanningTripAPI();
    public visibleVehicle = false;
    public calendarStore = useCalendarStore();
    public mainStore = useMainStore()
    public selectedDay: GetCalendarResponse | null = null;
    public date: Date | null = null;
    public markers: Markers[] = [];
    public loadingGeneral = false;
    public mergeProps = mergeProps;
    public timeOut = 0;
    public mobile = false;
    public seatingsAvailables: TypeStatusSeating[] = ['ABSEND', 'ATTENDED', 'ON_HOLD']
    public isPostergados = false;

    get planning() {
        return this.calendarStore.planningSelected
    }

    get isLoading() {
        return this.calendarStore.loadingPlanningSelected
    }

    get calendar() {
        return this.calendarStore.calendarData || undefined;
    }

    get stations() {
        return this.calendarStore.stations;
    }

    unmounted(): void {
        this.resetCalendar()
    }

    created() {
        try {
            const { mobile } = useDisplay();
            this.mobile = mobile.value;
        } catch (error) {
            console.log(error)
        }
    }

    async mounted() {
        this.loadingGeneral = true;
        try {
            await this.calendarStore.getPlanningTrips();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingGeneral = false
    }
    removeSelected() {
        this.calendarStore.removePlaning()
        this.selectedDay = null;
    }
    loadCalendar() {
        this.$nextTick(async () => {
            if (!this.planning) return;
            this.date = this.planning.days[0] as Date;
            this.isPostergados = false;
            this.calendar.forEach(data => {
                this.markers.push({
                    date: data.dateExecuting,
                    color: '#03cbf9',
                    type: 'line',
                    tooltip: [{ text: `Asientos: ${data.seatings.length}`, color: 'green' }],
                })
            });
            this.selectedDay = this.calendar[0];
            if (this.mobile) {
                await sleep(300, this.timeOut);
                customScrollTo('cardCalendar', 80)
            }
        })
    }
    setDate(modelData: Date) {
        const data = formatDateStartJSDate(new Date(modelData));
        const dataFull = data.toFormat('yyyy-MM-dd');
        this.selectedDay = this.calendar.find(element => {
            const dataFullCalendar = formatDateStart(element.dateExecuting as string).toFormat('yyyy-MM-dd')
            return dataFullCalendar === dataFull;
        }) || null;
    }
    sorted(a: GetCalendarSeating, b: GetCalendarSeating) {
        const seating1 = parseInt(a.seating);
        const seating2 = parseInt(b.seating);
        return seating1 - seating2;
    }
    findStation(id: string) {
        return this.stations.find(({ _id }) => _id === id)
    }
    findStationDestination(id: string) {
        const station = this.findStation(id);
        if (!station) return ''
        return station.routeStation.finalStation

    }
    findStationOrigin(id: string) {
        const station = this.findStation(id);
        if (!station) return ''
        return station.routeStation.originStation
    }
    resetCalendar() {
        this.selectedDay = null;
        this.markers = [];
        this.date = null;
        this.calendarStore.removePlaning()
    }

    async changeStatus(id: string, status: TypeStatusSeating) {
        if (!this.planning) return
        if (this.selectedDay == null) return;
        const dateEx = this.selectedDay.dateExecuting;
        try {
            this.selectedDay.seatings.find(({ _id }) => { _id === id });
            this.selectedDay.seatings = this.selectedDay.seatings.map((seating) => {
                if (seating._id == id) {
                    seating.loading = true;
                }
                return seating;
            })
            await this.apiPlanning.updateSeatingStatus(id, status);
            await this.calendarStore.getCalendar(this.planning._id)
            this.selectedDay = null;
            if (this.date) {
                this.setDate(dateEx as Date);
            } else {
                this.setDate(new Date(this.planning.days[0]))
            }
        } catch (error) {
            getErrorCatch(error)
        }
    }

    getColorStatus(status: TypeStatusSeating) {
        const colors: Record<TypeStatusSeating, string> = {
            ABSEND: 'orange',
            ATTENDED: 'green',
            POSTPONED: 'red',
            ON_HOLD: 'grey-darken-4',
            FINALIZED : 'blue'
        }
        return colors[status] || 'grey'
    }


    beforeUnmount(): void {
        clearInterval(this.timeOut);
    }

    checkPostergados(value:boolean) {
        if (!this.selectedDay) return;
        console.log(value)

    }
}
