
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import BannerMain from '@/components/home/BannerMain.vue'
import FeaturesHome from '@/components/home/Features.vue'
import Help from '@/components/home/Help.vue';
import Contact from '@/components/home/Contact.vue';
import { customScrollTo } from '@/utils';
import Partnerts from '@/components/home/Partnerts.vue';

@Options({
  components: {
    HelloWorld,
    BannerMain,
    FeaturesHome,
    Help,
    Contact,
    Partnerts
  },
})
export default class HomeView extends Vue {
  public timeOut: number | undefined = undefined
  
  mounted(): void {
    this.timeOut = setTimeout(() => {
      const router = this.$route;
      const hash = router.hash;
      if (hash) {
        customScrollTo(hash.slice(1), 80)
      }
    }, 200);
  }
  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }
}
