import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!

  return (_openBlock(), _createBlock(_component_ModalCommon, {
    textButton: "Actualizar",
    onEventSuccess: _ctx.saveVehicle,
    "max-width": "1000",
    isVisible: _ctx.isVisible,
    title: "Actualizar vehículo",
    loading: _ctx.loadingCreateVehicle,
    onCloseModal: _ctx.closeModalCreateVehicle
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "formCreateVehicle" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    variant: "outlined",
                    density: "comfortable",
                    label: "Marca",
                    modelValue: _ctx.brandSelected,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.brandSelected) = $event)),
                      _ctx.getModels
                    ],
                    items: _ctx.brands,
                    "item-value": "_id",
                    "item-title": "name",
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.dataCreate.model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataCreate.model) = $event)),
                    loading: _ctx.loadingModels,
                    variant: "outlined",
                    density: "comfortable",
                    label: "Modelos",
                    disabled: _ctx.brandSelected == '',
                    items: _ctx.models,
                    "item-value": "_id",
                    "item-title": "name"
                  }, null, 8, ["modelValue", "loading", "disabled", "items"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.dataCreate.typeVehicle,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataCreate.typeVehicle) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Tipo de vehículo",
                    items: _ctx.typesVehicle,
                    "item-value": "_id",
                    "item-title": "name"
                  }, null, 8, ["modelValue", "items"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.patent,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataCreate.patent) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Patente",
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.year,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataCreate.year) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Año",
                    type: "number",
                    rules: [_ctx.requiredField, (value) => _ctx.min(value, 1900)]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.gpsID,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataCreate.gpsID) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "GPS",
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.seatings,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataCreate.seatings) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Asientos",
                    type: "number",
                    rules: [_ctx.requiredField, (value) => _ctx.min(value, 1)]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    variant: "outlined",
                    density: "comfortable",
                    label: "Tipo de distribución",
                    modelValue: _ctx.dataCreate.typeDistribution,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataCreate.typeDistribution) = $event)),
                    items: _ctx.typeDistribution,
                    "item-value": "_id",
                    "item-title": "name",
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "items", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["onEventSuccess", "isVisible", "loading", "onCloseModal"]))
}