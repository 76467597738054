
import { Options, Vue } from "vue-class-component";
import { useMainStore } from "@/store/modules/main";

@Options({
  name: "Notification",
})
export default class Notification extends Vue {
  public store = useMainStore();

  get show() {
    return this.store.notification.isOpen;
  }

  set show(value: boolean) {
    this.store.setNotification({
      message: "",
      isOpen: value,
      color: this.color,
    });
  }

  get message() {
    return this.store.notification.message;
  }

  get color() {
    return this.store.notification.color;
  }

  get timeout() {
    return this.store.notification.timeout || 1000;
  }

  get isLink() {
    return this.store.notification.isLink;
  }

  get messageLink() {
    return this.store.notification.nameLink;
  }

  get link() {
    return this.store.notification.link;
  }

  get buttonClose() {
    return this.store.notification.buttonClose;
  }

  closeNotification() {
    this.store.setNotification({
      message: "",
      isOpen: false,
      color: this.color,
    });
  }
}
