import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { CreateCouponRequest, GetCouponsRequest } from "@/types/request/coupon";
import { COUPON_ENDPOINTS } from "./endpoint";
import { GetCouponNameResponse, GetCuponsPaginationResponse } from "@/types/response/coupon";

export class CouponApi {
  private request = new API(ROUTES_GLOBAL.COUPONS);
  private api: AxiosInstance = this.request.getInstance();

  async createCoupon(created: CreateCouponRequest) {
    await this.api.post(COUPON_ENDPOINTS.CREATE_COUPONS, created);
  }

  async getCoupons({limit,page,name,customer} : GetCouponsRequest){
    let URL = `${COUPON_ENDPOINTS.GET_ALL_COUPONS}?page=${page}&limit=${limit}`;
    if(name){
      URL+=`&name=${name}`
    }
    if(customer){
      URL+=`&user=${customer}`
    }
    const { data } = await this.api.get<GetCuponsPaginationResponse>(URL);
    return data;
  }

  async deleteCoupon(id: string){
    await this.api.delete(COUPON_ENDPOINTS.DELETE_COUPON+id);
  }
  async validateCoupon(name : string, total : number){
    const {data} = await this.api.get<GetCouponNameResponse>(COUPON_ENDPOINTS.GET_COUPON_NAME+name+"?total="+total);
    return data;
  }


  async getSelectCoupons() {
    const { data } = await this.api.get<{_id: string, name: string}[]>(
      COUPON_ENDPOINTS.GET_LIST_COUPONS
    );
    return data;
  }
}
