
import { Vue, Options } from "vue-class-component";
import BannerMaintenance from "@/assets/images/maintenance.png";
import IconLarge from "@/assets/images/logo.svg";
import { useAuthStore } from "@/store/modules/auth";
import { TripApi } from "@/api/trip";
import { useTripStore } from "@/store/modules/trips";
import { GpsDataEntity } from "@/types/request/trip";
import { UrlConstants } from "@/constants/url.constants";
import ModalCommon from "@/components/common/ModalCommon.vue";
/* eslint-disable */
declare const L: any;
declare const io: any;

@Options({
  name: "GPSPage",
  components: {
    ModalCommon,
  },
})
export default class GPSPage extends Vue {
  public banner = BannerMaintenance;
  public logo = IconLarge;
  public authStore = useAuthStore();
  public tripStore = useTripStore();
  public tripApi = new TripApi();
  public map: any = null;
  public userLatLng: number[] = [];
  public LatLng: number[] = [-33.445666, -70.660068];
  public direction = 190;
  public error: string | null = null;
  public marker: any = null;
  public top = false;
  public lastTime: number = 0;
  public visibleModalFinish = false;

  get agentData() {
    const toMatchIphone = /iPhone/i;
    const toMatchSafari = /Safari/i;
    if (navigator.userAgent.match(toMatchIphone)) {
      return "iphone-device-chrome";
    }
    if (navigator.userAgent.match(toMatchSafari)) {
      return "iphone-device-safari";
    }
    return "android-device";
  }

  get codeTrip() {
    return this.$route.params.id;
  }

  get typeTrip() {
    return this.$route.query["tipo"] as string;
  }

  get idTrip() {
    return this.$route.query["code"] as string;
  }

  get generalQuery() {
    return this.$route.query["general"] as string;
  }

  get tripSelected() {
    return this.tripStore.tripSelectedGPS;
  }

  mounted(): void {
    this.initGPS();
  }

  onLocationFound(e: any) {
    this.userLatLng = [e.latitude, e.longitude];
    this.map.fitBounds([this.LatLng, this.userLatLng]);
  }

  async initGPS() {
    try {
      if (this.idTrip) {
        await this.tripStore.getTripSelectedGPSTrip(
          this.codeTrip as string,
          this.idTrip,
          this.typeTrip
        );
      } else {
        await this.tripStore.getTripSelectedGPS(
          this.codeTrip as string,
          this.typeTrip
        );
      }

      const vanIcon = L.icon({
        iconUrl: "/van_marker.png",
        shadowUrl: "/van_marker.png",
        iconSize: [90, 90],
        iconAnchor: [50, 50],
        className: "marker-icon",
      });
      this.$nextTick(() => {
        const latVehicle = this.tripSelected?.lastGps
          ? [
              this.tripSelected.lastGps.latitude,
              this.tripSelected.lastGps.longitude,
            ]
          : this.LatLng;
        const direction = this.tripSelected?.lastGps
          ? this.tripSelected.lastGps.direction
          : 180;
        this.map = L.map("map", { zoomControl: false }).setView(latVehicle, 30);
        const googleTiles = L.tileLayer(
          "https://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
          {
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
            attribution: "Datos del mapa &copy; 2024 Google",
          }
        );
        googleTiles.addTo(this.map);
        this.map.on("locationfound", this.onLocationFound);
        this.map.on("locatedeactivate", (e: any) => {
          this.userLatLng = [];
          this.map.setView(this.LatLng, this.map.getZoom());
        });
        L.control.zoom({ position: "bottomleft" }).addTo(this.map);
        L.control
          .locate({
            position: "bottomright",
            strings: {
              title: "Mi ubicación",
            },
            locateOptions: {
              enableHighAccuracy: true,
              maxZoom: 50,
            },
          })
          .addTo(this.map);

        this.marker = L.marker(latVehicle, {
          icon: vanIcon,
          rotationAngle: direction,
        }).addTo(this.map);
        if (this.tripSelected) {
          const socket = io(UrlConstants.URL_SOCKET);
          socket.emit("subscribeToVehicle", this.tripSelected.vehicle);
          socket.on(
            this.tripSelected.vehicle,
            (gpsData: GpsDataEntity | string) => {
              if (typeof gpsData == "string") {
                //? Events (USS: Update status seating)
                if (gpsData.startsWith("USS")) {
                  this.validSeating(gpsData);
                }
                return;
              }
              // Verificar si el nuevo 'time' es mayor que el último 'time' registrado
              const entryTime = parseInt(gpsData.time);
              if (entryTime >= this.lastTime) {
                this.lastTime = entryTime; // Actualizar el último 'time' registrado
                // Actualizar la posición del marcador y su rotación
                this.animateMarker(
                  { lat: this.LatLng[0], lng: this.LatLng[1] },
                  { lat: gpsData.latitude, lng: gpsData.longitude },
                  1000
                );
                this.LatLng = [gpsData.latitude, gpsData.longitude];

                //this.marker.setLatLng(this.LatLng);
                this.marker.setRotationAngle(gpsData.direction);
                this.marker.setIcon(vanIcon);
                // Calcular el punto medio si se ha encontrado la ubicación del usuario
                if (this.userLatLng.length > 1) {
                  console.log(this.map.getZoom(), this.userLatLng);
                  this.map.fitBounds([this.LatLng, this.userLatLng]);
                } else {
                  // Si no hay ubicación del usuario, centrar en la ubicación de la van
                  this.map.setView(this.LatLng, this.map.getZoom());
                }
              }
            }
          );
        }
      });
    } catch (error) {
      this.error = (error as any).message;
    }
  }

  animateMarker(
    startLatLng: { lat: number; lng: number },
    endLatLng: { lat: number; lng: number },
    duration: number
  ) {
    const startTime = performance.now();

    const moveMarker = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const lat =
        startLatLng.lat + (endLatLng.lat - startLatLng.lat) * progress;
      const lng =
        startLatLng.lng + (endLatLng.lng - startLatLng.lng) * progress;

      this.marker.setLatLng([lat, lng]);

      if (progress < 1) {
        requestAnimationFrame(moveMarker);
      }
    };

    requestAnimationFrame(moveMarker);
  }

  validSeating(data: string) {
    const idSeating = data.split(",")[1];
    if (this.tripSelected && this.tripSelected.seatings) {
      const { seatings } = this.tripSelected;
      if (seatings.length == 1) {
        if (idSeating == seatings[0]) {
          this.visibleModalFinish = true;
          setTimeout(() => {
            this.closePage();
          }, 4000);
        }
      } else if (seatings.length >= 2) {
        console.log("OKEY");
      }
    }
  }
  closePage() {
    this.$router.back();
  }
}
