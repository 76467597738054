
import { Vue, Options } from 'vue-class-component';
import BannerMaintenance from '@/assets/images/maintenance.png'
import IconLarge from '@/assets/images/logo.svg';

@Options({
    name: 'MaintenancePage',
    components: {
    }
})
export default class MaintenancePage extends Vue {
   public banner = BannerMaintenance;
   public logo = IconLarge;
}

