import { AxiosInstance } from "axios";
import API from "@/utils/request";
import { ROUTES_GLOBAL } from "@/api/paths";
import { AUTH_ENDPOINTS } from "./endpoints";
import { IRequestLogin } from "@/types/request/login";
import {
  IResponseGetProfile,
  IResponseLogin,
  ResponseValidateForgot,
} from "@/types/response/login";
import { UpdateCustomerRequest } from "@/types/request/guest";

export class AuthApi {
  private request = new API(ROUTES_GLOBAL.AUTH);
  private api: AxiosInstance = this.request.getInstance();

  async login(dataLogin: IRequestLogin) {
    const { data } = await this.api.post<IResponseLogin>(
      AUTH_ENDPOINTS.LOGIN,
      dataLogin
    );
    return data.accessToken;
  }

  async getProfile() {
    const { data } = await this.api.get<IResponseGetProfile>(
      AUTH_ENDPOINTS.PROFILE
    );
    return data;
  }

  async forgot(email: string) {
    await this.api.post(AUTH_ENDPOINTS.FORGOT, { username: email });
  }

  async validateForgot(code: string) {
    const { data } = await this.api.post<ResponseValidateForgot>(
      AUTH_ENDPOINTS.VALIDATE_FORGOT,
      { code }
    );
    return data;
  }

  async updatePassword(code: string, password: string, userID: string) {
    await this.api.put(AUTH_ENDPOINTS.UPDATE_PASSWORD, {
      code,
      password,
      userID,
    });
  }

  async updateProfile(dataUpdate: UpdateCustomerRequest) {
    await this.api.put(AUTH_ENDPOINTS.UPDATE_PROFILE, dataUpdate);
  }

  async updatePasswordAUTH(password: string, newPassword: string) {
    await this.api.post(AUTH_ENDPOINTS.UPDATE_PASSWORD_AUTH, {
      password,
      newPassword,
    });
  }
}
