
import { PlanningTripAPI } from "@/api/planning";
import { convertTo12HourFormat, getDate } from "@/filters/formatDate";
import { useAuthStore } from "@/store/modules/auth";
import { useMainStore } from "@/store/modules/main";
import { usePlanningStore } from "@/store/modules/planning";
import { useRoutesStore } from "@/store/modules/route";
import { SelectData } from "@/types/common/vuetify";
import { GetBookingResponse } from "@/types/response/planning";
import { customScrollTo, formatCurrency, getErrorCatch, sleep } from "@/utils";
import { Vue, Options } from "vue-class-component";
import { useDisplay } from "vuetify";
@Options({
  name: "ListTripResult",
})
export default class ListTripResult extends Vue {
  public planningStore = usePlanningStore();
  private routeStore = useRoutesStore();
  private authStore = useAuthStore();
  private planningTripApi = new PlanningTripAPI();
  public convertTo12HourFormat = convertTo12HourFormat;
  public getDate = getDate;
  public formatCurrency = formatCurrency;
  public mobile = false;
  public timeOut = 0;
  private mainStore = useMainStore();
  public isLoadingNotification = false;
  public items: SelectData[] = [
    {
      title: "Menor precio",
      value: "price-low",
    },
    {
      title: "Salida más temprano",
      value: "earlier",
    },
    {
      title: "Salida más tarde",
      value: "late",
    },
  ];

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  get tripsSearch() {
    return this.planningStore.tripsSelected;
  }

  get tripsFinalSearch() {
    return this.planningStore.tripsSelectedFinal;
  }

  get emptyTrips() {
    return this.planningStore.emptyTrips;
  }

  get isLoadingBooking() {
    return this.planningStore.isLoadingBooking;
  }

  get searchSelected() {
    return this.planningStore.searchSelected;
  }

  get stageTrip() {
    return this.planningStore.stageTrip;
  }

  get stations() {
    return this.routeStore.stationsList;
  }
  getRouteById(id: string) {
    return this.routeStore.getStationById(id)?.name || "-";
  }

  existActiveTripNotification(trip: GetBookingResponse) {
    const user = this.authStore.user;
    if (user) {
      const notifications = user.activeTripNotifications;
      return (
        notifications.find((not) => not.includes(trip.activeTrip)) == undefined
      );
    }
    return false;
  }

  getStationSelected(id?: string) {
    return this.stations.find(({ _id }) => _id === id)?.name || "";
  }

  async addNotificationUser(
    trip: GetBookingResponse,
    type: "add" | "remove" = "add"
  ) {
    this.isLoadingNotification = true;
    try {
      if (type == "add") {
        const routeFrom =this.getStationSelected(this.searchSelected?.origin || "") ;
        const routeTo =this.getStationSelected(this.searchSelected?.destination || "") ;
        await this.planningTripApi.addUserNotification(trip.activeTrip, {
          hourFinish: trip.hourFinish,
          hourInit: trip.hourInit,
          routeFrom: this.stageTrip == 'return' ? routeTo : routeFrom,
          routeTo: this.stageTrip == 'return' ? routeFrom : routeTo
        });
      } else {
        await this.planningTripApi.removeUserNotification(trip.activeTrip);
      }
      await this.authStore.getUser();
    } catch (error) {
      getErrorCatch(error);
    }
    this.isLoadingNotification = false;
  }

  async addTrip(trip: GetBookingResponse) {
    if (trip.vehicle.seatings - trip.seatings.length === 0) {
      return;
    }
    this.planningStore.setTrip(trip);
    if (this.searchSelected?.typeTrip === "round") {
      if (this.stageTrip == "outbound") {
        this.mainStore.setNotification({
          isOpen: true,
          message: "Seleccione su viaje de vuelta.",
          color: "green darken-2",
        });
        this.planningStore.updateStageTrip("return");
      }
    }
    if (this.mobile) {
      await sleep(300, this.timeOut);
      if (this.searchSelected?.typeTrip == "round") {
        if (this.stageTrip == "return") {
          customScrollTo("type-trip-title", 0);
          return;
        }
        customScrollTo("null", 0);
      }
      customScrollTo("null", 0);
    }
  }

  backTripFirst() {
    this.planningStore.updateStageTrip("outbound");
  }

  setType(value: string) {
    switch (value) {
      case "price-low":
        this.planningStore.orderPrice();
        break;
      case "earlier":
        this.planningStore.orderTime();
        break;
      case "late":
        this.planningStore.orderTime(false);
        break;
      default:
        break;
    }
  }
}
