import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBar = _resolveComponent("AppBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_AppBar, {
        isLogged: _ctx.isLogged,
        onSignOut: _ctx.signOut
      }, null, 8, ["isLogged", "onSignOut"]),
      _createVNode(_component_router_view),
      _createVNode(_component_Notification)
    ]),
    _: 1
  }))
}