
import {
  formatRutChile,
  getErrorCatch,
  requiredField,
  ruleValidateRut,
  validateEmail,
  validateNumber,
} from "@/utils";
import { Options, Vue } from "vue-class-component";
import { EditDriverRequest } from "@/types/request/guest";
import ModalCommon from "../common/ModalCommon.vue";
import { UserApi } from "@/api/users";
import { useDriverStore } from "@/store/modules/drivers";
import { FormVuetify } from "@/types/common/vuetify";
import { IResponseGetGuest } from "@/types/response/guest";
@Options({
  name: "EditDriver",
  components: { ModalCommon },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    driver: {
      type: Object,
      default: null,
      required: false,
    },
  },
})
export default class EditDriver extends Vue {
  public loadingCreateRoute = false;
  public isVisible!: boolean;
  public driver!: IResponseGetGuest;
  public showPassword = false;
  public requiredField = requiredField;
  public validateEmail = validateEmail;
  public validateNumber = validateNumber;
  public ruleValidateRut = ruleValidateRut;
  private userApi = new UserApi();
  private driverStore = useDriverStore();
  public dataDriver: EditDriverRequest = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    rut: "",
  };

  mounted(): void {
    this.$watch("driver", (value: IResponseGetGuest) => {
      if (value) {
        const { email, firstName, lastName, phone, rut } = value;
        this.dataDriver = {
          email,
          firstName,
          lastName,
          phone,
          rut,
        };
      }
    });
  }

  async saveDriver() {
    const form = this.$refs["formEditDriver"] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {
      this.loadingCreateRoute = true;
      try {
        await this.userApi.updateDriver(this.driver._id, this.dataDriver);
        await this.driverStore.getDrivers();
        this.closeModal();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingCreateRoute = false;
    }
  }
  closeModal() {
    const form = this.$refs["formEditDriver"] as FormVuetify;
    if (!form) return;
    this.dataDriver = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      rut: "",
    };
    form.reset();
    this.$emit("closeModal");
  }
  formatReverse() {
    this.dataDriver.rut = this.dataDriver.rut.replace(/[^\dKk]/g, "");
  }
  format() {
    this.dataDriver.rut = formatRutChile(this.dataDriver.rut);
  }
}
