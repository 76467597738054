
import { Vue, Options } from 'vue-class-component';
import { requiredField, validateEmail, getErrorCatch } from '@/utils'
import { useAuthStore } from '../store/modules/auth';
import { useMainStore } from '../store/modules/main';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { FormVuetify } from '@/types/common/vuetify';
import TextLoaderButton from '@/components/common/LoaderButtons.vue';
import { AuthApi } from '@/api/auth/index'

@Options({
    name: 'ResetPasswordPage',
    components: {
        TextLoaderButton
    }
})
export default class ResetPasswordPage extends Vue {
    private authApi = new AuthApi();
    public loading = false;
    public email = '';
    public password = '';
    public confirmPassword = ''
    public showPassword = false;
    public showPassword2 = false;
    private code?: string;
    public correct = false;
    public loadingValidate = false;
    public resetNormal = true;
    public invalidValidate = false;
    public loadingReset = false;
    public isValidReset = false;
    private userID = ''

    validateEmail = validateEmail;
    requiredField = requiredField;

    public storeAuth = useAuthStore()
    public storeMain = useMainStore()

    created(): void {
        this.$watch('$route', (route: RouteLocationNormalizedLoaded) => {
            const query = route.query;
            if (query.codigo) {
                this.code = query.codigo as string;
                this.loadCode();
            }
        }, { immediate: true })
    }

    async loadCode() {
        this.userID = '';
        if (!this.code) return;
        this.loadingValidate = true;
        this.resetNormal = false;
        this.invalidValidate = false;
        try {
            const { isValid, user } = await this.authApi.validateForgot(this.code);
            this.invalidValidate = !isValid;
            this.userID = user;
        } catch (error) {
            this.invalidValidate = true;
        }
        this.loadingValidate = false;
    }

    mounted() {
        if (this.storeAuth.isLogged) {
            this.$router.push("/dashboard");
        }
    }

    async resetPassowrd() {
        this.loading = true;
        this.correct = false;
        //eslint-disable-next-line
        const form = this.$refs["resetForm"] as FormVuetify;
        const isValid = await form.validate();
        if (isValid.valid) {
            try {
                await this.authApi.forgot(this.email);
                this.correct = true;
                this.email = ''
                form.resetValidation();
                form.reset()
            } catch (error) {
                getErrorCatch(error)
            }
        }
        this.loading = false;
    }
    repeatPassword(value: string) {
        if (value == this.password) return true;
        return "Contraseña es diferente";
    }
    async confirmResetPassword() {
        if (!this.code) return;
        const form = this.$refs["resetPassword"] as FormVuetify;
        const isValid = await form.validate();
        if (!isValid) return;
        this.loadingReset = true;
        try {
            await this.authApi.updatePassword(this.code, this.password, this.userID)
            this.isValidReset = true;
        } catch (error) {
            this.isValidReset = false;
            getErrorCatch(error)
        }
        this.loadingReset = false;
    }
}

