import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationDrawer = _resolveComponent("NavigationDrawer")!
  const _component_ToolBar = _resolveComponent("ToolBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_ctx.user?.role !== 'CUSTOMER')
        ? (_openBlock(), _createBlock(_component_NavigationDrawer, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ToolBar),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            class: _normalizeClass(["fill-height align-start container_main", [_ctx.user?.role === 'CUSTOMER' ? 'bg-white' : '',_ctx.keysRoutes.includes(_ctx.key)  && _ctx.user?.role === 'CUSTOMER' ? 'px-0 py-0' : '' ]]),
            fluid: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_layout, { class: "w-100" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      _createVNode(_component_Notification)
    ]),
    _: 1
  }))
}