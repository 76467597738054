
import { Options, Vue } from 'vue-class-component';
import { iItemMenu } from './types';
import { AuthMenu } from '@/assets/data/menu'
import { useAuthStore } from '@/store/modules/auth';

@Options({
  name: "MainMenu",
})
export default class MainMenu extends Vue {
  //public menuItems: iItemMenu[] = AuthMenu;
  private storeAuth = useAuthStore();

  get menuItems(): iItemMenu[] {
    const roleUser = this.storeAuth.user;
    if (!roleUser) return []
    const menu = AuthMenu.map((menu) => {
      const items = menu.items.filter(({ permissions }) => permissions ? permissions.includes(roleUser.role) : false)
      return {
        ...menu,
        items
      }
    }).filter(({ items }) => items.length > 0)
    return menu;
  }
}
