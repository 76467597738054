
import { Options, Vue } from "vue-class-component";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useUserStore } from "../../store/modules/user";
import { getErrorCatch } from "../../utils";
import { formatDateCompleted } from "@/filters/formatDate";
import { useMainStore } from "../../store/modules/main";
import { UrlConstants } from "@/constants/url.constants";
import visaImagen from "@/assets/images/visa_logo.png";
import { IResponseGetGuest } from "@/types/response/guest";
import UpdateCustomer from "./UpdateCustomer.vue";
import { UserApi } from "@/api/users";
import ModalCommon from "../common/ModalCommon.vue";

@Options({
  name: "TableGuest",
  components: {
    UpdateCustomer,
    ModalCommon,
  },
})
export default class TableGuest extends Vue {
  UrlConstants = UrlConstants;
  private userStore = useUserStore();
  private mainStore = useMainStore();
  public isVisibleUpload = false;
  public loadingUpload = false;
  private userApi = new UserApi();
  public visaImagen = visaImagen;
  public formatDateCompleted = formatDateCompleted;
  public imagePDF: File[] = [];
  public loadingTable = false;
  public fullName = "";
  public rut = "";
  public visibleBlockUser = false;
  public loadingBlockUser = false;
  public isActionBlock = false;
  public rules = [
    (value: any) => {
      return (
        !value ||
        !value.length ||
        value[0].size < 10000000 ||
        "Contracto muy pesado"
      );
    },
  ];
  public customerSelected: IResponseGetGuest | null = null;
  public openEditCustomer = false;
  public serverOptions: ServerOptions = {
    page: 1,
    rowsPerPage: 5,
  };

  public headers: Header[] = [
    { text: "Fec. de registro", value: "dateCreated" },
    { text: "Nombre completo", value: "fullName" },
    { text: "Celular", value: "phone" },
    { text: "Email", value: "email" },
    { text: "Tracking", value: "tracking" },
    { text: "Flow", value: "cardResult" },
    { text: "Firma", value: "dataSign" },
    { text: "", value: "actions" },
  ];

  get userSelectedFullName() {
    if (this.customerSelected != null) {
      return `${this.customerSelected.firstName} ${this.customerSelected.lastName}`;
    }
    return "";
  }

  get total() {
    return this.userStore.totalGuest;
  }

  get items() {
    return this.userStore.guest;
  }

  get fullNameQuery() {
    return this.userStore.paginationGuest.fullName;
  }

  get rutQuery() {
    return this.userStore.paginationGuest.rut;
  }

  created() {
    this.$watch("serverOptions", ({ page, rowsPerPage }: ServerOptions) => {
      this.userStore.setPage(page);
      this.userStore.setLimit(rowsPerPage);
      this.getUsers();
    });
  }

  mounted(): void {
    this.getUsers();
  }

  async getUsers() {
    this.loadingTable = true;
    try {
      await this.userStore.getUsers();
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingTable = false;
  }

  setFullName() {
    if (this.fullName == "") return;
    this.userStore.setFullname(this.fullName);
    this.fullName = "";
    this.getUsers();
  }
  removeFullName() {
    this.userStore.setFullname("");
    this.getUsers();
  }
  removeRut() {
    this.userStore.setRUT("");
    this.getUsers();
  }
  setRUT() {
    if (this.rut === "") return;
    this.userStore.setRUT(this.rut);
    this.rut = "";
    this.getUsers();
  }
  getLetters(firstName?: string, lastName?: string) {
    return `${firstName?.slice(0, 1).toLocaleUpperCase() || ""}${
      lastName?.slice(0, 1).toLocaleUpperCase() || ""
    }`;
  }
  getURLFlowCustomer(customerID: string) {
    return `${UrlConstants.URL_FLOW_WEB}app/web/subscription/sCustomerSee.php?customerId=${customerID}`;
  }
  setUpdateCustomer(customer: string) {
    this.customerSelected =
      this.items.find(({ _id }) => _id == customer) || null;
    this.openEditCustomer = true;
  }
  closeModalUpdateCustomer() {
    this.openEditCustomer = false;
    this.customerSelected = null;
  }
  async viewContract(id: string) {
    this.loadingTable = true;
    try {
      const data = await this.userApi.getContractUser(id);
      const byteCharacters = atob(data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: "application/pdf" });
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL, "_blank");
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingTable = false;
  }

  async sendPassword(_id: string) {
    this.loadingTable = true;
    try {
      await this.userApi.resendPassword(_id);
      this.mainStore.setNotification({
        isOpen: true,
        color: "green-darken-2",
        message: "Contraseña enviada",
      });
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingTable = false;
  }

  async sendEmail(_id: string) {
    this.loadingTable = true;
    try {
      await this.userApi.resendSign(_id);
      this.mainStore.setNotification({
        isOpen: true,
        color: "green-darken-2",
        message: "Correo enviado",
      });
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingTable = false;
  }

  async saveContract() {
    if (this.customerSelected == null) return;
    this.loadingUpload = true;
    try {
      const formData = new FormData();
      formData.append("image", this.imagePDF[0]);
      await this.userApi.uploadContract(this.customerSelected._id, formData);
      this.mainStore.setNotification({
        isOpen: true,
        color: "green-darken-2",
        message: "El contrato se ha subido correctamente.",
      });
      this.imagePDF = [];
      this.getUsers();
      this.closeModalUpload();
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingUpload = false;
  }

  closeModalUpload() {
    this.customerSelected = null;
    this.isVisibleUpload = false;
  }
  sendContractModal(customer: string) {
    this.customerSelected =
      this.items.find(({ _id }) => _id == customer) || null;
    this.isVisibleUpload = true;
  }
  openBlockUser(id: string, actionBlock = false) {
    this.customerSelected = this.items.find(({ _id }) => _id == id) || null;
    this.visibleBlockUser = true;
    this.isActionBlock = actionBlock;
  }
  closeModalBlockUser() {
    this.visibleBlockUser = false;
    this.customerSelected = null;
  }
  async blockUser() {
    if (!this.customerSelected) return;
    this.loadingBlockUser = true;
    try {
      if (this.isActionBlock) {
        await this.userApi.blockedUser(this.customerSelected._id);
        this.mainStore.setNotification({
          isOpen: true,
          color: "green-darken-2",
          message: "Usuario bloqueado",
        });
      } else {
        await this.userApi.desBlockedUser(this.customerSelected._id);
        this.mainStore.setNotification({
          isOpen: true,
          color: "green-darken-2",
          message: "Usuario desbloqueado",
        });
      }
      await this.getUsers();
      this.closeModalBlockUser();
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingBlockUser = false;
  }
}
