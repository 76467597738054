
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { GetTripResponse } from '@/types/response/trip';
import { TypeCancelTrip } from '@/types/request/trip';
import { formatCurrency, getErrorCatch } from '@/utils';
import { useMainStore } from '@/store/modules/main';
import { TripApi } from '@/api/trip';
import { SelectData } from '@/types/common/vuetify';

@Options({
    name: 'ModalCancelTrip',
    components: { ModalCommon },
    props: {
        tripSelected: {
            type: Object,
            default: () => ({}),
            required: false
        },
        isVisible: {
            type: Boolean,
            default: false,
            required: true
        }
    }
})
export default class ModalCancelTrip extends Vue {
    private mainStore = useMainStore();
    private apiTrip = new TripApi()
    public tripSelected!: GetTripResponse;
    public isVisible!: boolean
    public successCoupon = false;
    public couponName = "";
    public isLoadingCancelTrip = false;
    public formatCurrency = formatCurrency;
    public amountFinal = 0;
    public typeCancel: TypeCancelTrip | null = 'BOTH';
    public isBoth = false;
    public onlyOneCancel = false;

    public itemsCancel: SelectData[] = [
        {
            title: 'Ambas rutas',
            value: 'BOTH'
        },
        {
            title: 'Solo ida',
            value: 'ONE_WAY'
        },
        {
            title: 'Solo retorno',
            value: 'ONLY_RETURN'
        }
    ]

    mounted(): void {
        this.$watch('isVisible', (value: boolean) => {
            if (value) {
                if (this.tripSelected) {
                    if (this.tripSelected.typeTrip == 'round') {
                        if (this.tripSelected.typeCancelTrip && this.tripSelected.typeCancelTrip.length > 0) {
                            const typeCancelTrip = this.tripSelected.typeCancelTrip;
                            if(this.tripSelected.isLate && this.tripSelected.typeCancelTrip.includes('ONLY_RETURN')){
                                this.typeCancel = null;
                                this.isBoth = false;
                                this.itemsCancel = []
                                return;
                            }
                            if (typeCancelTrip.includes('BOTH') || (typeCancelTrip.includes('ONE_WAY') && typeCancelTrip.includes('ONLY_RETURN'))) {
                                this.typeCancel = null;
                                this.isBoth = true;
                                this.itemsCancel = []
                                return;
                            }
                            this.typeCancel = null;
                            const tripOneWay = typeCancelTrip.includes('ONE_WAY');
                            const tripOnlyReturn = typeCancelTrip.includes('ONLY_RETURN');
                            this.typeCancel = tripOneWay ? 'ONLY_RETURN' : tripOnlyReturn ? 'ONE_WAY' : null
                            this.onlyOneCancel = true
                        }else{
                            if(this.tripSelected.isLate){
                                this.typeCancel =  'ONLY_RETURN';
                                this.onlyOneCancel = true;
                            }
                        }
                    }
                }
            } else {
                this.typeCancel = 'BOTH';
                this.isBoth = false;
                this.onlyOneCancel = false
                this.itemsCancel = [
                    {
                        title: 'Ambas rutas',
                        value: 'BOTH'
                    },
                    {
                        title: 'Solo ida',
                        value: 'ONE_WAY'
                    },
                    {
                        title: 'Solo retorno',
                        value: 'ONLY_RETURN'
                    }
                ]
            }
        })
    }

    get totalPriceFinal() {
        if (!this.tripSelected) return 0;
        if (this.tripSelected.typeTrip !== 'round') return this.tripSelected.totalPrice;
        if (this.typeCancel == 'BOTH') return this.tripSelected.totalPrice
        if (!this.tripSelected.returnData) return 0;
        const priceReturn = this.tripSelected.returnData.totalPrice;
        if (this.typeCancel === 'ONE_WAY') return this.tripSelected.totalPrice - priceReturn;
        return priceReturn;
    }

    get seatingsDelete() {
        if (!this.tripSelected) {
            return ''
        }
        const seatingOwner = this.tripSelected.seating;
        let seatings = '';
        if (this.tripSelected.passengers.length > 0) {
            seatings = this.tripSelected.passengers[0].seating;
            if (this.tripSelected.passengers.length > 1) {
                seatings = this.tripSelected.passengers.map(({ seating }) => seating).join(", ");
            }
        }
        if (seatings == '') {
            return seatingOwner
        }
        return `${seatingOwner}, ${seatings}.`
    }

    get seatingsDeleteReturn() {
        if (!this.tripSelected || !this.tripSelected.returnData) {
            return ''
        }
        const seatingOwner = this.tripSelected.returnData.seatingOwner;
        let seatings = '';
        if (this.tripSelected.returnData.passengers.length > 0) {
            seatings = this.tripSelected.returnData.passengers[0];
            if (this.tripSelected.returnData.passengers.length > 1) {
                seatings = this.tripSelected.returnData.passengers.join(', ')
            }
        }
        if (seatings == '') {
            return seatingOwner
        }
        return `${seatingOwner}, ${seatings}.`
    }

    get isRound() {
        if (this.tripSelected) {
            return this.tripSelected.typeTrip == 'round'
        }
        return false
    }

    closeCancelModal() {
        this.couponName = '';
        this.successCoupon = false;
        this.amountFinal = 0
        this.$emit('closeModal');
    }
    copy() {
        navigator.clipboard.writeText(this.couponName);
        this.mainStore.setNotification({
            isOpen: true,
            message: 'Cupón copiado correctamente',
            color: 'blue darken-2'
        })
    }
    async cancelTrip() {
        console.log(this.typeCancel)
        if(this.typeCancel == null){
            this.mainStore.setNotification({
                isOpen : true,
                color: 'red-darken-2',
                message : 'Imposible de cancelar, verifica los horarios de tu viaje'
            })
            return;
        }
        if (this.isBoth || this.typeCancel == null) {
            this.mainStore.setNotification({
                isOpen: true,
                message: 'Viaje ya cancelado',
                color: 'red darken-2'
            })
            return;
        }
        if (!this.tripSelected) return
        this.isLoadingCancelTrip = true;
        try {
            const data = await this.apiTrip.cancelTrip(this.tripSelected._id, this.isRound ? this.typeCancel : undefined);
            this.successCoupon = true;
            this.couponName = data;
            this.amountFinal = this.totalPriceFinal;
            this.typeCancel = 'BOTH'
            this.$emit('cancelTrip')
        } catch (error) {
            getErrorCatch(error)
        }
        this.isLoadingCancelTrip = false;
    }
}
