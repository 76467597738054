import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "section_terms" }
const _hoisted_2 = { class: "bg-black section_terms__title text-uppercase" }
const _hoisted_3 = { class: "section_terms__subtitle text-primary" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSteps, ({ id, html,title }) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `accordion_${id}`
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(title), 1),
            _createElementVNode("div", {
              class: "section_terms__body mt-4",
              innerHTML: html
            }, null, 8, _hoisted_4)
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}