
import { usePlanningStore } from '@/store/modules/planning';
import { useRoutesStore } from '@/store/modules/route';
import { Vue, Options } from 'vue-class-component';
import { getDate } from '@/filters/formatDate';
import HeaderCommonBooking from './HeaderCommonBooking.vue';

@Options({
    name: 'BannerInfoSearch',
    components: {
        HeaderCommonBooking
    }
})
export default class BannerInfoSearch extends Vue {
    public planningStore = usePlanningStore();
    public routeStore = useRoutesStore();
    public getDate = getDate;


    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get stations() {
        return this.routeStore.stationsList
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || ''
    }
}
