


import { convertTo12HourFormat } from '@/filters/formatDate';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'InfoHourRoute',
    props: {
        typeTrip: {
            type: String,
            default: '',
            required: false
        },
        activeTrip: {
            type: String,
            default: 'outbound',
            required: false
        },
        hourDeparture: {
            type: String,
            default: '',
            required: false
        },
        hourDepartureReturn: {
            type: String,
            default: '',
            required: false
        },
    }
})
export default class InfoHourRoute extends Vue {
    public typeTrip!: string;
    public activeTrip!: string;
    public hourDeparture!: string;
    public hourDepartureReturn!: string;
    public convertTo12HourFormat= convertTo12HourFormat;
}
