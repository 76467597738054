
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../common/ModalCommon.vue";
import {
  PlanningTripsResponse,
  TripFailDeleted,
} from "@/types/response/planning";
import { getErrorCatch } from "@/utils";
import { PlanningTripAPI } from "@/api/planning";
import { useMainStore } from "@/store/modules/main";
import { getDate } from "@/filters/formatDate";

@Options({
  name: "ModalReport",
  emits: ["closeModal", "loadPlanning"],
  components: {
    ModalCommon,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    planning: {
      type: Object,
      required: false,
      default: null,
    },
  },
})
export default class ModalReport extends Vue {
  public visible!: boolean;
  public planning!: PlanningTripsResponse;
  private planningApi = new PlanningTripAPI();
  private mainStore = useMainStore();
  public allowedDates: Date[] = [];
  public days: Date[] = [];
  public startDate = new Date();
  public getDate = getDate;
  public loading = false;
  public existTrips = false;
  public tripsDeleted: TripFailDeleted[] = [];
  public titleLoading = "Eliminar todos";

  closeModal() {
    this.$emit("closeModal");
  }

  async sendReport() {
    this.loading = true
    try {
      if(this.days.length == 0){
        throw new Error('No hay dias seleccionados')
      }
      const data  = await this.planningApi.generateReport(this.planning._id, this.days[0].getTime(), this.days[this.days.length-1].getTime());
      const elementA = document.createElement('a');
      elementA.target = "_blank";
      elementA.href = "data:application/octet-stream;base64,"+data;
      elementA.download = `Reporte_${this.planning.name.replace(/[ :.,;]/g, "_").toUpperCase()}.xlsx`;
      elementA.click();
      elementA.remove();
      this.days = [];
      this.closeModal()
      this.mainStore.setNotification({
        isOpen : true,
        color : 'green darken-2',
        message : "Reporte descargado"
      })
    } catch (error) {
      getErrorCatch(error);
    }
    this.loading = false;
  }

  mounted(): void {
    this.$watch("planning", (value: PlanningTripsResponse) => {
      if (value) {
        const { days } = value;
        const sortDays = days.map((day) => new Date(day));
        this.allowedDates = sortDays;
        this.startDate = sortDays[0];
      }
    });
  }
}
