
import { Vue, Options } from 'vue-class-component';
import Privacy from '@/components/Policies/Privacy.vue';
import { dataStepsPrivacy, dataStepsChangePolicy, dataStepsCookies } from '@/assets/data/policies';

@Options({
    name: 'PrivacyTerms',
    components: {
        Privacy
    }
})
export default class PrivacyTerms extends Vue {
    public dataPrivacy = dataStepsPrivacy || []
    public dataCookies = dataStepsCookies || []
    public dataChangePolicy = dataStepsChangePolicy || []
}

