
import { Vue, Options } from 'vue-class-component';
import Notification from '../components/common/Notification.vue';
import NavigationDrawer from '../components/NavigationAuth.vue';
import ToolBar from '../components/ToolBar.vue';
import { useAuthStore } from '@/store/modules/auth';
import { useMainStore } from '@/store/modules/main';
import { getErrorCatch } from '@/utils';
import { ParameterApi } from '@/api/parameter';

@Options({
  name: "LayoutAuth",
  components: {
    Notification,
    NavigationDrawer,
    ToolBar
  }
})
export default class LayoutAuth extends Vue {
  public authStore = useAuthStore();
  public mainStore = useMainStore();
  private parameterApi = new ParameterApi()
  public keysRoutes = ['/dashboard', '/perfil']
  get user() {
    return this.authStore.user;
  }
  get key() {
    return this.$route.path;
  }

  async mounted() {
      try {
        const data = await this.parameterApi.getStatuServer();
        this.mainStore.setStatus(data);
      } catch (error) {
        getErrorCatch(error)
      }
  }
}
