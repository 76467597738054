import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(["text-helvetica h-bold loader-text", _ctx.colorTextLoader])
  }, [
    _createVNode(_component_v_progress_circular, {
      size: _ctx.sizeLoader,
      color: _ctx.colorLoader,
      indeterminate: ""
    }, null, 8, ["size", "color"]),
    _createTextVNode(" " + _toDisplayString(_ctx.title) + " ... ", 1)
  ], 2))
}